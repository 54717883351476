@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);
.navbar {
    background: linear-gradient(90deg, rgb(99, 97, 97) 0%, rgb(26, 23, 23) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    z-index: 100;
    top: 0px;
    width:100%;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    /* margin-bottom: 10px; */
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    margin-top:10px;
    /* box-shadow: 1px 0px 17px gainsboro; */
  }
  
  /* .fa-firstdraft {
    margin-left: 9.5rem;
    font-size: 1.6rem;
  }
   */
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 45px;
    list-style: none;
    text-align: center;
    width: 80vw;
    justify-content: end;
    margin-right: 2rem;
    margin-left: 3rem;
  }
  
   
  .nav-item {
    display: flex;
    align-items: center;
    height: 80px;
    padding-top:15px;
  }
  
  .nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
  }
  
  .nav-links:hover {
    background-color: #f5985a;
    border-radius: 4px;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: absolute;
    }

    .navbar {
      background: linear-gradient(90deg, rgb(99, 97, 97) 0%, rgb(26, 23, 23) 100%);
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      position: fixed;
      width: 390px;
      z-index: 1;
  }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -150%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      
      /* margin-top:105px; */
      margin-left:0px;
      margin-right:30px;
      padding-left:0px;
      margin-top: 0px;
      z-index: 1;
   
    }


 
   
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      /* background-color:  #1888ff; */
      border-radius: 0;
    
      
    }

 
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      
      right: 0;
      
      font-size: 1.8rem;
      cursor: pointer;
      margin-top: 98px;
     
      width: 66px;
      height:56px;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      padding: 1.5rem;
      margin: 1rem auto;
      border-radius: 4px;
      width: 50%;
      background: #f5985a;
      text-decoration: none;
      color: #fff;
      font-size: 1.5rem;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #1888ff;
      transition: 250ms;
    }
  
    #btn {
      display: none;
    }
    
  }
.dropdown-menu {
    background: linear-gradient(90deg, rgb(71, 69, 69) 0%, rgb(26, 23, 23) 100%);
    width: 190px;
    position: absolute;
    top: 100%;
    list-style: none;
    text-align: start;
    border-radius: 3px 4px;
    z-index: 2;
  }
  
  .dropdown-menu li {
    background: #1a130e;
    cursor: pointer;
  }
  
  .dropdown-menu li:hover {
    background: #eea654;
  }
  
  .dropdown-menu.clicked {
    display: none;
  }
  
  .dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #fff;
    padding: 13px;
  }
  
  @media screen and (max-width: 960px) {
    .fa-caret-down {
      display: none;
    }
  }
.html{
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.body{
    color: #6B6B6B;
    background-color: #F2F2F2;
    word-wrap: break-word;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.7;
    font-size: 14px;
    width: 100%;
} 

html{
  box-sizing: border-box;
}
:before,:after{
  box-sizing:inherit;
}
/*Global styles
-------------------------------------------*/
body{
  color:#343434;
  margin:0;
  padding:0;
  font-family:'Open Sans', sans-serif;
  font-size: 15px;
  line-height: 1.5;
}
#includedContent:first-child{
    float: left!important;
    display: block;
    border-top: 1px solid #dadada;;

}
img#profil{
    opacity: 0.8;

}

#includedContent{
  padding-top: 80px;
    display: table;
    content: " ";
    background-color: #8080801a;
    background: linear-gradient(to bottom, rgba(253, 253, 238, 0.8), rgba(186, 186, 221, 0.9)), url(/static/media/3.9125b85d.jpg);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* float: left!important; */
  
  z-index: 99;
  bottom: 0px;
  /* right:30px;  */
  position: relative;
  display: inline-flex;
  width: 100%;
}


#despre-audit{
    /* float:center; */
   
    background-color: rgb(218, 165, 183);
    border-top: 1px solid #dadada;

 padding: 45px 50px;
    background-color: #fff;
    border: 1px solid #dadada;
   
    margin-left:30px;
    margin-bottom: 400px;

    
  
  
}

#custom-id-yes{
  margin-top:20%;
}

#custom-id-yess{
  margin-top:20%;
}
#custom-id-yesss{
   margin-top:20%;
}
div {
    display: block;
}

.tagcloud a {
    padding: 6px 7px;
    margin-right: 0;
    margin-bottom: 4px;
    line-height: 100%;
    display: inline-block;
    color: #FFF !important;
    background-color: #DADADA;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    font-size: 12px ;
}
.entry-meta a {
    font-size: 13px;
    color: #666666;
}
footer.entry-meta {
    background: #f7fff7;
    color: #F7FFF7;
    /* float: center; */
}

 .col-md-12,   .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
    position: relative;
    /* min-height: 1px;
    padding-right: 15px;
       padding-left: 0px; */
       width: 100%;
}
#secondary .widget > h3 {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 25px;
    margin-top: 0;
    color: #636467;
}
#secondary .widget {
    margin-bottom: 10px;
    color: #C1C0C0;
    overflow: hidden;
}
.well {
    background-color: #fff;
    padding: 30px;
    border: 1px solid #DADADA;
    overflow: hidden;
    clear: both;
    margin-top: 75px;
    
}

.well_audit {
  background-color: #fff;
  padding: 30px;
  border: 1px solid #DADADA;
  overflow: hidden;
  clear: both;
  /* margin-top: 75px;
   */
   margin-top:0px;
  margin-bottom: 0px;
  /* width: 682px; */
  /* margin-left: 30px; */
}




@media (min-width: 992px){
.col-md-4 {
    width: 33.33333333%;
}
}
@media (min-width: 992px){
.col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 {
    float: left;
}
}
div.wpcf7 .screen-reader-response {
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    margin: 0;
    padding: 0;
    border: 0;
}

section.footer-contab {background:#666; padding:60px 0; overflow:visible; padding-top:0px; margin-top: 1800px;}
.footer-elements-contab {margin-top:130px; margin-left:190px;margin-right:400px; position: relative;;}
.footer-element { width: 200px; background:rgb(153, 153, 219); padding: 8px 15px 15px; text-align:center; box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02); }
.footer-element span.typcn { font-size:45px;color:#fff; margin-bottom:15px; }
.footer-element p { font-size:14px; color:#fff;margin-bottom:0;}
.footer-element p span { display: block;}
.footer-element p a { color:#fff;}
.footer-widgets { margin-top: 42px;}
.footer-widget-contab { padding-right:45px;margin-left: 200px; display:table-footer-group; margin-right:30px; }
.footer-widgets .small-logo { margin:10px 40px 15px;}
.footer-widgets p { font-size:15px; color:#ccc;margin-bottom:0;}
.footer-widgets h5 { font-size:18px; color:#fff;margin:12px 0 20px; padding:0;}
.footer-widgets ul.posts { margin:0; padding:0; list-style: none; width: 550px;}
.footer-widgets ul.posts li {padding:5px 0; border-bottom:1px solid #747474; font-size:15px;}
.footer-widgets ul.posts li:first-child {padding-top:0; }
.footer-widgets ul.posts li:last-child {border-bottom:0; }
.footer-widgets ul.posts li a {color:#ccc; transition: all 250ms ease-out 0s;}
.footer-widgets ul.posts li a:hover {color:#fff;}
.footer-widgets ul.posts li span {display:block;font-size:12px; margin-top:3px;color:#B9B9B8;}
/* .row { max-width: 1170%; width:100%; display: -webkit-inline-box; margin-left:400px; } */

.row.no-gutter-all [class*='col-'] { padding-left: 0; padding-right: 0; }
#mc_embed_signup { margin-top:15px;}
#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
#mc_embed_signup input[type="email"] { border-radius:5px; width:100%; padding:10px 15px; border:0; }
#mc_embed_signup input[type="submitted"] { margin-top:5px; transition: all 250ms ease-out 0s; border-radius:5px; width:100%; padding:10px 15px; border:0; color:#fff; background:#cb6428;}
#mc_embed_signup input[type="submitted"]:hover { background:#808080;}

/* Social Buttons ---------------------- */
.social-links {margin:20px 0 0; padding:0;}
.social-links li { list-style: none;  display: inline-table;}
.social-links li a { color:#fff; font-size:13px; display: inline-block;  padding-top:7px;  text-align: center;  height: 34px;  width: 34px;  position: relative; z-index: 1; transition: color 0.15s; -moz-osx-font-smoothing: grayscale; border-radius:50%;}
.social-links li a::before { content: '';  position: absolute;  top: 0;  left: 0;  width: 100%;  height: 100%;  border-radius: inherit;  z-index: -1;  box-shadow: inset 0 0 0 35px #808080;  transform: scale3d(0.7, 0.7, 1);  transition: box-shadow 0.15s, transform 0.15s;}
.social-links li a:hover::before {  transform: scale3d(1.0, 1.0, 1); box-shadow: inset 0 0 0 35px  #009DE0;}

.copyright {padding:15px 0; background:#525151;}
.copyright p {font-size:14px; color:#ccc; margin-bottom:0;}

/* Back to Top Button  ----------------------- */
#back-to-top { color:#fff; position:fixed; bottom:20px; right:20px; z-index:99; display:none; text-align: center; border-radius: 50%; -moz-border-radius: 50%;  -webkit-border-radius:  50%; -o-border-radius:  50%;    z-index: 10000;    height: 59px;    width: 59px;    background-color:#3190E7;    background-repeat: no-repeat;    background-position: center;  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);  transform: scale3d(0.7, 0.7, 1);  transition: box-shadow 0.15s, transform 0.15s;}
#back-to-top i { padding-top:20px; font-size:17px; }
#back-to-top:hover { background:#e77a21;  transform: scale3d(1.0, 1.0, 1);}

 .col-sm-3  {
  display:-webkit-box;
  box-sizing: border-box;
  
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.row:before{
  display: table;
}

img#footerImg {
  box-shadow: -1px 0px 17px gainsboro;
  border-radius: 20px 20px ;
}


button#poli{
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  
    background-color: rgb(105, 104, 104,0.7);
    cursor: pointer;
    border: hidden;
  
}

input[type=text],[type=email], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
  }
  
  input#submitted[type=button] {
    background-color: #546cbd;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top:40px;
    margin-left:20px;
  }
  
  input[type=button]:hover {
    background-color: #e6bc4a;
  }
  

  textarea#subject{
      font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  .error {
    color: red;
    font-size: 90%;
  }
  
  
  .modal1#popup {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    padding-top: 60px;
  }
  .modal-content {
    background-color: #fefefe;
    margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
    border: 1px solid #888;
    width: 50%; 
    height: 70%;/* Could be more or less, depending on screen size */
  }
  #close-button {
    float: right;
    width: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    cursor: pointer;
    border-radius: 0.25rem;
    background-color: lightgray;
  }
  #close-button:hover {
    background-color: darkgray;
  }
  .show-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
  }
  .animate {
    animation: animatezoom 0.6s
  }
  
  p#populate{
    width:50px;
    height:60%;
    margin-left:30px;
    line-height: 2;
    line-break: strict;
  
  
  }
  
  input[type=button]#submit-email{
    background-color: #546cbd;
    color: white;
    padding: 7px 16px;
    border: none;
    border-radius: 6px;
    margin-left: 30px;
    cursor: pointer;
    margin-top: 0px;
  }
  
  input[type=button]#submit-email:hover {
    background-color: #e6bc4a;
  }
  
  a#prev {
    text-decoration: none;
    display: inline-block;
    padding: 8px 16px;
  }
  
  a#prev:hover {
    background-color: #ddd;
    color: black;
  }
  
  .previous {
    background-color: #f1f1f1;
    color: black;
  }
  
  @media screen and (max-width: 520px){
   
  
  input[type=text],[type=email], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
  }
  
  input[type=button] {
    background-color: #546cbd;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top:40px;
    margin-left:40px;
  }
  
  input[type=button]:hover {
    background-color: #e6bc4a;
    margin-left:40px;
  }
  

  
  textarea#subject{
      font-family: Verdana, Tahoma, sans-serif;
  }
  .error {
    color: red;
    font-size: 90%;
  }
    }
  
  
  
  /* Popup container */
  .modal{
    position: relative;
    display: none;
    cursor: pointer;
  }
  
  /* The actual popup (appears on top) */
  .modal .popup_content {
    visibility: hidden;
    width: 160px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -80px;
  }
  
  /* Popup arrow */
   .modal .popup_content::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  /* Toggle this class when clicking on the popup container (hide and show the popup) */
  .modal .show {
    visibility: visible;
    animation: fadeIn 1s;
    display: inline-block;
  }
  
  /* Add animation (fade in the popup) */
  
  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity:1 ;}
  }

  /*==============================================================================================*/

  
/* body {font-family: Arial, Helvetica, sans-serif; width: 100%; height:100%;} */
 
/* Full-width input fields */
input[type=text], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}


/* Extra styles for the cancel button */
.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #f44336;
}

/* Center the image and position the close button */
.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
  position: relative;
}

img#avatar {
  width: 40%;
 position: relative;
}

/* .container2 {
  padding: 16px;
  margin-top: 60px;
} */

span.psw {
  float: right;
  padding-top: 16px;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  padding-top: 60px;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button (x) */
.close {
  position: absolute;
  right: 25px;
  top: 0;
  color: #000;
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: red;
  cursor: pointer;
}


.animate {
  animation: animatezoom 0.6s;
}
  
@keyframes animatezoom {
  from {transform: scale(0)} 
  to {transform: scale(1)}
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
     display: block;
     float: none;
  }
  .cancelbtn {
     width: 100%;
  }
}


 
 p#logs{
  background-color: rgba(252, 227, 198, 0.9);
  color: black;
  margin-bottom: -45px;
}
 p#calls {
  margin-left: 1050px;
   background-color: rgba(248, 227, 220, 0.9);
}

a#calling{
  color: black;
  font-size: 80%;


}
#mess p{
  font-family: 'Alata';font-size: 10px;
  color: black;
}

@media screen and (max-width: 520px){
img#profil {
    width: 58px;
    margin-right: 6px;
}
}
img#profil a img {
  width: auto;
  max-width: 88px;
 
}

img.call{
  width: 20px;
  max-width: 88px;
  margin-left: 1090px;

  position: fixed;
  left: 0;
  top: 0;
  border-radius: 10px;
}


img#profil{

  width: 180px;
  position: fixed;
   left:0;
   top:0;
   border-radius: 10px;
   
  
}



#btn-det1,#btn-det2,#btn-det3{
  background: #f18339;
  color: #fff;
  font-weight: 700;
  letter-spacing: .06em;
  text-transform: uppercase;
  font-size: 13px;
  border-radius: 2px;
  padding: 15px 30px;
  transition: background-color 1s ease 0s;
  cursor: pointer;
  border-radius: 20px;
  border: none;
}


a{
  color: #f3e6a5;
 
}

a:hover{
  text-decoration: none;
}

h1{
  font-size: 100px;
  line-height: 1px;
}
h2#titlu_contabilitate{
 
  /* font-size: 34px; */
  /* text-indent: px; */
  padding-right: 10px;
/*  line-height: 1;*/
font-size: 26px;;
/* margin-left: 32px; */
font-family:'IBM Plex Sans';
margin-top: 5%;
padding-bottom: 2%;
}



.content-wrap{
 max-width: 800px;
 width:85%;
  margin: 0 auto;
  padding: 60px 0;
 
}
h3{
  margin-bottom: 0;
}

.item-details h3 + p{
  font-style: italic;
}
/*advanced selector*/


.item-details h3 ~ p {
  margin: 0;
}

.divider > section{
  border-bottom: 1px none #949393;
  padding: 25px 0;
}

.divider > section:last-of-type {
   border-bottom: none;
}



/*Profile section
------------------------------------------*/
.header  {

  width: 100%;
  background-color:  rgba(223, 223, 253, 0.7);
}

.fadein { position:relative; width:500px; height:332px; }


.item-1
{
	position: relative;
  display: block;
  top: 1em;
   
  
  width: 60%;
  
  font-size: 2em;

	animation-duration: 20s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}

.item-2{
  position: relative;
  display: block;
  top: -7em;
  /* float:center; */

  width: 60%;
  
  font-size: 2em;

	animation-duration: 20s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}

.item-3{
  position: relative;
  display: block;
	top: -13em;
  
  width: 60%;
  
  
  font-size: 2em;

	animation-duration: 20s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}
.item-1{
	animation-name: anim-1;
}

.item-2{
	animation-name: anim-2;
}

.item-3{
	animation-name: anim-3;
}

@keyframes anim-1 {
	0%, 8.3% { left: -100%; opacity: 0; }
  8.3%,25% { left: 20%; opacity: 1; }
  33.33%, 100% { left: 110%; opacity: 0; }
}

@keyframes anim-2 {
	0%, 33.33% { left: -100%; opacity: 0; }
  41.63%, 58.29% { left: 20%; opacity: 1; }
  66.66%, 100% { left: 110%; opacity: 0; }
}

@keyframes anim-3 {
	0%, 66.66% { left: -100%; opacity: 0; }
  74.96%, 91.62% { left: 20%; opacity: 1; }
  100% { left: 110%; opacity: 0; }
}

/*Projects
----------------------------*/
.projects{
  background:  #f8fcf8;
}

.projects a{
  color: #2F3061;

}

.projects .btn{
  color:#F7FFF7;
  background:  #2F3061;
  text-decoration: none;
  padding: 8px;
  border-radius: 4px;
  display: inline-block;

}

.projects .btn:hover{
  background: rgba(47, 48 , 97, 80%);
}

.project-item{
  overflow: hidden;
  animation: fade-slide-in 0.3s ease-out forwards;

}

#myclock {
  position: relative;
  top: 20%;
  left: 85%;
  transform: translateX(-50%) translateY(-50%);
  color: #3e2d55;
  font-size: 15px;
  font-family: Orbitron;
  letter-spacing: 2px;
 


}
.project-item h3{
  margin-top: 0;
}
/*Work experience
-----------------------------*/


.work{
  background: #d2e4ec;
}




/* Education ------------------
*/
.despre{
  /* background-image: url(../images/joanna-kosinska-unsplash.jpg); */
  background-size: cover;
  background-position: top right;
  padding-bottom: 0px;
  background-color: white;


}

.social-links {margin:20px 0 0; padding:0;}
.social-links li { list-style: none;  display: inline-table;}
.social-links li a { color:#fff; font-size:13px; display: inline-block;  padding-top:7px;  text-align: center;  height: 34px;  width: 34px;  position: relative; z-index: 1; transition: color 0.15s; -moz-osx-font-smoothing: grayscale; border-radius:50%;}
.social-links li a::before { content: '';  position: absolute;  top: 0;  left: 0;  width: 100%;  height: 100%;  border-radius: inherit;  z-index: -1;  box-shadow: inset 0 0 0 35px #808080;  transform: scale3d(0.7, 0.7, 1);  transition: box-shadow 0.15s, transform 0.15s;}
.social-links li a:hover::before {  transform: scale3d(1.0, 1.0, 1); box-shadow: inset 0 0 0 35px  #009DE0;}


.despre p{
  width:60%;
}




/* Fake image */
.fakeimg {
  width: 100%; 
  padding: 20px;

}

.card {
 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 800px;
  margin: 40px 30px;
  text-align: inherit;
  height: 100%;
  width:150%;
  margin-right: 0px;
  margin-bottom: 20px;

}

p#profi{
  border-top: gray 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-indent: 50px;

  

}
#text_despre{
  margin-top:10px;
  margin-left: 10px;
  margin-right:10px;
  margin-bottom: 10px;;
}

ul{
  margin-top:0px;
  text-align: left;
  /* text-indent: 150px; */
}
p#d_cor{
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  text-align: left;
  padding-bottom: 20px;
  text-indent: 50px;
  
  
}


.row {
  
width:600px;
}


.contact-list{
  list-style-type: none;
  padding:0;

}

.contact-list a{
  padding: 15px;
  display: inline-block;
}

/* Responsive
-----------------------*/

@media screen and (min-width:750px){
  header, p#logs{
    /* , footer */
      text-align: center;
      background-color:  rgba(255, 255, 255, 0.9);
  }
  .project-item img {
      float: left;
      margin-right: 20px;
  }
  .job-item{
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-column-gap: 20px;
      -webkit-column-gap: 20px;
              column-gap: 20px;
  }
  .contact-list{
      display: flex;
      justify-content: center;
  }
  p#logs{
    background-color: rgba(255, 255, 255, 0.9);
    color: black;
    margin-bottom: -45px;
  }
  p#calls{
    margin-left: 1050px;
    background-color:  rgba(255, 255, 255, 0.9);
  }
  
  a#calling{
    color: black;
    font-size: 80%;
  }

  #myclock {
    position: relative;
    top: 20%;
    left: 85%;
    transform: translateX(-50%) translateY(-50%);
    color: #3e2d55;
    font-size: 15px;
    font-family: Orbitron;
    letter-spacing: 2px;
   
  
  
  }
  h2#titlu_audit{
    text-indent:5px; 
    font-size: 27px;;
    /* margin-left: 32px; */
    font-family:'IBM Plex Sans';
  }
  h2#titlu_analiza{
    text-indent:5px; 
    font-size: 28px;;
    /* margin-left: 32px; */
    font-family:'IBM Plex Sans';
  }

  #para{
    text-indent: 25px;
     text-align: justify;
     line-height: 30px;
  }

  #paragraf_mare{
    margin-right: 30px;
     margin-left: 40px;}
  }

  #para2{
   text-indent: 20px;
    margin-bottom: 0px;
     line-height: 20px;
  }
  #para3{
    text-indent: 20px;
     margin-bottom: 30px;
      margin-top: 0px;
      line-height: 33px;
    
  }

  #includedContentAnaliza{   
   padding-top: 80px;
    display: table;
    content: " ";
    background-color: #8080801a;
    background: linear-gradient(to bottom, rgba(253, 253, 238, 0.8), rgba(186, 186, 221, 0.9)), url(/static/media/3.9125b85d.jpg);
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* float: left!important; */
    z-index: 99;
   width: 100%;
    position: relative;
    display: inline-flex;
}
#includedContentConta{   
  padding-top: 80px;
  display: table;
  content: " ";
  background-color: #8080801a;
  background: linear-gradient(to bottom, rgba(253, 253, 238, 0.8), rgba(186, 186, 221, 0.9)), url(/static/media/3.9125b85d.jpg);
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
  z-index: 99;
  right:0px;
  position: relative;
  display: inline-flex;
  overflow: hidden;
  width: 100%;
  
}



#includedContentCons{   
 padding-top: 80px;
  margin-bottom: 0px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: table;
  content: " ";
  background-color: #8080801a;
  background-image: linear-gradient(to bottom, rgba(253, 253, 238, 0.8), rgba(186, 186, 221, 0.9)), url(/static/media/3.9125b85d.jpg);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
 
  z-index: 99;
 
  position: relative;
  display: inline-flex;
  width: 100%;
}

#includedContentSala{   
 
  display: table;
  content: " ";
  background-color: #8080801a;
  background-image: linear-gradient(to bottom, rgba(253, 253, 238, 0.8), rgba(186, 186, 221, 0.9)), url(/static/media/3.9125b85d.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 80px;
  z-index: 99;
  position: relative;
  display: inline-flex;
  width: 100%;
}

.well_sal {
  background-color: #fff;
  padding: 40px;
  border: 1px solid #DADADA;
  overflow: hidden;
  clear: both;
  /* margin-top: 0px; */
  /* margin-bottom: 400px; */
  margin-bottom: 0px;
  height:1680px;
 
  padding-top:60px;
}

.well_analiza {
  background-color: #fff;
  padding: 30px;
  border: 1px solid #DADADA;
  overflow: hidden;
  clear: both;
  margin-top: 0px;
  /* margin-bottom: 400px; */
  margin-bottom: 0px;
}
.well_consultanta {
  background-color: #fff;
  padding: 30px;
  border: 1px solid #DADADA;
  overflow: hidden;
  clear: both;
 
  /* margin-bottom: 400px; */
  margin-bottom: 0px;
}
h2#titlu_sal{
  text-indent:5px; 
  font-size: 28px;;
  /* margin-left: 32px; */
  font-family:'IBM Plex Sans';


}
#despre-analiza {
  border-top: 1px solid #dadada;
  padding: 50px 50px;
  background-color: #fff;
  border: 1px solid #dadada;
  /* margin-left: 30px; */
  margin-bottom: 0px;
  position: relative;
  margin-right: 10px;
  margin-left: 10px;
}


  #para_analiza1{
  text-indent: 60px;
  }
  #para_analiza2{
   text-indent: 60px;
   margin-bottom: 0px;
  }
  #para_analiza3{
    text-indent: 60px;
     margin-bottom: 0px;
      margin-top: 0px;
  }

  #lista_analiza{
   text-align: left;
   text-indent: 10px;
    line-height: 40px;
    padding-left: 5%;
    padding-right: 5%;
  }
  #lista_consult{
   text-align: left;
    text-indent: 40px;
     line-height: 40px;
  }
  #paragraf_mare{
    padding-left: 3%;
    padding-right: 17%;
  }

  #lista_sal{
    margin-top:20px;
    /* margin-bottom: 10px; */
    text-align: left;
    text-indent: 15px;
    line-height: 35px;
  }
  #para_cons1{
    text-indent: 60px;
  }
  #oferta_para{
    text-indent: 60px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  #consultanta_of{
    text-indent: 60px;
    margin-bottom: 0px;
     margin-top: 0px;
  }
  #despre-audit {
   
    border-top: 1px solid #dadada;
    padding: 0 auto 0 auto;
    background-color: #fff;
    border: 1px solid #dadada;
    margin-right:30px;
    margin-left: 30px;
    margin-bottom: 0px;
    position: relative;
  
}

.form-conta{
  max-width: 500px;
  width:100%;
}
#despre-sal {
  border-top: 1px solid #dadada;
    padding: 50px 50px;
    background-color: #fff;
    border: 1px solid #dadada;
    margin-right:30px;
    margin-left: 30px;
    margin-bottom: 0px;
    position: relative;
}
#despre-consultanta {
  border-top: 1px solid #dadada;
    padding: 50px 50px;
    background-color: #fff;
    border: 1px solid #dadada;
    
    /* margin-left: 30px; */
    margin-bottom: 0px;
    position: relative;

}
#para_sal1{
  text-indent: 60px;
}

#head_sal1{
  text-indent: 60px;
  margin-bottom: 15px;
  margin-top: 25px;
  font-size: large;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.well {
  background-color: #fff;
  padding: 30px;
  border: 1px solid #DADADA;
  overflow: hidden;
  clear: both;
  margin-top: 0px;
  
}





@media screen and (max-width:749px){
  h1{
      font-size:75px;
      line-height: 0.9;
      margin-bottom: 20px;
  }
  h2#titlu_contabilitate{
      line-height: 1;

  }
  .contact-list a {
      padding: 5px;
  }

  .imgcontainer img#avatar {
    width: 40%;
    border-radius: 50%;

  }
  
  p#logs{
    text-align: center;
    background-color: white;
    color: black;
    margin-bottom: -45px;
  }
  p#calls{
    margin-left: 1050px;
    /* background-color: blanchedalmond; */
  }
  
  a#calling{
    color: black;
    font-size: 80%;
  }
  .footer-widgets ul.posts {
    margin: 0;
    padding: 0;
    list-style: none;
    text-indent: 0px;
    width: 390px;
  }
  
}

@media(max-width: 1075px){
  #includedContentConta{
    display: inline-block;
  }
  .form-conta{
    max-width: 100%;
  }
  #includedContent{
    display: inline-block;
  }
  #includedContentAnaliza{
    display: inline-block;
  }
}

.card-container{  
  display: block;


}

.titlee {
  color: grey;
  font-size: 18px;
  margin-left: 50px;
}
.col-sm-33 {
  display: -webkit-box;
  box-sizing: border-box;
  /* padding-left: 30px; */
  min-height: 1px;
  padding-right: 15px;
  width: 340px;
  /* padding-right: 0px; */
  /* width: 330px; */
  padding-left: 70px;
}
.col-sm-333 {
  display: -webkit-box;
  box-sizing: border-box;
  /* padding-left: 30px; */
  min-height: 1px;
  padding-right: 10px;
  width: 340px;
  /* padding-right: 0px; */
  /* width: 330px; */
  padding-left: 10px;
}
.jtitle{
  margin-left: 50px;
}
#myclock {
  position: relative;
  top: 20%;
  left: 85%;
  transform: translateX(-50%) translateY(-50%);
  color: #3e2d55;
  font-size: 15px;
  font-family: Orbitron;
  letter-spacing: 2px;
 


}

p#but {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

a#sign {
  text-decoration: none;
  font-size: 22px;
  color: black;
}

p#but:hover, a#sign:hover {
  opacity: 0.7;
}


/* ==================================
9 = Footer
==================================== */
section.footer-contab {background:#666; padding:60px 0; overflow:visible;padding-top: 0px;
  margin-top: 0px;padding-top: 0px;
  margin-top: 0px;}
.footer-elements-contab {margin-top:500px; margin-left:190px;margin-right:400px; position: relative;;}
.footer-element { width: 220px; background:rgb(153, 153, 219); padding: 8px 15px 15px; text-align:center; box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02); }
.footer-element span.el{ font-size:45px;color:#fff; margin-bottom:15px; }
.footer-element p { font-size:14px; color:#fff;margin-bottom:0;}
.footer-element p span { display: block;}
.footer-element p a { color:#fff;}
.footer-widgets { margin-top: 42px;}
.footer-widget-contab { padding-right:25px;margin-left: 80px; display:table-footer-group; margin-right:-240px;  width:400px;}
.footer-widgets .small-logo { margin:10px 40px 15px;}
.footer-widgets p { font-size:15px; color:#ccc;margin-bottom:0;}
.footer-widgets h5 { font-size:18px; color:#fff;margin:12px 0 20px; padding:0;}
.footer-widgets ul.posts { margin:0; padding:0; list-style: none; width: 550px;}
.footer-widgets ul.posts li {padding:5px 0; border-bottom:1px solid #747474; font-size:15px;}
.footer-widgets ul.posts li:first-child {padding-top:0; }
.footer-widgets ul.posts li:last-child {border-bottom:0; }
.footer-widgets ul.posts li a {color:#ccc; transition: all 250ms ease-out 0s;}
.footer-widgets ul.posts li a:hover {color:#fff;}
.footer-widgets ul.posts li span {display:block;font-size:12px; margin-top:3px;color:#B9B9B8;}
/* .row { max-width: 1170%; 
  width:100%; 
  display: -webkit-inline-box; 
  margin-left:70px; } */
/* .row1{width:400%; display:inline-flex}
.row.full-width {  margin: 0 auto; max-width: 100%; width: 100%; }
.row.custom-width-90 {  margin: 0 auto; max-width: 90%; width: 90%; }
.row.full-width-padding {  margin: 0 auto; max-width: 98%; width: 98%; }
.row.no-gutter { margin:0 auto;} */
.row.no-gutter [class*='col-']:not(:first-child), .row.no-gutter [class*='col-']:not(:last-child) { padding-left: 0; padding-right: 0; }
/* .row.no-gutter-all { margin:0 auto;} */
.row.no-gutter-all [class*='col-'] { padding-left: 0; padding-right: 0; }
#mc_embed_signup { margin-top:15px;}
#mc_embed_signup input[type="email"] { border-radius:5px; width:100%; padding:10px 15px; border:0; }
#mc_embed_signup input[type="submitted"] { margin-top:5px; transition: all 250ms ease-out 0s; border-radius:5px; width:100%; padding:10px 15px; border:0;  color:#fff; background:#cb6428;}
#mc_embed_signup input[type="submitted"]:hover { background:#808080;}

/* Social Buttons ---------------------- */
.social-links {margin:20px 0 0; padding:0;}
.social-links li { list-style: none;  display: inline-table;}
.social-links li a { color:#fff; font-size:13px; display: inline-block;  padding-top:7px;  text-align: center;  height: 34px;  width: 34px;  position: relative; z-index: 1; transition: color 0.15s; -moz-osx-font-smoothing: grayscale; border-radius:50%;}
.social-links li a::before { content: '';  position: absolute;  top: 0;  left: 0;  width: 100%;  height: 100%;  border-radius: inherit;  z-index: -1;  box-shadow: inset 0 0 0 35px #808080;  transform: scale3d(0.7, 0.7, 1);  transition: box-shadow 0.15s, transform 0.15s;}
.social-links li a:hover::before {  transform: scale3d(1.0, 1.0, 1); box-shadow: inset 0 0 0 35px  #009DE0;}

.copyright {padding:15px 0; background:#525151;}
.copyright p {font-size:14px; color:#ccc; margin-bottom:0;}

/* Back to Top Button  ----------------------- */
#back-to-top { color:#fff; position:fixed; bottom:20px; right:20px; z-index:99; display:none; text-align: center; border-radius: 50%; -moz-border-radius: 50%;  -webkit-border-radius:  50%; -o-border-radius:  50%;    z-index: 10000;    height: 59px;    width: 59px;    background-color:#3190E7;    background-repeat: no-repeat;    background-position: center;  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);  transform: scale3d(0.7, 0.7, 1);  transition: box-shadow 0.15s, transform 0.15s;}
#back-to-top i { padding-top:20px; font-size:17px; }
#back-to-top:hover { background:#e77a21;  transform: scale3d(1.0, 1.0, 1);}

 .col-sm-3  {
  display:-webkit-box;
  box-sizing: border-box;
  
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.row:before{
  display: table;
}

img#footerImg {
  box-shadow: -1px 0px 17px gainsboro;
  border-radius: 20px 20px ;
}

/* html, body {
  width: 100%;
  overflow-x: hidden;
  background-color: rgba(255, 255, 255, 0.9);
} */

button#poli{
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  
    background-color: rgb(105, 104, 104,0.7);
    cursor: pointer;
    border: hidden;
  
}



label {
  font-weight: bold;
  margin: 0.5rem 0;
  color: #464646;
}

input {
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.4rem 0.2rem;
  color: #383838;
}

input:focus {
  outline: none;
  background: #fff1c4;
}

.btn {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid #00329e;
  background: #00329e;
  color: white;
  border-radius: 6px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.26);
  cursor: pointer;
  margin: 0 0.5rem;
}

.btn:hover,
.btn:active {
  background: #f67722;
  border-color: #f67722;
}

.btn--passive {
  color: #00329e;
  background: transparent;
  border: none;
  box-shadow: none;
}

.btn--passive:hover,
.btn--passive:active {
  background: #aec6f8;
}

.btn--danger {
  background: #d30808;
  color: white;
  border-color: #d30808;
}

.btn--danger:hover,
.btn--danger:active {
  background: #ff3217;
  border-color: #ff3217;
}

.modal {
  
  position: fixed;
  z-index: 100;
  background: white;
  border-radius: 10px;
  width: 80%;
  top: 40px;
  left: 10%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  display: none;
}

.modal.visible {
  display: block;
  animation: fade-slide-in 0.3s ease-out forwards;
  margin-top: 10px;
    padding-top: 20px;
    padding-bottom: 50px;
}

.modal .modal__title {
  margin: 0;
  padding: 1rem;
  border-bottom: 1px solid #00329e;
  background: #00329e;
  color: white;
  border-radius: 10px 10px 0 0;
}

.modal .modal__content {
  padding: 1rem;
}

.modal .modal__actions {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

.movie-element {
  margin: 1rem 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
  display: flex;
}

.movie-element h2,
.movie-element p {
  font-size: 1.25rem;
  margin: 0;
}

.movie-element h2 {
  color: #383838;
  margin-bottom: 1rem;
}

.movie-element p {
  color: white;
  display: inline;
  background: #f67722;
  padding: 0.25rem 1rem;
  border-radius: 15px;
}

.movie-element__image {
  flex: 1 1;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
}

.movie-element__image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.movie-element__info {
  flex: 2 1;
  padding: 1rem;
}

#entry-text {
  margin: 2rem;
  font-size: 1.5rem;
  text-align: center;
}

#movie-list {
  list-style: none;
  width: 40rem;
  max-width: 90%;
  margin: 1rem auto;
  padding: 0;
}

#backdrop {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
  pointer-events: none;
  display: none;
}

#backdrop.visible {
  display: block;
  pointer-events: all;
}

#add-modal .modal__content {
  display: flex;
  flex-direction: column;
 
}

@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@keyframes fade-slide-in {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0);
  }
}


.copyright {
  padding: 15px 0;
  background: #525151;
  
}
.copyright p {
  font-size: 14px;
  color: #ccc;
  margin-bottom: 0;
}
.text-center {
  text-align: center;
  text-indent: 500px;
}
#back-to-top {
  color: #fff;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  display: inline;
  text-align: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  z-index: 10000;
  height: 59px;
  width: 59px;
  background-color: rgba(186, 186, 221, 0.9);
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  transform: scale3d(0.7, 0.7, 1);
  transition: box-shadow 0.15s, transform 0.15s;
 
}

#back-to-top i {
  padding-top: 20px;
  font-size: 17px;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.footer-elements-contab {
  /* margin-top: 500px; */
  margin-left: 0px;
  margin-right: 400px;
  position: relative;
  margin-top: 80px;
}
#mapa{

  width: 500px;
   height:300px;
}

#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }

#modal_cerere_btn {
  visibility: hidden;
}

#titlu_contab{
  text-indent: 60px;
}
ul#liste_contab{
 line-height: 2px;
 text-indent: 10px;
 
}


@media(max-width: 660px){
  #secondary{
    display: none;
  }
  #paragraf_mare{
    padding-left: 10%;;
  }

  
  
h2#titlu_contabilitate {
  line-height: 1;
  margin-top: -40px;
  font-size: 1.2rem;
  text-indent: 5px;
  text-align: justify;
  padding-left: 1%;
  width: 100%;
  font-family: 'Andada';
  font-style: italic;
  text-shadow: 2px 2px 5px #caa6ec;
  padding-top: 10px;

}

#titlu_contab {
  text-indent: 24px;
  width: 100%;

 
}


#modal_cerere_btn {
  visibility: visible;
 
  border-radius: 5px 5px;
  border: none;
  background-color: rgb(255, 244, 230);
  font-weight: bold;
  font-stretch: condensed;
  font-family: sans-serif;
  border-color: cornsilk;
  box-shadow: 0 9px 12px rgb(121 121 121 / 26%);
  font-size: large;
  color-interpolation-filters: linearRGB;
  width: 100%;
  position: relative;
 
}

}
.Modal {
    position: absolute;
    z-index: 200;
    border: 1px solid #eee;
    box-shadow: 0 2px 2px #ccc;
    background-color: white;
    padding: 10px;
    text-align: center;
    box-sizing: border-box;
    /* top: 50%; */
    top:0;
    left: 43%; 
    
    
   margin-left: -150px;
    transition: all 0.3s ease-out;
    max-width: 382px;
   width: 100%;
   height: auto;
   
    
}


.ModalOpen {
    animation: openModal 0.4s ease-out forwards;
}

.ModalClosed {
    animation: closeModal 1s ease-out forwards;
}



.fade-slide-enter-active {
    animation: openModal 0.4s ease-out forwards;
}



.fade-slide-exit-active {
    animation: closeModal 1s ease-out forwards;
}

@keyframes openModal {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    50% {
        opacity: 1;
        transform: translateY(90%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes closeModal {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    50% {
        opacity: 0.8;
        transform: translateY(60%);
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}
.Backdrop {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
}


.BackdropOpen {
    display: block;
}

.BackdropClosed {
    display: none;
}

body {font-family: Arial, Helvetica, sans-serif; width: 100%; height:100%;}


input[type=text], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

#titluri{
  width: 100%;
  font-size: 1rem;
  font-family: 'Arial';
  font-weight: bold;
  outline: #00329e;
}


/* Extra styles for the cancel button */
.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #f44336;
}

/* Center the image and position the close button */
.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
  position: relative;
}

img#avatar {
  width: 40%;
 position: relative;
}

.container {
  padding: 16px;
}

span.psw {
  float: right;
  padding-top: 16px;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  padding-top: 60px;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button (x) */
.close {
  position: absolute;
  right: 25px;
  top: 0;
  color: #000;
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: red;
  cursor: pointer;
}


.animate {
  animation: animatezoom 0.6s
}
  
@keyframes animatezoom {
  from {transform: scale(0)} 
  to {transform: scale(1)}
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
     display: block;
     float: none;
  }
  .cancelbtn {
     width: 100%;
  }
}

@media  (max-width: 360px){
 /* .navbar5{
   display: flex;
flex-wrap: wrap;
text-indent: 10px;
gap: 0.5em;
border-bottom: 1px solid #bdb773;
border-top: 60px solid rgba(252, 227, 198, 0.9);
padding-inline-start: 13em;
background-color: rgba(240, 222, 203, 0.9);
margin-bottom: 0px;
border-top-width: 0px;
padding-left: 0px;
} */

}
 

 p#logs{
  background-color: rgba(252, 227, 198, 0.9);
  color: black;
  margin-bottom: -45px;
}
 p#calls {
  margin-left: 1050px;
   background-color: rgba(248, 227, 220, 0.9);
   background: border-box;
}

a#calling{
  color: black;
  font-size: 80%;


}
#mess p{
  font-family: 'Alata';font-size: 10px;
  color: black;
  

}

@media screen and (max-width: 520px){
img#profil {
    width: 58px;
    margin-right: 6px;
}
/* .navbar5{
  display: flex;
flex-wrap: wrap;
text-indent: 10px;
gap: 0.5em;
border-bottom: 1px solid #bdb773;
border-top: 60px solid rgba(252, 227, 198, 0.9);
padding-inline-start: 13em;
background-color: rgba(240, 222, 203, 0.9);
margin-bottom: 0px;
border-top-width: 0px;padding-left: 0px;
} */


}
img#profil a img {
  width: auto;
  max-width: 88px;
 
}

img.call{
  width: 20px;
  max-width: 88px;
  margin-left: 1090px;

  position: fixed;
  left: 0;
  top: 0;
  border-radius: 10px;
}

html{
  box-sizing: border-box;
  width: auto;
  height: auto;
  margin:0;
  padding: 0;
  
}
*,*:before, *:after{
  box-sizing:inherit;
}
/*Global styles
-------------------------------------------*/
body{
  color:#343434;
  margin:0;
  padding:0;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  line-height: 1.5;
  width: auto;
  height: auto;
  /* height: 1605px; */
}
img#profil{

  width: 180px;
  position: fixed;
   left:0;
   top:0;
   border-radius: 10px;
   
   /* background-color: rgba(247, 219, 184, 0.9); */
}


section#menu-section{

  background-image: linear-gradient(to bottom, rgba(247, 219, 184, 0.9), rgba(186, 186, 221, 0.9)),url(/static/media/3.9125b85d.jpg);  
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* rgba(207, 207, 137, 0.8), rgba(186, 186, 221, 0.9)) */
  animation: example 20s ease infinite;
  /* background-image:  */

}

@keyframes example {
  0%   {background-color: #ffa86e;}
  25%  {background-color: #ffa86e}
  80%  {background-color: rgb(231, 121, 47);}
  100% {background-color: rgb(248, 216, 37);
  }
}

 
.Politica {
  position: fixed;
  z-index: 200;
  border: 1px solid #eee;
  box-shadow: 0 2px 2px rgb(218, 218, 218);
  background-color: white;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  top: 30%;
   /* left: 15%; */
   left: 0px;
  /* width: 50%; */
  margin-top: 20px;
  height: 100%;
  max-height: 1072px;  
  transition: all 0.3s ease-out;
  bottom: 0px;
  top: 0px;
  
  right: 0px;
  width:100%;
  max-width: 882px;
  overflow-y: auto;
  max-height: calc(100vh - 40px);
  
}


.PoliticaOpen {
  animation: openModal 0.4s ease-out forwards;
}

.PoliticaClosed {
  animation: closeModal 1s ease-out forwards;
}



.fade-slide-enter-active {
  animation: openModal 0.4s ease-out forwards;
}



.fade-slide-exit-active {
  animation: closeModal 1s ease-out forwards;
}

@keyframes openModal {
  0% {
      opacity: 0;
      transform: translateY(-100%);
  }
  50% {
      opacity: 1;
      transform: translateY(90%);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes closeModal {
  0% {
      opacity: 1;
      transform: translateY(0);
  }
  50% {
      opacity: 0.8;
      transform: translateY(60%);
  }
  100% {
      opacity: 0;
      transform: translateY(-100%);
  }
}

/* The Modal (background)
.modalp {
  display: none; /* Hidden by default *
  position: fixed; /* Stay in place *
  z-index: 1; /* Sit on top *
  left: 0;
  top: 0;
  width: 100%; /* Full width *
  height: 100%; /* Full height *
  overflow: auto; /* Enable scroll if needed *
  background-color: rgb(0,0,0); /* Fallback color *
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity *
  padding-top: 60px;
} */

/* Modal Content/Box */
.modal__contentp {
  background-color: #fefefe;
  margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 650px; /* Could be more or less, depending on screen size */
  height:1024px;
}

/* The Close Button (x) */
.close {
  position: absolute;
  right: 25px;
  top: 0;
  color: #000;
  font-size: 35px;
  font-weight: bold;
}
#paragraf_pol{
  text-indent: 10px;;
  width:750px;
}
.modalp{
  height: 100%;
  max-height: 1024px;
  width:100%;
  max-width:824px;

}
#modalpolitica{
  width:100%;
  max-width: 824px;
  height: 100%;
  max-height: 524px;
  margin-top:0px;
  padding-left:20px;
  padding-right:15px;
  margin-left:5px;
  margin-right:20px;

}
.modal_polaction{
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  
}

#btn-ok{
  

  color: #00329e;
  background: transparent;
  border: none;
  box-shadow: none;

}

.btn-ok:hover,
.btn-ok:active {
  background: #aec6f8;
}

.close:hover,
.close:focus {
  color: red;
  cursor: pointer;
}


.animate {
  animation: animatezoom 0.6s
}
  
@keyframes animatezoom {
  from {transform: scale(0)} 
  to {transform: scale(1)}
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
     display: block;
     float: none;
  }
  .cancelbtn {
     width: 100%;
  }
}

#btn-det1,#btn-det2,#btn-det3{
  background: #ffa86e;
  color: rgb(202, 179, 245);
  font-weight: 700;
  letter-spacing: .06em;
  text-transform: uppercase;
  font-size: 13px;
  border-radius: 2px;
  padding: 15px 30px;
  transition: background-color 1s ease 0s;
  cursor: pointer;
  border-radius: 20px;
  border: none;
}


a{
  color: #f3e6a5;
 
}
a#cere{
  color:#fcf4cf;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  width: 100%;
  font-size:15px;
}

a:hover{
  text-decoration: none;
}

h1{
  font-size: 100px;
  line-height: 1;
}


#despreContent{
  max-width: 100%;
    width: 100%;
    /* margin-left: 260px; */
    /* margin-right: 100px; */
  
    display: flex;
    padding-left:10%;
   padding-right:10%;
  background-color:  #f8f9fa!important;
}

.content-wrap{
 
  max-width: 1070px;
  width:85%;
margin-left: 260px;
margin-right: 100px;
   padding: 60px 0;
}
.content-wrap1.item-details{


  max-width: 800px;
  width:85%;
   margin: 0 auto;
   padding: 60px 0;
   
}

/* ///sters la 22:58 pt forma iphone responsive */
 .project-item h3{
  margin-bottom: 0; 
  text-align: start;
    font-size: medium;
    font-family: 'Andada';
    /* text-decoration-color: bisque; */
    text-shadow: 1px 1px 3px #caa6ec;
    text-overflow: ellipsis;
    outline-style: dotted;
    outline-color: whitesmoke;
    cursor: zoom-out;
  cursor: zoom-out;
  text-indent: 100px;
  width: 800px;
  margin-left: 60px;

} 

p#firma{
  text-indent: 50px;
  visibility: hidden;
}
.item-details h3 + p{
  font-style: italic;
}
/*advanced selector*/


.item-details h3 ~ p {
  margin: 0;
}

.divider > section{
  border-bottom: 1px none #949393;
  padding: 25px 0;
}

.divider > section:last-of-type {
   border-bottom: none;
}



/*Profile section
------------------------------------------*/
.header  {
   /* background-color:#dfe0ec; */
  /* color: #F7FFF7;  */
  width: 100%;
  background-color:  rgba(223, 223, 253, 0.7);
}

.fadein { position:relative; width:500px; height:332px; }
/* .fadein img#i1#i2#i3  :hover { position:center; left:0; top:0; } */




.item-1
{
	position: relative;
  display: block;
  top: 1em;
   
  
  width: 60%;
  
  font-size: 2em;

	animation-duration: 20s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}

.item-2{
  position: relative;
  display: block;
  top: -5em;
  /* float:center; */

  width: 60%;
  
  font-size: 2em;

	animation-duration: 20s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}

.item-3{
  position: relative;
  display: block;
	top: -13em;
  
  width: 60%;
  
  
  font-size: 2em;

	animation-duration: 20s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}
.item-1{
	animation-name: anim-1;
}

.item-2{
	animation-name: anim-2;
}

.item-3{
	animation-name: anim-3;
}

@keyframes anim-1 {
	0%, 8.3% { left: -100%; opacity: 0; }
  8.3%,25% { left: 20%; opacity: 1; }
  33.33%, 100% { left: 110%; opacity: 0; }
}

@keyframes anim-2 {
	0%, 33.33% { left: -100%; opacity: 0; }
  41.63%, 58.29% { left: 20%; opacity: 1; }
  66.66%, 100% { left: 110%; opacity: 0; }
}

@keyframes anim-3 {
	0%, 66.66% { left: -100%; opacity: 0; }
  74.96%, 91.62% { left: 20%; opacity: 1; }
  100% { left: 110%; opacity: 0; }
}

/*Projects
----------------------------*/
.projects{
  background:  #f8fcf8;
}

.projects a{
  color: #2F3061;

}

.projects .btn_projects{
  color:#F7FFF7;
  background:  #2F3061;
  text-decoration: none;
  padding: 8px;
  border-radius: 4px;
  display: inline-block;

}

.projects .btn_projects:hover{
  background: rgba(47, 48 , 97, 80%);
}

.project-item{
  overflow: hidden;
  animation: fade-slide-in 0.3s ease-out forwards;

}


.project-item h3{
  margin-top: 0;
}
/*Work experience
-----------------------------*/


.work{
  background: #edf6fa;
}




/* Education ------------------
*/
.despre{

  background-size: cover;
  background-position: top right;
  padding-bottom: 0px;
  background-color: white;


}

.despre p{
  width:60%;
}


/* Create two unequal columns that floats next to each other */
/* Left column */
/* .leftcolumn {   
  float: center;
  width: 75%;
} */


/* Fake image */
.despre_img {
  width: 100%;
  /* position: center; */
  height:100%; opacity: .5; 
  padding: 20px;
  background-image: url(/static/media/2i.2de64956.png);
}

/* Add a card effect for articles */
.card {
  /* background-color: white;
  padding: 20px;
  margin-top: 20px; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 800px;
  margin: 40px 30px;
  text-align: inherit;
  height: 100%;
  width:150%;
  margin-right: 0px;
  margin-bottom: 20px;

}

p#profi{
  border-top: gray 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-indent: 50px;

  

}
#text_despre{
  margin-top:10px;
  /* margin-left: 10px; */
  /* margin-right:10px; */
  margin-bottom: 10px;;

  height: 650px;
  margin-left: 35px;
  margin-right: 20px;
}

ul{
  margin-top:0px;
  text-align: left;
  /* text-indent: 150px; */
  list-style-type: disc;
}
p#d_cor{
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  text-align: left;
  padding-bottom: 20px;
  text-indent: 50px;
  
  
}

img#s1{
  opacity: 0;
}
.row {

width:600px;
}
/*Contact Info
---------------------------------*/

/* footer{
  background: #2F3061;
  color: #F7FFF7;
  
} */

.contact-list{
  list-style-type: none;
  padding:0;

}

.contact-list a{
  padding: 15px;
  display: inline-block;
}

#articol_recent{
 text-indent:90px;
}
/* Responsive
-----------------------*/




@media screen and (min-width:750px){

  #menu-section {
    /* position:absolute; */
    top:0;
    left:0;
    width: 100%;
    height:100%;;
  }
  div.infoTransitions {
    display: flex;
    justify-content: space-between;
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
    position: relative;
    margin-top: 0px;
    /* transition: opacity .5s ease-in; */
    height: 600px;
    margin-left: 23%;
  }
  
  
.item-1
{
	position: relative;
  display: grid;
  /* top: -7.5em; */
 
  /* height:100px; */
  width: 300px;
  /* margin-left: 0px; */
  font-size: 1em;

	animation-duration: 40s;
	animation-timing-function: ease-in-out;
  animation-iteration-count: 1;

  margin-top: 0px;
  height: 80px;
  top: 0px;
  left: 120px;
}

.item-2{
  position: relative;
  display: grid;
  top: -40em;
  /* float:center; */

  width: 440px;
  margin-left: 750px;
  font-size: 1em;
 
	animation-duration: 30s;
	animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

}

.item-3{
  position: relative;
  display: grid;
	top: 0px;
  /* margin-left: 200px;
  width: 540px; */
  width: 450px;
  margin-left: 720px;
  
  font-size: 1.5em;
  height:140px;

	animation-duration: 30s;
	animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  
}
.item-1{
	animation-name: anim-1;
}

.item-2{
  animation-name: anim-2;
  
}

.item-3{
	animation-name: anim-3;
}

@keyframes anim-1 {
	0%, 8.3% { left: -100%; opacity: 0; }
  8.3%,25% { left: 1%; opacity: 1; }
  33.33%, 100% { left: 110%; opacity: 0; }
}

@keyframes anim-2 {
	0%, 33.33% { left: -120%; opacity: 0; }
  41.63%, 58.29% { left: 20%; opacity: 1; }
  66.66%, 100% { left: 110%; opacity: 0; }
}

@keyframes anim-3 {
	0%, 66.66% { left: -120%; opacity: 0; }
  74.96%, 91.62% { left: 20%; opacity: 1; }
  100% { left: 110%; opacity: 0; }
}
h3#cand{
  font-family: '-webkit-pictograph;';
  font-size: 20px;
  width:724px;
 }
 img#profil{

  width: 200px;
  position: relative;
   left:40px;
   top:0px;
   border-radius: 10px;
  
   
   /* background-color: rgba(247, 219, 184, 0.9); */
}
.videoTag{
  /* visibility: hidden; */
  position:absolute;
  display: none;
  min-height: 360vh;
}



.content-wrap2{

    width: 900px;
    margin-left: 10px;
    margin-right: 200px;
    padding: 0px 0;
  
    height: 765px;
}
 div.anunt{
 
    position: absolute;
   
     margin-top: 0px;
    top: 642px;
    margin-left: 510px;
    width: 500px;
   }
   #tickr-scroll a {
    color: #ff0000!important;
    text-decoration: none;
    font-weight: bold;
}

div#anunturi{
  position: fixed;
  /* top:0%; */
 bottom: -10%;
z-index:1;
   margin-top: 460px;
  /* left: 130px; */
  width: 2600px; 

  
  
}



#par{
  margin-top: 65px;
}

.news {
  width: 160px
}

.news-scroll a {
  text-decoration: none
}

.dot {
  height: 6px;
  width: 6px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 2px !important;
  background-color: rgb(207, 23, 23);
  border-radius: 50%;
  display: inline-block
}

#tickr-box {
  background: #C0C0C0;
  max-width: 950px;
  padding: 3px;
  margin: 0 auto 0 auto;
  border-radius: 4px;
}
}

h2#titlu_mob {
  line-height: 1;
  /* margin-top: -60px; */
  padding-top: 20px;
  height: 66px;
  font-size: 20px;
  padding: 1px 2px;
  text-align: center;
  padding-right: 150px;
  width: 510px;
  padding-left: 0px;

  background-color: #edeff2; ;
  border-right-width: 20px;
  margin-right: 20px;
  font-family: 'system-ui';
  
  text-shadow: 2px 2px 5px #caa6ec;
  text-indent: 15px;
  visibility: visible;
}



h3#asistenta {
  margin-bottom: 0;
  outline-style: dotted;
  outline-color: whitesmoke;
  cursor: zoom-out;
  text-indent: 100px;
  width: 800px;
  margin-left: 60px;
  visibility: hidden;
}
img#human{
  width:330px;
  
  
  /* margin-bottom: 490px; */
  /* position:absolute; */
  margin-left:35px;
  height:440px;
  transition: opacity .5s ease-in;
  margin-top:140px;

  
}




img#titlu_poza{
  width:470px;
  opacity: 0.8;
  /* background-color: #151823; */
  /* margin-top:39px; */
  height:750px;
}


img#anunt{
  width:330px;
  /* margin-left:880px; */
  height:440px;
  margin-top:240px;

}

 #link1{
  margin-left:27px;
   margin-right:20px;
   
 }

 /* #link2{
  width: 300px;
   max-width: 100%;
 } */

 #link3{
margin-left:15px;
 margin-right:30px; 

 }

  header, p#logs{
    /* , footer */
      text-align: center;
      /* background-color:  rgba(252, 227, 198, 0.9); */
     background-color:  rgba(250 225 196 / 90%);
}
h2#articol_recent {
  width:150%;
  text-indent: 570px;
  margin-top:0px;
  padding-top:25px;
  word-spacing: 4px;
  -webkit-text-decoration-style:wavy;
          text-decoration-style:wavy;
   -webkit-text-decoration-color: #a7510b;
           text-decoration-color: #a7510b;
  font-weight:400;
  font-size:x-large;
  background-color: #ffa86e;
  text-shadow: 2px 2px 5px #ffa86e ;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 
  border-radius: 25%;

  
}


h2#oferim{
  width:1651px;
  text-indent: 170px;
  margin-top:0px;
  padding-top:10px;
  word-spacing: 4px;
  -webkit-text-decoration-style:wavy;
          text-decoration-style:wavy;
   -webkit-text-decoration-color: #a7510b;
           text-decoration-color: #a7510b;
  font-weight:500;
  font-size:xx-large;
  /* background-color: #ffa86e; */
  text-shadow: 2px 2px 5px #ffa86e ;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 
}

h2#despre_noi {
  width:1651px;
 
  margin-top:0px;
  padding-top:10px;
  word-spacing: 4px;
  -webkit-text-decoration-style:wavy;
          text-decoration-style:wavy;
   -webkit-text-decoration-color: #a7510b;
           text-decoration-color: #a7510b;
  font-weight:500;
  font-size:xx-large;
  /* background-color: #ffa86e; */
  text-shadow: 2px 2px 5px #ffb888 ;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; 
  font-weight: 400;
  margin-top: -40px;
  text-indent: -20px;
}
#noutati{

  width: 100%;
   
    padding-left: 15%;
    padding-bottom: 5%;
}

.popover-header{
  
  font-weight: bolder;
}
.popover-body{
  background-color: antiquewhite;
  border-radius: 2rem;
}

#criterii{
  text-indent:5px;
}
#d_cor{
  padding-left:20px;
  padding-right: 5px;
 text-indent: 10px;
}
#pol{
  border:none;
  background-color: #666a6a;
  text-decoration: underline;
  border-radius: 10px;
}

  
  .project-item img {
      float: left;
      margin-right: 20px;
  }
  .job-item{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 1px;
      -webkit-column-gap: 1px;
              column-gap: 1px;
      width: 100%;
      padding-top:1rem;
      
  }

  #link4{
   
    margin-right: 2.5%;
    padding-bottom: 40px;
    margin-left: 2%;
  }
  
#myclock {
  position: relative;
  top: 20%;
  left: 85%;
  transform: translateX(-50%) translateY(-50%);
  color: #3e2d55;
  font-size: 15px;
  font-family: Orbitron;
  letter-spacing: 2px;
 


}
  .projects{
    background-color: #ecebf6;
  }
  #post1{

    /* width:500px; height:265px;  */
    border:none;overflow:hidden;margin-right: 20px;margin-left: 20px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 4px;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 70%;
  }
  
  #post2{
    /* width:500px; height:260px; */
    border:none;overflow:hidden;margin-left:20px;
    display: inline;
    margin-right: 20px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 4px;


    position: absolute;
    top: 0;
    left: 0;
    /* bottom: 0; */
    /* right: 20px; */
    width: 100%;
    height: 70%;
  }
  #post3{
    /* width:320px; height:285px; */
    border:none;overflow:hidden;margin-right: 0px;
    display: inline;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 4px;


    position: absolute;
    top: 0;
    left: 0;
    /* bottom: 0; */
    right: 0;
    width: 100%;
    /* height: 100%; */
  }
  .MuiCardActions-root {
    display: flex;
    padding: 8px;
    align-items: center;
    
}



 .copy-link {
  padding-left: 30px;
  padding-right: 30px;
}



.targets {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  /* margin-bottom: 20px; */
  padding-left: 1rem;
  padding-top: 1rem;
}


.linkA {
  /* display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border-radius: 2px; */
  /* background-color: #eee; */
  background-color: #2e2e46;
  padding-top: 10px;
    vertical-align: bottom;
    font-size: 23px;
    color: yellow;
    margin: 0;
  width:100%;
    animation: marquee 10s linear infinite;
  display: inline-block;
  /* padding-right: 10px; */
  text-decoration: none;
 
}


#anunturi:after {
	content:'';
  top:0;
	transform:translateX(100%);
	width:100%;
	height:115px;
	position: absolute;
	z-index:1;
	animation: slide 5s infinite;
	 
  /* 
  CSS Gradient - complete browser support from http://www.colorzilla.com/gradient-editor/ 
  */ /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
	background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
}

/* animation */

@keyframes slide {
	0% {transform:translateX(-100%);}
	100% {transform:translateX(100%);}
}
img#anuntnews{
  max-width: 129px;
  height: auto;
  display: inline;
  /* object-position: left bottom; */
  /* padding-bottom: 15px; */
  /* background-color:#2e2e46;
  border-radius: 25%; */

  position: relative;
}


.position-fixed-top {
  position:fixed !important;
  top: 0;
}

.containerNews{
  border-radius: 3px;
    /* background-color: #f2f2f2; */
    /* padding: 20px; */
    padding-top: 0px;
    margin-bottom: 105px;
    margin-right: 180px;
    margin-left: 40px;
    /* margin-bottom:0px; */
    width:100%;
 
    display: inline-flex;
   

}
/* .row2{
  width:100%;
  margin-right: auto;
  /* display:inline-flex; */
  /* margin-left: auto; */
/* }  */
.pen-url {
  margin-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.hidden {
  display: none;
}



 html, body{
  /* max-width: 100%; */
  overflow-x: hidden;
  }
  .fb{
  width: 100%;
    height: auto;
  }
  .work{
    background-color: white;
    height:auto;
    width: 100%;
    padding-bottom: 15%;
    padding-top:5%;
    /* padding-right:60px; */
   
  }
 
 
  .contact-list{
      display: flex;
      justify-content: center;
  }
  p#logs{
    background-color: rgba(252, 227, 198, 0.9);
    color: black;
    margin-bottom: -45px;
    margin-top: 0px;
  }
  p#calls{
    margin-left: 1050px;
    background-color:  rgba(252, 227, 198, 0.9);
  }
 
  a#calling{
    color: black;
    font-size: 80%;
  }
  #despre_img {
    width: 20%;
    background-size:cover;
    /* height:200px; */
     opacity: .2; 
    /* border-radius: 0px; */
    /* padding: 4px; */
    margin-left: 50px;
    margin-top: 30px;
    background-image: url(/static/media/2i.2de64956.png);
    background-repeat: repeat-x;
    height: auto;

  }
  /* h2#despre_noi {
    font-family: 'Caveat', cursive;
    font-weight: 400;
    margin-top:-40px;
    text-indent: -20px;
} */
  .content-wrap1.item-details{
    max-width: 800px;
    width: 105%;
    margin: 0 auto;
    padding: 60px 0;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 60px;
    padding-left: 0px;
}
  

#desen1{
  float:left; 
  background:Red;
  /* width:15%; */
  height:280px;
}

.s{
  display: flex;
}

#desen2{
  float:right;
  background-image:url(/static/media/despre_f.ffc480be.png);
  /* width:35%; */
  height:280px;
  /* margin-top:56px; */
}

#videoD{
  /* margin-left: 146px; */
  width: 140%;
  height: auto;
  /* width: 50%; */
  float: center;

  
} 



#despreS{
  width: 100%;
  height: auto;
 
  margin-bottom:5%;
  max-width: 1950px;
 position: relative;


  
}


/* Clear floats after the columns */
#despreS::after {
  content: "";
  display: table;
  clear: both;
}


 



/* .row1{width:400%; display:inline-flex}
.row.full-width {  margin: 0 auto; max-width: 100%; width: 100%; }
.row.custom-width-90 {  margin: 0 auto; max-width: 90%; width: 90%; }
.row.full-width-padding {  margin: 0 auto; max-width: 98%; width: 98%; }
.row.no-gutter { margin:0 auto;} */
.row.no-gutter [class*='col-']:not(:first-child), .row.no-gutter [class*='col-']:not(:last-child) { padding-left: 0; padding-right: 0; }
/* .row.no-gutter-all { margin:0 auto;} */
.row.no-gutter-all [class*='col-'] { padding-left: 0; }
#mc_embed_signup { margin-top:15px;     height: 40px;
  width: 320px; border-radius:5px;}
#mc_embed_signup input[type="email"] { border-radius:5px; width:100%; padding:10px 15px; border:0;    width: 320px;}
#mc_embed_signup input[type="submit"] { margin-top:2px; transition: all 250ms ease-out 0s; border-radius:5px;     width: 320px; padding:10px 15px; border:0;  color:#fff; background:#cb6428;}
#mc_embed_signup input[type="submit"]:hover { background:#808080;}

/* Social Buttons ---------------------- */
.social-linkss {margin:20px 0 0; padding:0;}
.social-linkss li { list-style: none;  display: inline-table;}
.social-linkss li a { color:#fff; font-size:13px; display: inline-block;  padding-top:7px;  text-align: center;  height: 34px;  width: 34px;  position: relative; z-index: 1; transition: color 0.15s; -moz-osx-font-smoothing: grayscale; border-radius:50%;}
.social-linkss li a::before { content: '';  position: absolute;  top: 0;  left: 0;  width: 100%;  height: 100%;  border-radius: inherit;  z-index: -1;  box-shadow: inset 0 0 0 35px #808080;  transform: scale3d(0.7, 0.7, 1);  transition: box-shadow 0.15s, transform 0.15s;}
.social-linkss li a:hover::before {  transform: scale3d(1.0, 1.0, 1); box-shadow: inset 0 0 0 35px  #009DE0;}

.copyright {padding:15px 0; background:#525151;}
.copyright p {font-size:14px; color:#ccc; margin-bottom:0;}

/* Back to Top Button  ----------------------- */
#back-to-top { color:#fff; position:fixed; bottom:20px; right:20px; z-index:99; display:none; text-align: center; border-radius: 50%; -moz-border-radius: 50%;  -webkit-border-radius:  50%; -o-border-radius:  50%;    z-index: 10000;    height: 59px;    width: 59px;    background-color:#3190E7;    background-repeat: no-repeat;    background-position: center;  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);  transform: scale3d(0.7, 0.7, 1);  transition: box-shadow 0.15s, transform 0.15s;}
#back-to-top i { padding-top:20px; font-size:17px; }
#back-to-top:hover { background:#e77a21;  transform: scale3d(1.0, 1.0, 1);}

 .col-sm-3  {
  display:-webkit-box;
  box-sizing: border-box;
  padding-left: 30px;  
  min-height: 1px;
  padding-right: 15px;
  
}
.card-container{
  /* float:center; */
  display: block;
  /* inline-size: auto; */


}
.despre {
  background-size: cover;
  background-position: top right;
  padding-bottom: 0px;
  background-color: white;
  margin-top:250px;
  height: 100%;
  width: 100%;

  

}
#pozeC{
  margin-top:260px;
  
}

.despre::after{
	transform-origin: left bottom;
	transform: skewY(3deg);
}

.row:before{
  display: table;
}

img#footerImg {
  box-shadow: -1px 0px 17px gainsboro;
  border-radius: 20px 20px ;
}
#articol_recent {
  text-indent: 190px;
}

#postare{
  display: inline-flex; margin-left: 20px;margin-right: 20px;
 }


@media screen and (max-width:749px){
  h1{
      font-size:75px;
      line-height: 0.9;
      margin-bottom: 20px;
  }
  h2{
       line-height: 1; 
      margin-top: -35px;
      font-size: 30px;
      padding: 1px 2px;
      text-align: center;
      width: 100%;

  }

 .project-item h3{
    text-indent: 15px;
    text-align: start;
    font-size: medium;
    font-family: cursive; 
    /* text-decoration-color: bisque; */
    /* text-decoration-line: underline; */
    /* text-decoration-skip-ink: inherit; */
    /* text-overflow: ellipsis;*/
  } 
  .contact-list a {
      padding: 5px;
  }

  .imgcontainer img#avatar {
    width: 40%;
    border-radius: 50%;

  }
  p#logs{
    
    text-align: center;
    background-color: rgba(252, 227, 198, 0.9);
    color: black;
    padding-top: 30px;
    /* margin-bottom: -45px; */
  
  }
  p#calls{

    margin-left: 140px;
    /* background-color: blanchedalmond; */

    /* background-color: blanchedalmond; */
    margin-top: -50px;
  }

  p#firma{
    /* text-indent: 10px; */
    visibility: hidden;
  }
  
  a#calling{
    color: black;
    font-size: 80%;
  }
 
  img#profil{

    width: 130px;
    position: fixed;
     left:0;
     top:0;
     border-radius: 10px;
     
     /* background-color: rgba(247, 219, 184, 0.9); */
  }


  .headings{
    padding-bottom: 0px;
        height: 660px;
        width: 389px;
  }
  
  .item-2 {
    position: relative;
    display: block;
    top: -7em;
    width: 80%;
    font-weight: 600;
    font-size: 1.5em;
  
    animation-duration: 20s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  
}

.item-1{
  position: relative;
  display: block;
  /* top: em; */
  width: 60%;
  font-size: 1.5em;
  animation-duration: 20s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  font-weight: 600;
  width:300px;
}

.item-3{

  position: relative;
  display: block;
  top: -18em;
  width: 80%;
  font-size: 1.5em;
  animation-duration: 20s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  font-weight: 600;

}
#postare{
  margin-left: 30px;
  margin-right: 30px;
  height: 780px;
}

#articol_recent{

  height: 67px; width: 354px;

}

.card-container{
  /* float:center; */
  display: block;
  /* inline-size: auto; */
  width: 300px;

}


.card {
  width: 360px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  
    margin: 40px 30px;
    text-align: inherit;
    height: 100%;
    width: 150%;
    margin-right: 0px;
    margin-bottom: 20px;
}
.despre{
  width: 500px;
}

.content-wrap1.item-details{


  margin-left: 0px;
  width: 240px;
  margin-right: 600px;
  padding: 60px 0;
}
.pozaTranzitie{
  margin-top: 0px;
    height: 299px;
    margin-left: 60px;
    width: 450px;
}
.content-wrap{
    width: 300px;
    margin-left: 60px;
    margin-right: 600px;
    padding: 60px 0;
  
      
  
}
.row {

  width: 200px;
  display: -webkit-inline-box;
}

#text_despre{
  width: 330px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}
#criterii{
  margin-top: 0px;
    text-align: left;
    text-indent: 10px;
}
#post2{
  /* width:552; height: 349; */
  border:none;overflow:hidden;width: 348px;height: 300px;margin-right: 80px;
}

#post1{
  /* width:552; height:340; */
   border:none;overflow:hidden;height: 323px;width: 350px;margin-right: 0px;
}

#post3{
  border:none;
  width: 348px;
  overflow: hidden;
}





.col-sm-12{
  margin-left: 0px;
  width: 90px;
}

.col-sm-3{
  margin-left: 0px;
  width: 110px;
 display: -webkit-box;
 box-sizing: border-box;
 min-height: 1px;
 height: 59px;
}




  .text-center{
    width: 360px;
    height: 40px;
    margin-top: 20px;
    border-bottom-width: 0px;
    margin-left: 30px;
  }
 

.row { max-width: 1170%; width:100%; display: -webkit-inline-box; }
/* .row1{width:400%; display:inline-flex}*/
.row.full-width {  margin: 0 auto; max-width: 100%; width: 100%; }
.row.custom-width-90 {  margin: 0 auto; max-width: 90%; width: 90%; }
.row.full-width-padding {  margin: 0 auto; max-width: 98%; width: 98%; }
.row.no-gutter { margin:0 auto;} 
.row.no-gutter [class*='col-']:not(:first-child), .row.no-gutter [class*='col-']:not(:last-child) { padding-left: 0; padding-right: 0; }
/* .row.no-gutter-all { margin:0 auto;} */
.row.no-gutter-all [class*='col-'] { padding-left: 0; padding-right: 0; }
#mc_embed_signup { margin-top:15px;}
#mc_embed_signup input[type="email"] {  border:0; padding-top: 3px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 3px;
  width: 89px;}
#mc_embed_signup input[type="submit"] { margin-top:5px; transition: all 250ms ease-out 0s; border-radius:5px; border:0; font-size: smaller; color:#fff; background:#cb6428;
  padding-top: 2px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 3px;
  width: 89px;
  height: 35px;}
#mc_embed_signup input[type="submit"]:hover { background:#808080;}

/* Social Buttons ---------------------- */
.social-linkss {margin:20px 0 0; padding:0;}
.social-linkss li { list-style: none;  display: inline-table;}
.social-linkss li a { color:#fff; font-size:13px; display: inline-block;  padding-top:7px;  text-align: center;  height: 34px;  width: 34px;  position: relative; z-index: 1; transition: color 0.15s; -moz-osx-font-smoothing: grayscale; border-radius:50%;}
.social-linkss li a::before { content: '';  position: absolute;  top: 0;  left: 0;  width: 100%;  height: 100%;  border-radius: inherit;  z-index: -1;  box-shadow: inset 0 0 0 35px #808080;  transform: scale3d(0.7, 0.7, 1);  transition: box-shadow 0.15s, transform 0.15s;}
.social-linkss li a:hover::before {  transform: scale3d(1.0, 1.0, 1); box-shadow: inset 0 0 0 35px  #009DE0;}

.copyright {padding-top: 0px; background:#525151;    height: 67px;
  }
.copyright p {font-size:14px; color:#ccc; margin-bottom:0;    height: 50px;
  margin-top: 0px;}

/* Back to Top Button  ----------------------- */
#back-to-top { color:#fff; position:fixed; bottom:20px; right:20px; z-index:99; display:none; text-align: center; border-radius: 50%; -moz-border-radius: 50%;  -webkit-border-radius:  50%; -o-border-radius:  50%;    z-index: 10000;    height: 59px;    width: 59px;    background-color:#3190E7;    background-repeat: no-repeat;    background-position: center;  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);  transform: scale3d(0.7, 0.7, 1);  transition: box-shadow 0.15s, transform 0.15s;}
#back-to-top i { padding-top:20px; font-size:17px; }
#back-to-top:hover { background:#e77a21;  transform: scale3d(1.0, 1.0, 1);}


#politica{    padding-top: 40px;}
.row:before{
  display: table;
}

img#footerImg {
  box-shadow: -1px 0px 17px gainsboro;
  border-radius: 20px 20px ;
  width: 90px;height: 40px;
  
}

html, body {
  width: 100%;
  overflow-x: hidden;
  background-color: rgba(252, 227, 198, 0.9);
}

button#pol{
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  
    background-color: rgb(105, 104, 104,0.7);
    cursor: pointer;
    border: hidden;
  
}


}

.despre_img {
  width: 100%;
  padding: 10px;
  background-image: url(/static/media/2i.2de64956.png);
  height:auto;
  background-size: contain;
  background-repeat: no-repeat;
}


.card-container{
  /* float:center; */
  display: block;
  /* inline-size: auto; */


}

.titlee {
  color: grey;
  font-size: 18px;
  margin-left: 50px;
}

.jtitle{
  margin-left: 50px;
}

p#but {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

a#sign {
  text-decoration: none;
  font-size: 22px;
  color: black;
}

p#but:hover, a#sign:hover {
  opacity: 0.7;
}

/* img#cris{
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

img#cora{
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
} */

#pozaTranzitie{
  /* background-image: url('../images/3.jpg'); */
  width: 100%;
  height: 20%;
  
}




label {
  font-weight: bold;
  margin: 0.5rem 0;
  color: #464646;
}

input {
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.4rem 0.2rem;
  color: #383838;
}

input:focus {
  outline: none;
  background: #fff1c4;
}

.btn {
  font: inherit;
  /* padding: 0.5rem 1.5rem; */
  border: 1px solid #00329e;
  background: #f67722;
  /* color: white; */
  border-radius: 6px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.26);
  cursor: pointer;
  margin: 0 0.5rem;
  padding-left: 10px;
  margin-right: 0px;
}

.btn:hover,
.btn:active {
  background: #f67722;
  border-color: #f67722;
}

.btn--passive {
  color: #00329e;
  background: transparent;
  border: none;
  box-shadow: none;
}

.btn--passive:hover,
.btn--passive:active {
  background: #aec6f8;
}

.btn--danger {
  background: #d30808;
  color: white;
  border-color: #d30808;
}

.btn--danger:hover,
.btn--danger:active {
  background: #ff3217;
  border-color: #ff3217;
}

.modal {
  
  position: fixed;
  z-index: 100;
  background: white;
  border-radius: 10px;
  width: 80%;
  top: 40px;
  left: 10%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  display: none;
}

.modal.visible {
  display: block;
  animation: fade-slide-in 0.3s ease-out forwards;
  margin-top: 10px;
    padding-top: 20px;
    padding-bottom: 50px;
}

.modal .modal__title {
  margin: 0;
  padding: 1rem;
  border-bottom: 1px solid #00329e;
  background: #00329e;
  color: white;
  border-radius: 10px 10px 0 0;
}

.modal .modal__content {
  padding: 1rem;
}

.modal .modal__actions {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

.movie-element {
  margin: 1rem 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
  display: flex;
}

.movie-element h2,
.movie-element p {
  font-size: 1.25rem;
  margin: 0;
}

.movie-element h2 {
  color: #383838;
  margin-bottom: 1rem;
}

.movie-element p {
  color: white;
  display: inline;
  background: #f67722;
  padding: 0.25rem 1rem;
  border-radius: 15px;
}

.movie-element__image {
  flex: 1 1;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
}


.movie-element__image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.movie-element__info {
  flex: 2 1;
  padding: 1rem;
}

#entry-text {
  margin: 2rem;
  font-size: 1.5rem;
  text-align: center;
}

#movie-list {
  list-style: none;
  width: 40rem;
  max-width: 90%;
  margin: 1rem auto;
  padding: 0;
}

#backdrop {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
  pointer-events: none;
  display: none;
}

#backdrop.visible {
  display: block;
  pointer-events: all;
}

#add-modal .modal__content {
  display: flex;
  flex-direction: column;
 
}

@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
  
}

@keyframes fade-slide-in {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0);
  }
}


.copyright {
  padding: 15px 0;
  background: #525151;
  
}
.copyright p {
  font-size: 14px;
  color: #ccc;
  margin-bottom: 0;
}
.text-center {
  text-align: center;
  text-indent: 500px;
}
#back-to-top {
  color: #fff;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  display: inline;
  text-align: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  z-index: 10000;
  height: 59px;
  width: 59px;
  background-color: rgba(186, 186, 221, 0.9);
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  transform: scale3d(0.7, 0.7, 1);
  transition: box-shadow 0.15s, transform 0.15s;
 
}

#back-to-top i {
  padding-top: 20px;
  font-size: 17px;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width:960px){

  
  .infoTransitions{
    display: none;
  }
  .pozaTranzitie{
    display: none;
  }
  img #human{
    display: none;
  }

  /* #anunturi{
    position: fixed;
    z-index: 1;
    top:76%;
  } */

  #politica{
    z-index: 2;
  }
 .news-scroll{
   width:auto;
    pointer-events: none;
 }
 
 #anunturi{




  z-index:1;
  overflow: hidden;
 pointer-events: none;

 position: fixed;
 bottom: 0 ;
 margin:0;
 width: 100%;
 display: none !important;

 }


 .news{
  height:auto;
  /* background-color:Blue; */
  padding: 10px 30px 10px 30px;
  font-size: x-large;
  color: white;
  z-index:1;
   display: block;
}

 /* #anuntnews{
 width:max-content;
 pointer-events: none;
 
 } */
 .link{
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
   font-size: large;
   scroll-behavior: auto;


   display: inline;
   text-decoration: none;
  
 }
  .anunt{
    display: none;
  }

  .containerNews{
    /* width: 100%;
    display: fixed;
    margin-left: 0px;
  */

padding-top: 10px;
    vertical-align: middle;
    font-size: 25px;
    color: yellow;
    margin: 0;
  width:100%;
    animation: marquee 10s linear infinite;
  display: inline-block;
  /* padding-right: 10px; */
}
  
  

  .card{
    max-height: 950px;
    height: 100%;
    background-color: rgb(255, 255, 255);
    width: 100%;
    position: relative;
    display: grid;
    place-items: center;
    line-height: 1.5;
    box-shadow: rgb(0 0 0 / 18%) 0px 20px 30px;
    margin-top: 15%;
    max-width: 700px;
    width: 100%;;
    top: 50px;
  }

  img#profil{

    width: 200px;
    position: relative;
     left:20px;
     top:-55px;
     border-radius: 10px;
  }
.textBox{
  padding-right: 5%;
    max-width: 300px;
    width: 100%;
    margin-right: 0px;
  
}

.md-form{
  color: rgb(78, 94, 114);
  margin: 0px;
  padding: 0px;
  text-align: center;
  font-family: monospace;
  overflow: hidden;
  max-width: 300px;
  width: 100%;

}


  .menu-icon {
    display: block;
    position: absolute;
    right: 0;
    font-size: 1.8rem;
    cursor: pointer;
    margin-top: 0px;
    width: 66px;
    height: 56px;
}
.navbar {
  background: linear-gradient(
90deg
, rgb(99, 97, 97) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  width:100%;
  z-index: 100;
}

.videoTag{
  width:100%;

}

.card-body px-lg-5 pt-0{
  width:100%;
}


#post2 {
  border: none;
  overflow: hidden;
  height: 310px;
  width: 330px;
  margin-right: 0px;
  margin-left: 14px;
  padding-top: 15px;
}
#post1 {
  border: none;
  overflow: hidden;
  height: 310px;
  width: 330px;
  margin-right: 0px;
  margin-left: 14px;
  padding-top: 10px;
}
#post3 {
  border: none;
  width: 330px;
  margin-top: 25px;
  margin-left: 14px;
  overflow: hidden;
}
.job-item {
  display: table;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 1px;
  grid-column-gap: 1px;
  -webkit-column-gap: 1px;
          column-gap: 1px;
}
}

#nt-example1 {
  max-width: 620px;
  margin: auto;
}

#nt-example1-container {
  text-align: center;
}

#nt-example1-container i {
  font-size: 36px;
  margin: 8px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

#nt-example1-container i:hover {
  color: #333;
}

#nt-example1 li {
  color: #4e4e4e;
  background: #f2f2f2;
  overflow: hidden;
  height: 80px;
  padding: 10px;
  line-height: 30px;
  list-style: none;
  font-size: 24px;
  text-align: left;
  border-bottom: 1px dotted #2c8162;
}

#nt-example1 li:hover {
  background: #fff;
}



@media screen and ( max-width:812px){
  
  h1{
      font-size:75px;
      line-height: 0.9;
      margin-bottom: 20px;
  }
  /* h2{
       line-height: 1; 
      margin-top: -40px;
      font-size: 27px;
      padding: 1px 2px;
      text-align: center;
      padding-right: 200px;
      width: 510px;
      padding-left: 0px;
      height: 90px;
      border-right-width: 20px;
      margin-right: 20px;
      font-family: 'Andada';
      font-style: italic;
      text-shadow: 2px 2px 5px #caa6ec ;
      margin-bottom: 0px;

  } */
  

  .project-item h3{
    text-indent: 19px;
    text-align: start;
    font-size: medium;
    font-family: 'Andada';
    /* text-decoration-color: bisque; */
    text-shadow: 1px 1px 3px #caa6ec ;
    text-overflow: ellipsis;
    outline-style: dotted;
    outline-color: whitesmoke;
    cursor: zoom-out;
  } 
  .contact-list a {
      padding: 5px;
  }

  .imgcontainer img#avatar {
    width: 40%;
    border-radius: 50%;

  }
  p#logs{
    
    text-align: center;
    background-color: rgba(252, 227, 198, 0.9);
    color: black;
    padding-top: 30px;
    text-transform: capitalize;
    margin-top: 40px;
    padding-bottom: 0px;
    height: 32px;
    /* margin-bottom: -45px; */
  
  }
  .projects{
    background-color: #ecebf6;
  }
  p#calls{


    margin-left: 150px;
    padding-bottom: 20px;
    /* background-color: blanchedalmond; */

    /* background-color: blanchedalmond; */
    margin-top: -50px;
  }

  p#firma{
   visibility: hidden;
  }
  
  a#calling{
    color: black;
    font-size: 65%;
  }
  .heading{
    width: 100%;
    padding-left: 0px;
  }
 
  img#profil{

    width: 130px;
    position: fixed;
     left:0;
     top:0;
     border-radius: 10px;
     
     /* background-color: rgba(247, 219, 184, 0.9); */
  }
  #myclock {
    position: relative;
    top: -60px;
    left: 70%;
    transform: translateX(-50%) translateY(-50%);
    color: #3e2d55;
    font-size: 15px;
    font-family: Orbitron;
    letter-spacing: 1px;
   
  
  
  }
  .headings{
    padding-bottom: 0px;
        height: 660px;
        width: 389px;
  }
  
  .item-2 {
    position: relative;
    display: block;
    top: -6em;
    width: 80%;
    font-weight: 600;
    font-size: 1.5em;
  
    animation-duration: 20s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  
}

.item-1{
  position: relative;
  display: block;
  /* top: em; */
  top:-4px;
  /* width: 60%; */
  font-size: 1.5em;
  animation-duration: 20s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  font-weight: 600;
  width:200px;
}

.item-3{

  position: relative;
  display: block;
  top: -15em;
  width: 80%;
  font-size: 1.5em;
  animation-duration: 20s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  font-weight: 600;

}
#postare{
  margin-left: 25px;
  margin-right: 20px;
  height: 780px;
}

.fb{
      height: 1275px;
}
#articol_recent{

  height: 67px;
  width: 530px;
  text-indent: 50px;

}
.despre_img {
  width: 100%;
  /* position: center; */
  padding: 10px;
  background-image: url(/static/media/2i.2de64956.png);
  height: 100px;
  background-size: contain;
  background-repeat: repeat;
}
.card-container{
  /* float:center; */
  display: block;
  /* inline-size: auto; */
  width: 300px;

}


.card {

  margin-right: 10px;
  width: 330px;
  margin-top: 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  
    margin: 40px 30px;
    text-align: inherit;
    height: 100%;
    
  
    margin-bottom: 20px;
}

.job-item{
  display: table;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 1px;
  -webkit-column-gap: 1px;
          column-gap: 1px;
  position: relative;
}

.work{
  background: #fcf7ff;
}
.despre{
  width: 500px;
}

h2#despre_noi{
  margin-bottom: 0px;
  height:12px;
}
.content-wrap1.item-details{


  margin-left: 0px;
  width: 500px;
  margin-right: 600px;
  padding: 60px 0;
}
.pozaTranzitie{
  margin-top: 0px;
    height: 299px;
    width: 250px;

}
.content-wrap{
    width: 300px;
    margin-left: 46px;
    margin-right: 600px;
    padding: 60px 0;
  
      
  
}
.infoTransitions{
  display: none;
}


.row {

  width: 200px;
  display: -webkit-inline-box;
}

#text_despre{

    margin-top: 10px;
    margin-left: 10px;

    margin-bottom: 10px;
    margin-right: 20px;
    width: 310px;
}
#criterii{
  margin-top: 0px;
    text-align: left;
    text-indent: 10px;
}
#post2{
  /* width:552; height: 349; */
  border:none;overflow:hidden;    height: 310px;
  width: 330px;margin-right: 80px;
}

#post1{
  /* width:552; height:340; */
   border:none;overflow:hidden;height: 380px;
   width: 330px;margin-right: 0px;
}

#post3{
  border:none;
  width: 330px; margin-top: 25px;
  overflow: hidden;
}




.col-sm-12{
  margin-left: 0px;
  width: 84px;
}

.col-sm-3{
  margin-left: 0px;

 display: -webkit-box;
 box-sizing: border-box;
 min-height: 1px;

 width: 96px;
    height: 40px;
}



  .text-center{
    width: 340px;
    height: 40px;
    margin-top: 20px;
    border-bottom-width: 0px;
    margin-left: 30px;
  }
 
.row { max-width: 1170%; width:100%; display: -webkit-inline-box; }
/* .row1{width:400%; display:inline-flex}*/
.row.full-width {  margin: 0 auto; max-width: 100%; width: 100%; }
.row.custom-width-90 {  margin: 0 auto; max-width: 90%; width: 90%; }
.row.full-width-padding {  margin: 0 auto; max-width: 98%; width: 98%; }
.row.no-gutter { margin:0 auto;} 
.row.no-gutter [class*='col-']:not(:first-child), .row.no-gutter [class*='col-']:not(:last-child) { padding-left: 0; padding-right: 0; }
/* .row.no-gutter-all { margin:0 auto;} */
.row.no-gutter-all [class*='col-'] { padding-left: 0; padding-right: 0; }
#mc_embed_signup { margin-top:15px;}
#mc_embed_signup input[type="email"] {  border:0; padding-top: 3px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 3px;
  width: 84px;  border-radius:5px;}
#mc_embed_signup input[type="submit"] { margin-top:5px; transition: all 250ms ease-out 0s; border-radius:5px; border:0; font-size: smaller; color:#fff; background:#cb6428;
  padding-top: 2px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 3px;
  width: 84px;
  height: 35px;}
#mc_embed_signup input[type="submit"]:hover { background:#808080;}

/* Social Buttons ---------------------- */
.social-linkss {margin:20px 0 0; padding:0;}
.social-linkss li { list-style: none;  display: inline-table;}
.social-linkss li a { color:#fff; font-size:13px; display: inline-block;  padding-top:7px;  text-align: center;  height: 34px;  width: 34px;  position: relative; z-index: 1; transition: color 0.15s; -moz-osx-font-smoothing: grayscale; border-radius:50%;}
.social-linkss li a::before { content: '';  position: absolute;  top: 0;  left: 0;  width: 100%;  height: 100%;  border-radius: inherit;  z-index: -1;  box-shadow: inset 0 0 0 35px #808080;  transform: scale3d(0.7, 0.7, 1);  transition: box-shadow 0.15s, transform 0.15s;}
.social-linkss li a:hover::before {  transform: scale3d(1.0, 1.0, 1); box-shadow: inset 0 0 0 35px  #009DE0;}

.copyright {padding-top: 0px; background:#525151;    height: 67px;
  }
.copyright p {font-size:14px; color:#ccc; margin-bottom:0;    height: 50px;
  margin-top: 0px;}

/* Back to Top Button  ----------------------- */
#back-to-top { color:#fff; position:fixed; bottom:20px; right:20px; z-index:99; display:none; text-align: center; border-radius: 50%; -moz-border-radius: 50%;  -webkit-border-radius:  50%; -o-border-radius:  50%;    z-index: 10000;    height: 59px;    width: 59px;    background-color:#7f65f0;    background-repeat: no-repeat;    background-position: center;  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);  transform: scale3d(0.7, 0.7, 1);  transition: box-shadow 0.15s, transform 0.15s;}
#back-to-top i { padding-top:20px; font-size:17px; }
#back-to-top:hover { background:#e77a21;  transform: scale3d(1.0, 1.0, 1);}


#politica{        padding-top: 0px;
  height: 20px;
  padding-left: 10px;
  width: 80px;
}
.row:before{
  display: table;
}

img#footerImg {
  box-shadow: -1px 0px 17px gainsboro;
  border-radius: 20px 20px ;
  width: 90px;height: 40px;
  
}

html, body {
  width: 100%;
  overflow-x: hidden;
  background-color: rgba(252, 227, 198, 0.9);
}

button#pol{
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  
    background-color: rgb(105, 104, 104,0.7);
    cursor: pointer;
    border: hidden;
    height: 52px;
  
}


}





#curveUpColor path,#curveDownColor path{fill:#dc790e;stroke:#dc790e}.col-2 .column{width:49%}.text:first-child{text-align:right}.text:nth-child(2){text-align:left}.column{display:inline-block;vertical-align:top}.color{background:#dc790e}@media screen and (max-width: 54em){.column{display:block;width:100% !important}.text:first-child,.text:nth-child(2){text-align:center}.icon{margin:0 auto}.col-2 .column:first-child .icon{margin:0 auto 50px}.col-2 .column:nth-child(2) .icon{margin:50px auto 0}}

body {font-family: Arial, Helvetica, sans-serif;
    /* background-image: linear-gradient(to bottom, rgba(248, 248, 246, 0.8), rgba(255, 255, 255, 0.9)), url('../images/3.jpg'); */
    /* overflow-x: hidden; */
    background-position: center;
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-color: white;
}
* {box-sizing: border-box;}


html{
  box-sizing: border-box;
}
*,*:before, *:after{
  box-sizing:inherit;
}

input[type=text],[type=email], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type=button] {
  background-color: #546cbd;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top:40px;
  margin-left:10%;
}

input[type=button]:hover {
  background-color: #e6bc4a;
}
h2#cere_ofertaM{
  font-size:x-large;
  font-style: oblique;
  font-family:sans-serif;
  margin-top:100px;
text-indent: 150px;
height:5px;
}

#myclock{
  margin-left: 150px;;
}
.container_of {
  border-radius: 5px;
  background-color: #f2f2f2;
  /* padding: 20px; */
 padding-left:20%;
 padding-right:20%;
 padding-top:3%;
 padding-bottom: 8%;
 
  margin-right: 280px;
  /* margin-left:280px; */
  margin-bottom: 0%;
  width: 100%;
}

textarea#subject{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.error {
  color: red;
  font-size: 90%;
}


.modal1#popup {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  padding-top: 60px;
}
.modal-content {
  background-color: #fefefe;
  margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 50%; 
  height: 70%;/* Could be more or less, depending on screen size */
}
#close-button {
  float: right;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: lightgray;
}
#close-button:hover {
  background-color: darkgray;
}
.show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1.0);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}
.animate {
  animation: animatezoom 0.6s
}

p#populate{
  width:50px;
  height:60%;
  margin-left:30px;
  line-height: 2;
  line-break: strict;


}

input[type=button]#submit-email{
  background-color: #546cbd;
  color: white;
  padding: 7px 16px;
  border: none;
  border-radius: 6px;
  margin-left: 30px;
  cursor: pointer;
  margin-top: 0px;
}

input[type=button]#submit-email:hover {
  background-color: #e6bc4a;
}

a#prev {
  text-decoration: none;
  display: inline-block;
  padding: 8px 16px;
  margin-top: 120px;
}

a#prev:hover {
  background-color: #ddd;
  color: black;
}
.section-notifications, .section-thin {
  padding: 0;
}
.section {
  padding: 70px 0;
  position: relative;
  background: #fff;
}
.alert.alert-success {
  background-color: rgba(24,206,15,.8);
}
.alert {
  border: 0;
  border-radius: 0;
  color: #fff;
  padding-top: .9rem;
  padding-bottom: .9rem;
}
.fade {
  transition: opacity .15s linear;
}
.alert.alert-info {
  background-color: rgba(44,168,255,.8);
}
.alert.alert-warning {
  background-color: rgba(255,178,54,.8);
}
.alert.alert-danger {
  background-color: rgba(255,54,54,.8);
}

.previous {
  background-color: #f1f1f1;
  color: black;
}
/* #myclock {
  position: relative;
  top: 20%;
  left: 85%;
  transform: translateX(-50%) translateY(-50%);
  color: #3e2d55;
  font-size: 15px;
  font-family: Orbitron;
  letter-spacing: 2px;
 


} */
@media screen and (max-width: 520px){


input[type=text],[type=email], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type=button] {
  background-color: #546cbd;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top:40px;
  margin-left:40px;
}

input[type=button]:hover {
  background-color: #e6bc4a;
  margin-left:40px;
}

.container_of {
  border-radius: 5px;
  background-color: #f2f2f2;
  /* padding: 20px; */
  padding: 50px 60px 60px 50px;
  margin-right: 10px;
  margin-left:10px;
  /* margin-bottom: 150px; */
}

textarea#subject{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.error {
  color: red;
  font-size: 90%;
}
  }



/* Popup container */
.modal{
  position: relative;
  display: none;
  cursor: pointer;
}

/* The actual popup (appears on top) */
.modal .popup_content {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
 .modal .popup_content::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}


/* Toggle this class when clicking on the popup container (hide and show the popup) */
.modal .show {
  visibility: visible;
  animation: fadeIn 1s;
  display: inline-block;
}

/* Add animation (fade in the popup) */

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}


@media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) {



/* #myclock{
  margin-left: 80px;
  margin-top:50px;
} */

      body {font-family: Arial, Helvetica, sans-serif;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.9)), url(/static/media/3.9125b85d.jpg);
        
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;}
    * {box-sizing: border-box;}
    
    
    html{
      box-sizing: border-box;
    }
    *,*:before, *:after{
      box-sizing:inherit;
    }
  
    input[type=text],[type=email], select, textarea {
      width: 100%;
      padding: 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
      margin-top: 6px;
      margin-bottom: 16px;
      resize: vertical;
    }
    
    input[type=button] {
      background-color: #546cbd;
      color: white;
      padding: 12px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-top:40px;
      margin-left:280px;
    }
    
    input[type=button]:hover {
      background-color: #e6bc4a;
    }
    h2#cere_ofertaM{
      font-size:large;
      font-family: 'IBM Plex Sans';
      margin-top:100px;
    text-indent: 60px;
    height:5px;
    }
    .container_of {
      border-radius: 5px;
      background-color: #f2f2f2;
      /* padding: 20px; */
      padding: 50px 60px 60px 50px;
      margin-right: 280px;
      /* margin-left:280px; */
      margin-bottom: 150px;
    }
    
    textarea#subject{
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }
    .error {
      color: red;
      font-size: 90%;
    }
    
    
    .modal1#popup {
      display: none; /* Hidden by default */
      position: fixed; /* Stay in place */
      z-index: 1; /* Sit on top */
      left: 0;
      top: 0;
      width: 100%; /* Full width */
      height: 100%; /* Full height */
      overflow: auto; /* Enable scroll if needed */
      background-color: rgb(0,0,0); /* Fallback color */
      background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
      padding-top: 60px;
    }
    .modal-content {
      background-color: #fefefe;
      margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
      border: 1px solid #888;
      /* width: 257px;  */
      height: 70%;/* Could be more or less, depending on screen size */
      margin-top: 100px;
    width: 302px;
    }
    #close-button {
      float: right;
      width: 1.5rem;
      line-height: 1.5rem;
      text-align: center;
      cursor: pointer;
      border-radius: 0.25rem;
      background-color: lightgray;
    }
    #close-button:hover {
      background-color: darkgray;
    }
    .show-modal {
      opacity: 1;
      visibility: visible;
      transform: scale(1.0);
      transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
    }
    .animate {
      animation: animatezoom 0.6s
    }
    
    p#populate{
      width:50px;
      height:60%;
      margin-left:30px;
      line-height: 2;
      line-break: strict;
    
    
    }
    
    input[type=button]#submit-email{
      background-color: #546cbd;
      color: white;
      padding: 5px 16px;
      border: none;
      border-radius: 6px;
      /* margin-left: 30px; */
      cursor: pointer;
      margin-top: 0px;
      margin-left: 5px;
    }
    
    input[type=button]#submit-email:hover {
      background-color: #e6bc4a;
      margin-left: 25px;
    }
    
    a#prev {
      text-decoration: none;
      display: inline-block;
      padding: 8px 16px;
    }
    
    a#prev:hover {
      background-color: #ddd;
      color: black;
    }
    .section-notifications, .section-thin {
      padding: 0;
    }
    .section {
      padding: 70px 0;
      position: relative;
      background: #fff;
    }
    .alert.alert-success {
      background-color: rgba(24,206,15,.8);
    }
    .alert {
      border: 0;
      border-radius: 0;
      color: #fff;
      padding-top: .9rem;
      padding-bottom: .9rem;
    }
    .fade {
      transition: opacity .15s linear;
    }
    .alert.alert-info {
      background-color: rgba(44,168,255,.8);
    }
    .alert.alert-warning {
      background-color: rgba(255,178,54,.8);
    }
    .alert.alert-danger {
      background-color: rgba(255,54,54,.8);
    }
    
    .previous {
      background-color: #f1f1f1;
      color: black;
    }
    /* #myclock {
      position: relative;
      top: 20%;
      left: 85%;
      transform: translateX(-50%) translateY(-50%);
      color: #3e2d55;
      font-size: 15px;
      font-family: Orbitron;
      letter-spacing: 2px;
     
    
    
    } */
      /* Popup container */
      .modal{
        position: relative;
        display: none;
        cursor: pointer;
      }
      
      /* The actual popup (appears on top) */
      .modal .popup_content {
        visibility: hidden;
        width: 160px;
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 8px 0;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -80px;
      }
      
      /* Popup arrow */
       .modal .popup_content::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
      }
      
      
      /* Toggle this class when clicking on the popup container (hide and show the popup) */
      .modal .show {
        visibility: visible;
        animation: fadeIn 1s;
        display: inline-block;
      }
      
      /* Add animation (fade in the popup) */
      
      @keyframes fadeIn {
        from {opacity: 0;}
        to {opacity:1 ;}
      }
  
  
    } 

    @media screen and (max-width: 520px){
   
    
    input[type=text],[type=email], select, textarea {
       width: 100%; 
      padding: 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
      margin-top: 6px;
      margin-bottom: 16px;
      resize: vertical;
      /* width: 251px; */
      height: 70%;
     
   
  
    }
    
    input[type=button] {
      background-color: #546cbd;
      color: white;
      padding: 12px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-top:40px;
      /* margin-left:40px; */
      margin-left: 0px;
      padding-bottom: 25px;
    }
    
    input[type=button]:hover {
      background-color: #e6bc4a;
      margin-left:40px;
    }
    
    .container {
      border-radius: 5px;
      background-color: #f2f2f2;
      /* padding: 20px; */
      padding: 50px 60px 60px 50px;
      margin-right: 10px;
      margin-left:10px;
      margin-bottom: 150px;
    }
    
    textarea#subject{
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }
    .error {
      color: red;
      font-size: 90%;
    }
      }
    
    
    



  





section.footer {background:#666; padding:60px 0; overflow:visible;padding-top: 0px;    margin-top: 0px;       padding-left: 6%;
    padding-right: 6%; }
.footer-elements { position: relative;
  margin-top: 0px;}
.footer-element {  background:rgb(153, 153, 219);  text-align:center; box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);    

    width: 100%;
  
  }
.footer-element span.el{ font-size:45px;color:#fff; margin-bottom:15px; }
.footer-element p { font-size:14px; color:#fff;margin-bottom:0;width: 100%;}
.footer-element p span { display: block;}
.footer-element p a { color:#fff;}
.footer-widgets { margin-top: 42px;}
.footer-widget { padding-right:25px;margin-left: 60px; display:table-footer-group; margin-right:-240px; }
.footer-widgets .small-logo { margin:10px 40px 15px;}
.footer-widgets p { font-size:15px; color:#ccc;margin-bottom:0;width: 100%;}
.footer-widgets h5 { font-size:18px; color:#fff;margin:12px 0 20px; padding:0;}
.footer-widgets ul.posts { margin:0; padding:0; list-style: none;  text-indent: 0px; width: 350px;}
.footer-widgets ul.posts li {padding:9px 0; border-bottom:1px solid #747474; font-size:15px;}
.footer-widgets ul.posts li:first-child {padding-top:0; }
.footer-widgets ul.posts li:last-child {border-bottom:0; }
.footer-widgets ul.posts li a {color:#ccc; transition: all 250ms ease-out 0s;}
.footer-widgets ul.posts li a:hover {color:#fff;}
.footer-widgets ul.posts li span {display:block;font-size:12px; margin-top:3px;color:#B9B9B8;}
.row { 
  /* max-width: 1170%; */
 
  width:100%;
  display: inline-flex;
  position: relative;
  /* margin-right:50px;
  margin-left: 0px; */
}


/* .row1{width:400%; display:inline-flex}
.row.full-width {  margin: 0 auto; max-width: 100%; width: 100%; }
.row.custom-width-90 {  margin: 0 auto; max-width: 90%; width: 90%; }
.row.full-width-padding {  margin: 0 auto; max-width: 98%; width: 98%; }
.row.no-gutter { margin:0 auto;} */
.row.no-gutter [class*='col-']:not(:first-child), .row.no-gutter [class*='col-']:not(:last-child) { padding-left: 0; padding-right: 0; }
/* .row.no-gutter-all { margin:0 auto;} */
.row.no-gutter-all [class*='col-'] { padding-left: 0; }
#mc_embed_signup { margin-top:15px;     height: 40px;
   border-radius:5px;}
#mc_embed_signup input[type="email"] { border-radius:5px; width:100%; padding:5px 15px; border:0;    width: 320px; margin-left: 0px;}
#mc_embed_signup input[type="submit"] { margin-top:1px; transition: all 250ms ease-out 0s; border-radius:5px;     width: 100%; padding:10px 15px; border:0;  color:#fff; background:#cb6428;}
#mc_embed_signup input[type="submit"]:hover { background:#808080;}

/* Social Buttons ---------------------- */
.social-linkss {margin:20px 0 0; padding:0;}
.social-linkss li { list-style: none;  display: inline-table;}
.social-linkss li a { color:#fff; font-size:13px; display: inline-block;  padding-top:7px;  text-align: center;  height: 34px;  width: 34px;  position: relative; z-index: 1; transition: color 0.15s; -moz-osx-font-smoothing: grayscale; border-radius:50%;}
.social-linkss li a::before { content: '';  position: absolute;  top: 0;  left: 0;  width: 100%;  height: 100%;  border-radius: inherit;  z-index: -1;  box-shadow: inset 0 0 0 35px #808080;  transform: scale3d(0.7, 0.7, 1);  transition: box-shadow 0.15s, transform 0.15s;}
.social-linkss li a:hover::before {  transform: scale3d(1.0, 1.0, 1); box-shadow: inset 0 0 0 35px  #009DE0;}

.copyright {padding:15px 0; background:#525151;}
.copyright p {font-size:14px; color:#ccc; margin-bottom:0;}

.col-sm-3  {
    display:-webkit-box;
    box-sizing: border-box;
    padding-left: 30px;  
    min-height: 1px;
    padding-right: 15px;
    height: auto;
    
  }

  .widget-row{
      display: inline-flex;
      width: 100%;

  }

  .widget-col-sm-3{
      width: 100%;
      height: auto;
      
  }

  button#pol {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    background-color: rgb(105, 104, 104,0.7);
    cursor: pointer;
    border: hidden;
    height: auto;
}

.copy-col-sm-12{
    width: 100%;
    text-align: center;
}


  @media (max-width: 1295px){
     .col-sm-3{
         width: 100%;
         height: auto;
     }

      .row{
          display: inline-block;
      }

      .col-sm-12{
          width: 100%;
      }

      .row.no-gutter{
          width: 100%;
      }
      .footer-elements{
          width: 100%;
      }
      .widget-row{
          display: grid;
          justify-content: center;
      }
      
  }

  .footer-element{
      position: relative;
      height: auto;
  }
.App {
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}




 
  #domeniu{
    /* padding-left: 3%; */
    padding-right: 2%;
    font-size: 3em;
    
  }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.home,
.services,
.products,
.contact-us,
.sign-up,
.marketing,
.consulting {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.home {
  /* background-image: url('./images/img-1.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.services {
  /* background-image: url('./images/img-2.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  /* background-image: url('./images/img-3.jpg'); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contact-us {
  /* background-image: url('./images/img-4.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  /* background-image: url('./images/img-7.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.marketing {
  /* background-image: url('./images/img-6.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.consulting {
  /* background-image: url('./images/img-5.jpg'); */
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.card_euro{
  max-height: 950px;
  height: 100%;
  background-color: #fff;
  width: 100%;
  /* // height: '450px', */
  position: relative;
  display: grid;
  /* //gridTemplateColumns: '1fr 1fr', */
  place-items: center;
  line-height: 1.5;
  box-shadow: '0 20px 30px rgba(0, 0, 0, 0.185)';
  /* margin-top: 5%; */
  margin-top: 100px;
  max-width: 700px;


}

.card-body{
  display: inline-flex;
}
.content-wrap-card{
  width: 100%;
  margin: 5% 5% 5% 5%;
  justify-content: center;
  position: relative;
  display: flex;
}

.newsletter-overlay{
  margin-bottom: 5%;
}
@media(max-width:770px){
   .card-body {
    display: inline-block;

  } 
  
}



