

@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);
//Change color1 to change entire color scheme

$color1:rgb(241, 139, 28);
$cloudColor:#ecf0f1;
// * Curves */
#curveUpColor path,
#curveDownColor path {
	fill: darken($color1, 7%);
	stroke: darken($color1, 7%);
}


.col-2 .column {
	width: 49%;
}



.text:first-child {
	text-align: right;
}

.text:nth-child(2) {
	text-align: left;
}

.column {
	display: inline-block;
	vertical-align: top;
}


.color {
	background: darken($color1, 7%);
}


/* Media Queries for the layout */
@media screen and (max-width: 68em) {
	section {
		// font-size: 80%;
	}
}

@media screen and (max-width: 54em) {
	.column {
		display: block;
		width: 100% !important;
	}

	.text:first-child,
	.text:nth-child(2) {
		text-align: center;
	}

	.icon {
		margin: 0 auto;
	}

	.col-2 .column:first-child .icon {
		margin: 0 auto 50px;
	}

	.col-2 .column:nth-child(2) .icon {
		margin: 50px auto 0;
	}
}