section.footer {background:#666; padding:60px 0; overflow:visible;padding-top: 0px;    margin-top: 0px;       padding-left: 6%;
    padding-right: 6%; }
.footer-elements { position: relative;
  margin-top: 0px;}
.footer-element {  background:rgb(153, 153, 219);  text-align:center; box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);    

    width: 100%;
  
  }
.footer-element span.el{ font-size:45px;color:#fff; margin-bottom:15px; }
.footer-element p { font-size:14px; color:#fff;margin-bottom:0;width: 100%;}
.footer-element p span { display: block;}
.footer-element p a { color:#fff;}
.footer-widgets { margin-top: 42px;}
.footer-widget { padding-right:25px;margin-left: 60px; display:table-footer-group; margin-right:-240px; }
.footer-widgets .small-logo { margin:10px 40px 15px;}
.footer-widgets p { font-size:15px; color:#ccc;margin-bottom:0;width: 100%;}
.footer-widgets h5 { font-size:18px; color:#fff;margin:12px 0 20px; padding:0;}
.footer-widgets ul.posts { margin:0; padding:0; list-style: none;  text-indent: 0px; width: 350px;}
.footer-widgets ul.posts li {padding:9px 0; border-bottom:1px solid #747474; font-size:15px;}
.footer-widgets ul.posts li:first-child {padding-top:0; }
.footer-widgets ul.posts li:last-child {border-bottom:0; }
.footer-widgets ul.posts li a {color:#ccc; transition: all 250ms ease-out 0s;}
.footer-widgets ul.posts li a:hover {color:#fff;}
.footer-widgets ul.posts li span {display:block;font-size:12px; margin-top:3px;color:#B9B9B8;}
.row { 
  /* max-width: 1170%; */
 
  width:100%;
  display: inline-flex;
  position: relative;
  /* margin-right:50px;
  margin-left: 0px; */
}


/* .row1{width:400%; display:inline-flex}
.row.full-width {  margin: 0 auto; max-width: 100%; width: 100%; }
.row.custom-width-90 {  margin: 0 auto; max-width: 90%; width: 90%; }
.row.full-width-padding {  margin: 0 auto; max-width: 98%; width: 98%; }
.row.no-gutter { margin:0 auto;} */
.row.no-gutter [class*='col-']:not(:first-child), .row.no-gutter [class*='col-']:not(:last-child) { padding-left: 0; padding-right: 0; }
/* .row.no-gutter-all { margin:0 auto;} */
.row.no-gutter-all [class*='col-'] { padding-left: 0; }
#mc_embed_signup { margin-top:15px;     height: 40px;
   border-radius:5px;}
#mc_embed_signup input[type="email"] { border-radius:5px; width:100%; padding:5px 15px; border:0;    width: 320px; margin-left: 0px;}
#mc_embed_signup input[type="submit"] { margin-top:1px; transition: all 250ms ease-out 0s; border-radius:5px;     width: 100%; padding:10px 15px; border:0;  color:#fff; background:#cb6428;}
#mc_embed_signup input[type="submit"]:hover { background:#808080;}

/* Social Buttons ---------------------- */
.social-linkss {margin:20px 0 0; padding:0;}
.social-linkss li { list-style: none;  display: inline-table;}
.social-linkss li a { color:#fff; font-size:13px; display: inline-block;  padding-top:7px;  text-align: center;  height: 34px;  width: 34px;  position: relative; z-index: 1; -webkit-transition: color 0.15s; transition: color 0.15s; -moz-osx-font-smoothing: grayscale; border-radius:50%;}
.social-linkss li a::before { content: '';  position: absolute;  top: 0;  left: 0;  width: 100%;  height: 100%;  border-radius: inherit;  z-index: -1;  box-shadow: inset 0 0 0 35px #808080;  -webkit-transform: scale3d(0.7, 0.7, 1);  transform: scale3d(0.7, 0.7, 1);  -webkit-transition: box-shadow 0.15s, -webkit-transform 0.15;  transition: box-shadow 0.15s, transform 0.15s;}
.social-linkss li a:hover::before {  -webkit-transform: scale3d(1.0, 1.0, 1);  transform: scale3d(1.0, 1.0, 1); box-shadow: inset 0 0 0 35px  #009DE0;}

.copyright {padding:15px 0; background:#525151;}
.copyright p {font-size:14px; color:#ccc; margin-bottom:0;}

.col-sm-3  {
    display:-webkit-box;
    box-sizing: border-box;
    padding-left: 30px;  
    min-height: 1px;
    padding-right: 15px;
    height: auto;
    
  }

  .widget-row{
      display: inline-flex;
      width: 100%;

  }

  .widget-col-sm-3{
      width: 100%;
      height: auto;
      
  }

  button#pol {
    text-decoration-line: underline;
    background-color: rgb(105, 104, 104,0.7);
    cursor: pointer;
    border: hidden;
    height: auto;
}

.copy-col-sm-12{
    width: 100%;
    text-align: center;
}


  @media (max-width: 1295px){
     .col-sm-3{
         width: 100%;
         height: auto;
     }

      .row{
          display: inline-block;
      }

      .col-sm-12{
          width: 100%;
      }

      .row.no-gutter{
          width: 100%;
      }
      .footer-elements{
          width: 100%;
      }
      .widget-row{
          display: grid;
          justify-content: center;
      }
      
  }

  .footer-element{
      position: relative;
      height: auto;
  }