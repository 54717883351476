.html{
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.body{
    color: #6B6B6B;
    background-color: #F2F2F2;
    word-wrap: break-word;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.7;
    font-size: 14px;
    width: 100%;
} 

html{
  box-sizing: border-box;
}
:before,:after{
  box-sizing:inherit;
}
/*Global styles
-------------------------------------------*/
body{
  color:#343434;
  margin:0;
  padding:0;
  font-family:'Open Sans', sans-serif;
  font-size: 15px;
  line-height: 1.5;
}
#includedContent:first-child{
    float: left!important;
    display: block;
    border-top: 1px solid #dadada;;

}
img#profil{
    opacity: 0.8;

}

#includedContent{
  padding-top: 80px;
    display: table;
    content: " ";
    background-color: #8080801a;
    background: linear-gradient(to bottom, rgba(253, 253, 238, 0.8), rgba(186, 186, 221, 0.9)), url('../images/3.jpg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* float: left!important; */
  
  z-index: 99;
  bottom: 0px;
  /* right:30px;  */
  position: relative;
  display: inline-flex;
  width: 100%;
}


#despre-audit{
    /* float:center; */
   
    background-color: rgb(218, 165, 183);
    border-top: 1px solid #dadada;

 padding: 45px 50px;
    background-color: #fff;
    border: 1px solid #dadada;
   
    margin-left:30px;
    margin-bottom: 400px;

    
  
  
}

#custom-id-yes{
  margin-top:20%;
}

#custom-id-yess{
  margin-top:20%;
}
#custom-id-yesss{
   margin-top:20%;
}
div {
    display: block;
}

.tagcloud a {
    padding: 6px 7px;
    margin-right: 0;
    margin-bottom: 4px;
    line-height: 100%;
    display: inline-block;
    color: #FFF !important;
    background-color: #DADADA;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    font-size: 12px ;
}
.entry-meta a {
    font-size: 13px;
    color: #666666;
}
footer.entry-meta {
    background: #f7fff7;
    color: #F7FFF7;
    /* float: center; */
}

 .col-md-12,   .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
    position: relative;
    /* min-height: 1px;
    padding-right: 15px;
       padding-left: 0px; */
       width: 100%;
}
#secondary .widget > h3 {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 25px;
    margin-top: 0;
    color: #636467;
}
#secondary .widget {
    margin-bottom: 10px;
    color: #C1C0C0;
    overflow: hidden;
}
.well {
    background-color: #fff;
    padding: 30px;
    border: 1px solid #DADADA;
    overflow: hidden;
    clear: both;
    margin-top: 75px;
    
}

.well_audit {
  background-color: #fff;
  padding: 30px;
  border: 1px solid #DADADA;
  overflow: hidden;
  clear: both;
  /* margin-top: 75px;
   */
   margin-top:0px;
  margin-bottom: 0px;
  /* width: 682px; */
  /* margin-left: 30px; */
}




@media (min-width: 992px){
.col-md-4 {
    width: 33.33333333%;
}
}
@media (min-width: 992px){
.col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 {
    float: left;
}
}
div.wpcf7 .screen-reader-response {
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    margin: 0;
    padding: 0;
    border: 0;
}

section.footer-contab {background:#666; padding:60px 0; overflow:visible; padding-top:0px; margin-top: 1800px;}
.footer-elements-contab {margin-top:130px; margin-left:190px;margin-right:400px; position: relative;;}
.footer-element { width: 200px; background:rgb(153, 153, 219); padding: 8px 15px 15px; text-align:center; box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02); }
.footer-element span.typcn { font-size:45px;color:#fff; margin-bottom:15px; }
.footer-element p { font-size:14px; color:#fff;margin-bottom:0;}
.footer-element p span { display: block;}
.footer-element p a { color:#fff;}
.footer-widgets { margin-top: 42px;}
.footer-widget-contab { padding-right:45px;margin-left: 200px; display:table-footer-group; margin-right:30px; }
.footer-widgets .small-logo { margin:10px 40px 15px;}
.footer-widgets p { font-size:15px; color:#ccc;margin-bottom:0;}
.footer-widgets h5 { font-size:18px; color:#fff;margin:12px 0 20px; padding:0;}
.footer-widgets ul.posts { margin:0; padding:0; list-style: none; width: 550px;}
.footer-widgets ul.posts li {padding:5px 0; border-bottom:1px solid #747474; font-size:15px;}
.footer-widgets ul.posts li:first-child {padding-top:0; }
.footer-widgets ul.posts li:last-child {border-bottom:0; }
.footer-widgets ul.posts li a {color:#ccc; transition: all 250ms ease-out 0s;}
.footer-widgets ul.posts li a:hover {color:#fff;}
.footer-widgets ul.posts li span {display:block;font-size:12px; margin-top:3px;color:#B9B9B8;}
/* .row { max-width: 1170%; width:100%; display: -webkit-inline-box; margin-left:400px; } */

.row.no-gutter-all [class*='col-'] { padding-left: 0; padding-right: 0; }
#mc_embed_signup { margin-top:15px;}
#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
#mc_embed_signup input[type="email"] { border-radius:5px; width:100%; padding:10px 15px; border:0; }
#mc_embed_signup input[type="submitted"] { margin-top:5px; transition: all 250ms ease-out 0s; border-radius:5px; width:100%; padding:10px 15px; border:0; color:#fff; background:#cb6428;}
#mc_embed_signup input[type="submitted"]:hover { background:#808080;}

/* Social Buttons ---------------------- */
.social-links {margin:20px 0 0; padding:0;}
.social-links li { list-style: none;  display: inline-table;}
.social-links li a { color:#fff; font-size:13px; display: inline-block;  padding-top:7px;  text-align: center;  height: 34px;  width: 34px;  position: relative; z-index: 1; -webkit-transition: color 0.15s; transition: color 0.15s; -moz-osx-font-smoothing: grayscale; border-radius:50%;}
.social-links li a::before { content: '';  position: absolute;  top: 0;  left: 0;  width: 100%;  height: 100%;  border-radius: inherit;  z-index: -1;  box-shadow: inset 0 0 0 35px #808080;  -webkit-transform: scale3d(0.7, 0.7, 1);  transform: scale3d(0.7, 0.7, 1);  -webkit-transition: box-shadow 0.15s, -webkit-transform 0.15;  transition: box-shadow 0.15s, transform 0.15s;}
.social-links li a:hover::before {  -webkit-transform: scale3d(1.0, 1.0, 1);  transform: scale3d(1.0, 1.0, 1); box-shadow: inset 0 0 0 35px  #009DE0;}

.copyright {padding:15px 0; background:#525151;}
.copyright p {font-size:14px; color:#ccc; margin-bottom:0;}

/* Back to Top Button  ----------------------- */
#back-to-top { color:#fff; position:fixed; bottom:20px; right:20px; z-index:99; display:none; text-align: center; border-radius: 50%; -moz-border-radius: 50%;  -webkit-border-radius:  50%; -o-border-radius:  50%;    z-index: 10000;    height: 59px;    width: 59px;    background-color:#3190E7;    background-repeat: no-repeat;    background-position: center;  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24); -webkit-transform: scale3d(0.7, 0.7, 1);  transform: scale3d(0.7, 0.7, 1);  -webkit-transition: box-shadow 0.15s, -webkit-transform 0.15;  transition: box-shadow 0.15s, transform 0.15s;}
#back-to-top i { padding-top:20px; font-size:17px; }
#back-to-top:hover { background:#e77a21;  -webkit-transform: scale3d(1.0, 1.0, 1);  transform: scale3d(1.0, 1.0, 1);}

 .col-sm-3  {
  display:-webkit-box;
  box-sizing: border-box;
  
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.row:before{
  display: table;
}

img#footerImg {
  box-shadow: -1px 0px 17px gainsboro;
  border-radius: 20px 20px ;
}


button#poli{
  text-decoration-line: underline;
  
    background-color: rgb(105, 104, 104,0.7);
    cursor: pointer;
    border: hidden;
  
}

input[type=text],[type=email], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
  }
  
  input#submitted[type=button] {
    background-color: #546cbd;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top:40px;
    margin-left:20px;
  }
  
  input[type=button]:hover {
    background-color: #e6bc4a;
  }
  

  textarea#subject{
      font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  .error {
    color: red;
    font-size: 90%;
  }
  
  
  .modal1#popup {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    padding-top: 60px;
  }
  .modal-content {
    background-color: #fefefe;
    margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
    border: 1px solid #888;
    width: 50%; 
    height: 70%;/* Could be more or less, depending on screen size */
  }
  #close-button {
    float: right;
    width: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    cursor: pointer;
    border-radius: 0.25rem;
    background-color: lightgray;
  }
  #close-button:hover {
    background-color: darkgray;
  }
  .show-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
  }
  .animate {
    -webkit-animation: animatezoom 0.6s;
    animation: animatezoom 0.6s
  }
  
  p#populate{
    width:50px;
    height:60%;
    margin-left:30px;
    line-height: 2;
    line-break: strict;
  
  
  }
  
  input[type=button]#submit-email{
    background-color: #546cbd;
    color: white;
    padding: 7px 16px;
    border: none;
    border-radius: 6px;
    margin-left: 30px;
    cursor: pointer;
    margin-top: 0px;
  }
  
  input[type=button]#submit-email:hover {
    background-color: #e6bc4a;
  }
  
  a#prev {
    text-decoration: none;
    display: inline-block;
    padding: 8px 16px;
  }
  
  a#prev:hover {
    background-color: #ddd;
    color: black;
  }
  
  .previous {
    background-color: #f1f1f1;
    color: black;
  }
  
  @media screen and (max-width: 520px){
   
  
  input[type=text],[type=email], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
  }
  
  input[type=button] {
    background-color: #546cbd;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top:40px;
    margin-left:40px;
  }
  
  input[type=button]:hover {
    background-color: #e6bc4a;
    margin-left:40px;
  }
  

  
  textarea#subject{
      font-family: Verdana, Tahoma, sans-serif;
  }
  .error {
    color: red;
    font-size: 90%;
  }
    }
  
  
  
  /* Popup container */
  .modal{
    position: relative;
    display: none;
    cursor: pointer;
  }
  
  /* The actual popup (appears on top) */
  .modal .popup_content {
    visibility: hidden;
    width: 160px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -80px;
  }
  
  /* Popup arrow */
   .modal .popup_content::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  /* Toggle this class when clicking on the popup container (hide and show the popup) */
  .modal .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
    display: inline-block;
  }
  
  /* Add animation (fade in the popup) */
  @-webkit-keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  
  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity:1 ;}
  }

  /*==============================================================================================*/

  
/* body {font-family: Arial, Helvetica, sans-serif; width: 100%; height:100%;} */
 
/* Full-width input fields */
input[type=text], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}


/* Extra styles for the cancel button */
.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #f44336;
}

/* Center the image and position the close button */
.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
  position: relative;
}

img#avatar {
  width: 40%;
 position: relative;
}

/* .container2 {
  padding: 16px;
  margin-top: 60px;
} */

span.psw {
  float: right;
  padding-top: 16px;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  padding-top: 60px;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button (x) */
.close {
  position: absolute;
  right: 25px;
  top: 0;
  color: #000;
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: red;
  cursor: pointer;
}


.animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s;
}

@-webkit-keyframes animatezoom {
  from {-webkit-transform: scale(0)} 
  to {-webkit-transform: scale(1)}
}
  
@keyframes animatezoom {
  from {transform: scale(0)} 
  to {transform: scale(1)}
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
     display: block;
     float: none;
  }
  .cancelbtn {
     width: 100%;
  }
}


 
 p#logs{
  background-color: rgba(252, 227, 198, 0.9);
  color: black;
  margin-bottom: -45px;
}
 p#calls {
  margin-left: 1050px;
   background-color: rgba(248, 227, 220, 0.9);
}

a#calling{
  color: black;
  font-size: 80%;


}
#mess p{
  font-family: 'Alata';font-size: 10px;
  color: black;
}

@media screen and (max-width: 520px){
img#profil {
    width: 58px;
    margin-right: 6px;
}
}
img#profil a img {
  width: auto;
  max-width: 88px;
 
}

img.call{
  width: 20px;
  max-width: 88px;
  margin-left: 1090px;

  position: fixed;
  left: 0;
  top: 0;
  border-radius: 10px;
}


img#profil{

  width: 180px;
  position: fixed;
   left:0;
   top:0;
   border-radius: 10px;
   
  
}



#btn-det1,#btn-det2,#btn-det3{
  background: #f18339;
  color: #fff;
  font-weight: 700;
  letter-spacing: .06em;
  text-transform: uppercase;
  font-size: 13px;
  border-radius: 2px;
  padding: 15px 30px;
  transition: background-color 1s ease 0s;
  cursor: pointer;
  border-radius: 20px;
  border: none;
}


a{
  color: #f3e6a5;
 
}

a:hover{
  text-decoration: none;
}

h1{
  font-size: 100px;
  line-height: 1px;
}
h2#titlu_contabilitate{
 
  /* font-size: 34px; */
  /* text-indent: px; */
  padding-right: 10px;
/*  line-height: 1;*/
font-size: 26px;;
/* margin-left: 32px; */
font-family:'IBM Plex Sans';
margin-top: 5%;
padding-bottom: 2%;
}



.content-wrap{
 max-width: 800px;
 width:85%;
  margin: 0 auto;
  padding: 60px 0;
 
}
h3{
  margin-bottom: 0;
}

.item-details h3 + p{
  font-style: italic;
}
/*advanced selector*/


.item-details h3 ~ p {
  margin: 0;
}

.divider > section{
  border-bottom: 1px none #949393;
  padding: 25px 0;
}

.divider > section:last-of-type {
   border-bottom: none;
}



/*Profile section
------------------------------------------*/
.header  {

  width: 100%;
  background-color:  rgba(223, 223, 253, 0.7);
}

.fadein { position:relative; width:500px; height:332px; }


.item-1
{
	position: relative;
  display: block;
  top: 1em;
   
  
  width: 60%;
  
  font-size: 2em;

	animation-duration: 20s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}

.item-2{
  position: relative;
  display: block;
  top: -7em;
  /* float:center; */

  width: 60%;
  
  font-size: 2em;

	animation-duration: 20s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}

.item-3{
  position: relative;
  display: block;
	top: -13em;
  
  width: 60%;
  
  
  font-size: 2em;

	animation-duration: 20s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}
.item-1{
	animation-name: anim-1;
}

.item-2{
	animation-name: anim-2;
}

.item-3{
	animation-name: anim-3;
}

@keyframes anim-1 {
	0%, 8.3% { left: -100%; opacity: 0; }
  8.3%,25% { left: 20%; opacity: 1; }
  33.33%, 100% { left: 110%; opacity: 0; }
}

@keyframes anim-2 {
	0%, 33.33% { left: -100%; opacity: 0; }
  41.63%, 58.29% { left: 20%; opacity: 1; }
  66.66%, 100% { left: 110%; opacity: 0; }
}

@keyframes anim-3 {
	0%, 66.66% { left: -100%; opacity: 0; }
  74.96%, 91.62% { left: 20%; opacity: 1; }
  100% { left: 110%; opacity: 0; }
}

/*Projects
----------------------------*/
.projects{
  background:  #f8fcf8;
}

.projects a{
  color: #2F3061;

}

.projects .btn{
  color:#F7FFF7;
  background:  #2F3061;
  text-decoration: none;
  padding: 8px;
  border-radius: 4px;
  display: inline-block;

}

.projects .btn:hover{
  background: rgba(47, 48 , 97, 80%);
}

.project-item{
  overflow: hidden;
  animation: fade-slide-in 0.3s ease-out forwards;

}

#myclock {
  position: relative;
  top: 20%;
  left: 85%;
  transform: translateX(-50%) translateY(-50%);
  color: #3e2d55;
  font-size: 15px;
  font-family: Orbitron;
  letter-spacing: 2px;
 


}
.project-item h3{
  margin-top: 0;
}
/*Work experience
-----------------------------*/


.work{
  background: #d2e4ec;
}




/* Education ------------------
*/
.despre{
  /* background-image: url(../images/joanna-kosinska-unsplash.jpg); */
  background-size: cover;
  background-position: top right;
  padding-bottom: 0px;
  background-color: white;


}

.social-links {margin:20px 0 0; padding:0;}
.social-links li { list-style: none;  display: inline-table;}
.social-links li a { color:#fff; font-size:13px; display: inline-block;  padding-top:7px;  text-align: center;  height: 34px;  width: 34px;  position: relative; z-index: 1; -webkit-transition: color 0.15s; transition: color 0.15s; -moz-osx-font-smoothing: grayscale; border-radius:50%;}
.social-links li a::before { content: '';  position: absolute;  top: 0;  left: 0;  width: 100%;  height: 100%;  border-radius: inherit;  z-index: -1;  box-shadow: inset 0 0 0 35px #808080;  -webkit-transform: scale3d(0.7, 0.7, 1);  transform: scale3d(0.7, 0.7, 1);  -webkit-transition: box-shadow 0.15s, -webkit-transform 0.15;  transition: box-shadow 0.15s, transform 0.15s;}
.social-links li a:hover::before {  -webkit-transform: scale3d(1.0, 1.0, 1);  transform: scale3d(1.0, 1.0, 1); box-shadow: inset 0 0 0 35px  #009DE0;}


.despre p{
  width:60%;
}




/* Fake image */
.fakeimg {
  width: 100%; 
  padding: 20px;

}

.card {
 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 800px;
  margin: 40px 30px;
  text-align: inherit;
  height: 100%;
  width:150%;
  margin-right: 0px;
  margin-bottom: 20px;

}

p#profi{
  border-top: gray 5px;
  width: fit-content;
  text-indent: 50px;

  

}
#text_despre{
  margin-top:10px;
  margin-left: 10px;
  margin-right:10px;
  margin-bottom: 10px;;
}

ul{
  margin-top:0px;
  text-align: left;
  /* text-indent: 150px; */
}
p#d_cor{
  width:fit-content;
  text-align: left;
  padding-bottom: 20px;
  text-indent: 50px;
  
  
}


.row {
  
width:600px;
}


.contact-list{
  list-style-type: none;
  padding:0;

}

.contact-list a{
  padding: 15px;
  display: inline-block;
}

/* Responsive
-----------------------*/

@media screen and (min-width:750px){
  header, p#logs{
    /* , footer */
      text-align: center;
      background-color:  rgba(255, 255, 255, 0.9);
  }
  .project-item img {
      float: left;
      margin-right: 20px;
  }
  .job-item{
      display: grid;
      grid-template-columns: 1fr 2fr;
      column-gap: 20px;
  }
  .contact-list{
      display: flex;
      justify-content: center;
  }
  p#logs{
    background-color: rgba(255, 255, 255, 0.9);
    color: black;
    margin-bottom: -45px;
  }
  p#calls{
    margin-left: 1050px;
    background-color:  rgba(255, 255, 255, 0.9);
  }
  
  a#calling{
    color: black;
    font-size: 80%;
  }

  #myclock {
    position: relative;
    top: 20%;
    left: 85%;
    transform: translateX(-50%) translateY(-50%);
    color: #3e2d55;
    font-size: 15px;
    font-family: Orbitron;
    letter-spacing: 2px;
   
  
  
  }
  h2#titlu_audit{
    text-indent:5px; 
    font-size: 27px;;
    /* margin-left: 32px; */
    font-family:'IBM Plex Sans';
  }
  h2#titlu_analiza{
    text-indent:5px; 
    font-size: 28px;;
    /* margin-left: 32px; */
    font-family:'IBM Plex Sans';
  }

  #para{
    text-indent: 25px;
     text-align: justify;
     line-height: 30px;
  }

  #paragraf_mare{
    margin-right: 30px;
     margin-left: 40px;}
  }

  #para2{
   text-indent: 20px;
    margin-bottom: 0px;
     line-height: 20px;
  }
  #para3{
    text-indent: 20px;
     margin-bottom: 30px;
      margin-top: 0px;
      line-height: 33px;
    
  }

  #includedContentAnaliza{   
   padding-top: 80px;
    display: table;
    content: " ";
    background-color: #8080801a;
    background: linear-gradient(to bottom, rgba(253, 253, 238, 0.8), rgba(186, 186, 221, 0.9)), url('../images/3.jpg');
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* float: left!important; */
    z-index: 99;
   width: 100%;
    position: relative;
    display: inline-flex;
}
#includedContentConta{   
  padding-top: 80px;
  display: table;
  content: " ";
  background-color: #8080801a;
  background: linear-gradient(to bottom, rgba(253, 253, 238, 0.8), rgba(186, 186, 221, 0.9)), url('../images/3.jpg');
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
  z-index: 99;
  right:0px;
  position: relative;
  display: inline-flex;
  overflow: hidden;
  width: 100%;
  
}



#includedContentCons{   
 padding-top: 80px;
  margin-bottom: 0px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: table;
  content: " ";
  background-color: #8080801a;
  background-image: linear-gradient(to bottom, rgba(253, 253, 238, 0.8), rgba(186, 186, 221, 0.9)), url('../images/3.jpg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
 
  z-index: 99;
 
  position: relative;
  display: inline-flex;
  width: 100%;
}

#includedContentSala{   
 
  display: table;
  content: " ";
  background-color: #8080801a;
  background-image: linear-gradient(to bottom, rgba(253, 253, 238, 0.8), rgba(186, 186, 221, 0.9)), url('../images/3.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 80px;
  z-index: 99;
  position: relative;
  display: inline-flex;
  width: 100%;
}

.well_sal {
  background-color: #fff;
  padding: 40px;
  border: 1px solid #DADADA;
  overflow: hidden;
  clear: both;
  /* margin-top: 0px; */
  /* margin-bottom: 400px; */
  margin-bottom: 0px;
  height:1680px;
 
  padding-top:60px;
}

.well_analiza {
  background-color: #fff;
  padding: 30px;
  border: 1px solid #DADADA;
  overflow: hidden;
  clear: both;
  margin-top: 0px;
  /* margin-bottom: 400px; */
  margin-bottom: 0px;
}
.well_consultanta {
  background-color: #fff;
  padding: 30px;
  border: 1px solid #DADADA;
  overflow: hidden;
  clear: both;
 
  /* margin-bottom: 400px; */
  margin-bottom: 0px;
}
h2#titlu_sal{
  text-indent:5px; 
  font-size: 28px;;
  /* margin-left: 32px; */
  font-family:'IBM Plex Sans';


}
#despre-analiza {
  border-top: 1px solid #dadada;
  padding: 50px 50px;
  background-color: #fff;
  border: 1px solid #dadada;
  /* margin-left: 30px; */
  margin-bottom: 0px;
  position: relative;
  margin-right: 10px;
  margin-left: 10px;
}


  #para_analiza1{
  text-indent: 60px;
  }
  #para_analiza2{
   text-indent: 60px;
   margin-bottom: 0px;
  }
  #para_analiza3{
    text-indent: 60px;
     margin-bottom: 0px;
      margin-top: 0px;
  }

  #lista_analiza{
   text-align: left;
   text-indent: 10px;
    line-height: 40px;
    padding-left: 5%;
    padding-right: 5%;
  }
  #lista_consult{
   text-align: left;
    text-indent: 40px;
     line-height: 40px;
  }
  #paragraf_mare{
    padding-left: 3%;
    padding-right: 17%;
  }

  #lista_sal{
    margin-top:20px;
    /* margin-bottom: 10px; */
    text-align: left;
    text-indent: 15px;
    line-height: 35px;
  }
  #para_cons1{
    text-indent: 60px;
  }
  #oferta_para{
    text-indent: 60px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  #consultanta_of{
    text-indent: 60px;
    margin-bottom: 0px;
     margin-top: 0px;
  }
  #despre-audit {
   
    border-top: 1px solid #dadada;
    padding: 0 auto 0 auto;
    background-color: #fff;
    border: 1px solid #dadada;
    margin-right:30px;
    margin-left: 30px;
    margin-bottom: 0px;
    position: relative;
  
}

.form-conta{
  max-width: 500px;
  width:100%;
}
#despre-sal {
  border-top: 1px solid #dadada;
    padding: 50px 50px;
    background-color: #fff;
    border: 1px solid #dadada;
    margin-right:30px;
    margin-left: 30px;
    margin-bottom: 0px;
    position: relative;
}
#despre-consultanta {
  border-top: 1px solid #dadada;
    padding: 50px 50px;
    background-color: #fff;
    border: 1px solid #dadada;
    
    /* margin-left: 30px; */
    margin-bottom: 0px;
    position: relative;

}
#para_sal1{
  text-indent: 60px;
}

#head_sal1{
  text-indent: 60px;
  margin-bottom: 15px;
  margin-top: 25px;
  font-size: large;
  text-decoration-line: underline;
}

.well {
  background-color: #fff;
  padding: 30px;
  border: 1px solid #DADADA;
  overflow: hidden;
  clear: both;
  margin-top: 0px;
  
}





@media screen and (max-width:749px){
  h1{
      font-size:75px;
      line-height: 0.9;
      margin-bottom: 20px;
  }
  h2#titlu_contabilitate{
      line-height: 1;

  }
  .contact-list a {
      padding: 5px;
  }

  .imgcontainer img#avatar {
    width: 40%;
    border-radius: 50%;

  }
  
  p#logs{
    text-align: center;
    background-color: white;
    color: black;
    margin-bottom: -45px;
  }
  p#calls{
    margin-left: 1050px;
    /* background-color: blanchedalmond; */
  }
  
  a#calling{
    color: black;
    font-size: 80%;
  }
  .footer-widgets ul.posts {
    margin: 0;
    padding: 0;
    list-style: none;
    text-indent: 0px;
    width: 390px;
  }
  
}

@media(max-width: 1075px){
  #includedContentConta{
    display: inline-block;
  }
  .form-conta{
    max-width: 100%;
  }
  #includedContent{
    display: inline-block;
  }
  #includedContentAnaliza{
    display: inline-block;
  }
}

.card-container{  
  display: block;


}

.titlee {
  color: grey;
  font-size: 18px;
  margin-left: 50px;
}
.col-sm-33 {
  display: -webkit-box;
  box-sizing: border-box;
  /* padding-left: 30px; */
  min-height: 1px;
  padding-right: 15px;
  width: 340px;
  /* padding-right: 0px; */
  /* width: 330px; */
  padding-left: 70px;
}
.col-sm-333 {
  display: -webkit-box;
  box-sizing: border-box;
  /* padding-left: 30px; */
  min-height: 1px;
  padding-right: 10px;
  width: 340px;
  /* padding-right: 0px; */
  /* width: 330px; */
  padding-left: 10px;
}
.jtitle{
  margin-left: 50px;
}
#myclock {
  position: relative;
  top: 20%;
  left: 85%;
  transform: translateX(-50%) translateY(-50%);
  color: #3e2d55;
  font-size: 15px;
  font-family: Orbitron;
  letter-spacing: 2px;
 


}

p#but {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

a#sign {
  text-decoration: none;
  font-size: 22px;
  color: black;
}

p#but:hover, a#sign:hover {
  opacity: 0.7;
}


/* ==================================
9 = Footer
==================================== */
section.footer-contab {background:#666; padding:60px 0; overflow:visible;padding-top: 0px;
  margin-top: 0px;padding-top: 0px;
  margin-top: 0px;}
.footer-elements-contab {margin-top:500px; margin-left:190px;margin-right:400px; position: relative;;}
.footer-element { width: 220px; background:rgb(153, 153, 219); padding: 8px 15px 15px; text-align:center; box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02); }
.footer-element span.el{ font-size:45px;color:#fff; margin-bottom:15px; }
.footer-element p { font-size:14px; color:#fff;margin-bottom:0;}
.footer-element p span { display: block;}
.footer-element p a { color:#fff;}
.footer-widgets { margin-top: 42px;}
.footer-widget-contab { padding-right:25px;margin-left: 80px; display:table-footer-group; margin-right:-240px;  width:400px;}
.footer-widgets .small-logo { margin:10px 40px 15px;}
.footer-widgets p { font-size:15px; color:#ccc;margin-bottom:0;}
.footer-widgets h5 { font-size:18px; color:#fff;margin:12px 0 20px; padding:0;}
.footer-widgets ul.posts { margin:0; padding:0; list-style: none; width: 550px;}
.footer-widgets ul.posts li {padding:5px 0; border-bottom:1px solid #747474; font-size:15px;}
.footer-widgets ul.posts li:first-child {padding-top:0; }
.footer-widgets ul.posts li:last-child {border-bottom:0; }
.footer-widgets ul.posts li a {color:#ccc; transition: all 250ms ease-out 0s;}
.footer-widgets ul.posts li a:hover {color:#fff;}
.footer-widgets ul.posts li span {display:block;font-size:12px; margin-top:3px;color:#B9B9B8;}
/* .row { max-width: 1170%; 
  width:100%; 
  display: -webkit-inline-box; 
  margin-left:70px; } */
/* .row1{width:400%; display:inline-flex}
.row.full-width {  margin: 0 auto; max-width: 100%; width: 100%; }
.row.custom-width-90 {  margin: 0 auto; max-width: 90%; width: 90%; }
.row.full-width-padding {  margin: 0 auto; max-width: 98%; width: 98%; }
.row.no-gutter { margin:0 auto;} */
.row.no-gutter [class*='col-']:not(:first-child), .row.no-gutter [class*='col-']:not(:last-child) { padding-left: 0; padding-right: 0; }
/* .row.no-gutter-all { margin:0 auto;} */
.row.no-gutter-all [class*='col-'] { padding-left: 0; padding-right: 0; }
#mc_embed_signup { margin-top:15px;}
#mc_embed_signup input[type="email"] { border-radius:5px; width:100%; padding:10px 15px; border:0; }
#mc_embed_signup input[type="submitted"] { margin-top:5px; transition: all 250ms ease-out 0s; border-radius:5px; width:100%; padding:10px 15px; border:0;  color:#fff; background:#cb6428;}
#mc_embed_signup input[type="submitted"]:hover { background:#808080;}

/* Social Buttons ---------------------- */
.social-links {margin:20px 0 0; padding:0;}
.social-links li { list-style: none;  display: inline-table;}
.social-links li a { color:#fff; font-size:13px; display: inline-block;  padding-top:7px;  text-align: center;  height: 34px;  width: 34px;  position: relative; z-index: 1; -webkit-transition: color 0.15s; transition: color 0.15s; -moz-osx-font-smoothing: grayscale; border-radius:50%;}
.social-links li a::before { content: '';  position: absolute;  top: 0;  left: 0;  width: 100%;  height: 100%;  border-radius: inherit;  z-index: -1;  box-shadow: inset 0 0 0 35px #808080;  -webkit-transform: scale3d(0.7, 0.7, 1);  transform: scale3d(0.7, 0.7, 1);  -webkit-transition: box-shadow 0.15s, -webkit-transform 0.15;  transition: box-shadow 0.15s, transform 0.15s;}
.social-links li a:hover::before {  -webkit-transform: scale3d(1.0, 1.0, 1);  transform: scale3d(1.0, 1.0, 1); box-shadow: inset 0 0 0 35px  #009DE0;}

.copyright {padding:15px 0; background:#525151;}
.copyright p {font-size:14px; color:#ccc; margin-bottom:0;}

/* Back to Top Button  ----------------------- */
#back-to-top { color:#fff; position:fixed; bottom:20px; right:20px; z-index:99; display:none; text-align: center; border-radius: 50%; -moz-border-radius: 50%;  -webkit-border-radius:  50%; -o-border-radius:  50%;    z-index: 10000;    height: 59px;    width: 59px;    background-color:#3190E7;    background-repeat: no-repeat;    background-position: center;  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24); -webkit-transform: scale3d(0.7, 0.7, 1);  transform: scale3d(0.7, 0.7, 1);  -webkit-transition: box-shadow 0.15s, -webkit-transform 0.15;  transition: box-shadow 0.15s, transform 0.15s;}
#back-to-top i { padding-top:20px; font-size:17px; }
#back-to-top:hover { background:#e77a21;  -webkit-transform: scale3d(1.0, 1.0, 1);  transform: scale3d(1.0, 1.0, 1);}

 .col-sm-3  {
  display:-webkit-box;
  box-sizing: border-box;
  
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.row:before{
  display: table;
}

img#footerImg {
  box-shadow: -1px 0px 17px gainsboro;
  border-radius: 20px 20px ;
}

/* html, body {
  width: 100%;
  overflow-x: hidden;
  background-color: rgba(255, 255, 255, 0.9);
} */

button#poli{
  text-decoration-line: underline;
  
    background-color: rgb(105, 104, 104,0.7);
    cursor: pointer;
    border: hidden;
  
}



label {
  font-weight: bold;
  margin: 0.5rem 0;
  color: #464646;
}

input {
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.4rem 0.2rem;
  color: #383838;
}

input:focus {
  outline: none;
  background: #fff1c4;
}

.btn {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid #00329e;
  background: #00329e;
  color: white;
  border-radius: 6px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.26);
  cursor: pointer;
  margin: 0 0.5rem;
}

.btn:hover,
.btn:active {
  background: #f67722;
  border-color: #f67722;
}

.btn--passive {
  color: #00329e;
  background: transparent;
  border: none;
  box-shadow: none;
}

.btn--passive:hover,
.btn--passive:active {
  background: #aec6f8;
}

.btn--danger {
  background: #d30808;
  color: white;
  border-color: #d30808;
}

.btn--danger:hover,
.btn--danger:active {
  background: #ff3217;
  border-color: #ff3217;
}

.modal {
  
  position: fixed;
  z-index: 100;
  background: white;
  border-radius: 10px;
  width: 80%;
  top: 40px;
  left: 10%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  display: none;
}

.modal.visible {
  display: block;
  animation: fade-slide-in 0.3s ease-out forwards;
  margin-top: 10px;
    padding-top: 20px;
    padding-bottom: 50px;
}

.modal .modal__title {
  margin: 0;
  padding: 1rem;
  border-bottom: 1px solid #00329e;
  background: #00329e;
  color: white;
  border-radius: 10px 10px 0 0;
}

.modal .modal__content {
  padding: 1rem;
}

.modal .modal__actions {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

.movie-element {
  margin: 1rem 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
  display: flex;
}

.movie-element h2,
.movie-element p {
  font-size: 1.25rem;
  margin: 0;
}

.movie-element h2 {
  color: #383838;
  margin-bottom: 1rem;
}

.movie-element p {
  color: white;
  display: inline;
  background: #f67722;
  padding: 0.25rem 1rem;
  border-radius: 15px;
}

.movie-element__image {
  flex: 1;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
}

.movie-element__image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.movie-element__info {
  flex: 2;
  padding: 1rem;
}

#entry-text {
  margin: 2rem;
  font-size: 1.5rem;
  text-align: center;
}

#movie-list {
  list-style: none;
  width: 40rem;
  max-width: 90%;
  margin: 1rem auto;
  padding: 0;
}

#backdrop {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
  pointer-events: none;
  display: none;
}

#backdrop.visible {
  display: block;
  pointer-events: all;
}

#add-modal .modal__content {
  display: flex;
  flex-direction: column;
 
}

@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@keyframes fade-slide-in {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0);
  }
}


.copyright {
  padding: 15px 0;
  background: #525151;
  
}
.copyright p {
  font-size: 14px;
  color: #ccc;
  margin-bottom: 0;
}
.text-center {
  text-align: center;
  text-indent: 500px;
}
#back-to-top {
  color: #fff;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  display: inline;
  text-align: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  z-index: 10000;
  height: 59px;
  width: 59px;
  background-color: rgba(186, 186, 221, 0.9);
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  -webkit-transform: scale3d(0.7, 0.7, 1);
  transform: scale3d(0.7, 0.7, 1);
  -webkit-transition: box-shadow 0.15s, -webkit-transform 0.15;
  transition: box-shadow 0.15s, transform 0.15s;
 
}

#back-to-top i {
  padding-top: 20px;
  font-size: 17px;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.footer-elements-contab {
  /* margin-top: 500px; */
  margin-left: 0px;
  margin-right: 400px;
  position: relative;
  margin-top: 80px;
}
#mapa{

  width: 500px;
   height:300px;
}

#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }

#modal_cerere_btn {
  visibility: hidden;
}

#titlu_contab{
  text-indent: 60px;
}
ul#liste_contab{
 line-height: 2px;
 text-indent: 10px;
 
}


@media(max-width: 660px){
  #secondary{
    display: none;
  }
  #paragraf_mare{
    padding-left: 10%;;
  }

  
  
h2#titlu_contabilitate {
  line-height: 1;
  margin-top: -40px;
  font-size: 1.2rem;
  text-indent: 5px;
  text-align: justify;
  padding-left: 1%;
  width: 100%;
  font-family: 'Andada';
  font-style: italic;
  text-shadow: 2px 2px 5px #caa6ec;
  padding-top: 10px;

}

#titlu_contab {
  text-indent: 24px;
  width: 100%;

 
}


#modal_cerere_btn {
  visibility: visible;
 
  border-radius: 5px 5px;
  border: none;
  background-color: rgb(255, 244, 230);
  font-weight: bold;
  font-stretch: condensed;
  font-family: sans-serif;
  border-color: cornsilk;
  box-shadow: 0 9px 12px rgb(121 121 121 / 26%);
  font-size: large;
  color-interpolation-filters: linearRGB;
  width: 100%;
  position: relative;
 
}

}