body {font-family: Arial, Helvetica, sans-serif;
    /* background-image: linear-gradient(to bottom, rgba(248, 248, 246, 0.8), rgba(255, 255, 255, 0.9)), url('../images/3.jpg'); */
    /* overflow-x: hidden; */
    background-position: center;
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-color: white;
}
* {box-sizing: border-box;}


html{
  box-sizing: border-box;
}
*,*:before, *:after{
  box-sizing:inherit;
}

input[type=text],[type=email], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type=button] {
  background-color: #546cbd;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top:40px;
  margin-left:10%;
}

input[type=button]:hover {
  background-color: #e6bc4a;
}
h2#cere_ofertaM{
  font-size:x-large;
  font-style: oblique;
  font-family:sans-serif;
  margin-top:100px;
text-indent: 150px;
height:5px;
}

#myclock{
  margin-left: 150px;;
}
.container_of {
  border-radius: 5px;
  background-color: #f2f2f2;
  /* padding: 20px; */
 padding-left:20%;
 padding-right:20%;
 padding-top:3%;
 padding-bottom: 8%;
 
  margin-right: 280px;
  /* margin-left:280px; */
  margin-bottom: 0%;
  width: 100%;
}

textarea#subject{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.error {
  color: red;
  font-size: 90%;
}


.modal1#popup {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  padding-top: 60px;
}
.modal-content {
  background-color: #fefefe;
  margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 50%; 
  height: 70%;/* Could be more or less, depending on screen size */
}
#close-button {
  float: right;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: lightgray;
}
#close-button:hover {
  background-color: darkgray;
}
.show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1.0);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}
.animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s
}

p#populate{
  width:50px;
  height:60%;
  margin-left:30px;
  line-height: 2;
  line-break: strict;


}

input[type=button]#submit-email{
  background-color: #546cbd;
  color: white;
  padding: 7px 16px;
  border: none;
  border-radius: 6px;
  margin-left: 30px;
  cursor: pointer;
  margin-top: 0px;
}

input[type=button]#submit-email:hover {
  background-color: #e6bc4a;
}

a#prev {
  text-decoration: none;
  display: inline-block;
  padding: 8px 16px;
  margin-top: 120px;
}

a#prev:hover {
  background-color: #ddd;
  color: black;
}
.section-notifications, .section-thin {
  padding: 0;
}
.section {
  padding: 70px 0;
  position: relative;
  background: #fff;
}
.alert.alert-success {
  background-color: rgba(24,206,15,.8);
}
.alert {
  border: 0;
  border-radius: 0;
  color: #fff;
  padding-top: .9rem;
  padding-bottom: .9rem;
}
.fade {
  transition: opacity .15s linear;
}
.alert.alert-info {
  background-color: rgba(44,168,255,.8);
}
.alert.alert-warning {
  background-color: rgba(255,178,54,.8);
}
.alert.alert-danger {
  background-color: rgba(255,54,54,.8);
}

.previous {
  background-color: #f1f1f1;
  color: black;
}
/* #myclock {
  position: relative;
  top: 20%;
  left: 85%;
  transform: translateX(-50%) translateY(-50%);
  color: #3e2d55;
  font-size: 15px;
  font-family: Orbitron;
  letter-spacing: 2px;
 


} */
@media screen and (max-width: 520px){


input[type=text],[type=email], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type=button] {
  background-color: #546cbd;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top:40px;
  margin-left:40px;
}

input[type=button]:hover {
  background-color: #e6bc4a;
  margin-left:40px;
}

.container_of {
  border-radius: 5px;
  background-color: #f2f2f2;
  /* padding: 20px; */
  padding: 50px 60px 60px 50px;
  margin-right: 10px;
  margin-left:10px;
  /* margin-bottom: 150px; */
}

textarea#subject{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.error {
  color: red;
  font-size: 90%;
}
  }



/* Popup container */
.modal{
  position: relative;
  display: none;
  cursor: pointer;
}

/* The actual popup (appears on top) */
.modal .popup_content {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
 .modal .popup_content::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}


/* Toggle this class when clicking on the popup container (hide and show the popup) */
.modal .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
  display: inline-block;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}


@media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) {



/* #myclock{
  margin-left: 80px;
  margin-top:50px;
} */

      body {font-family: Arial, Helvetica, sans-serif;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.9)), url('../images/3.jpg');
        
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;}
    * {box-sizing: border-box;}
    
    
    html{
      box-sizing: border-box;
    }
    *,*:before, *:after{
      box-sizing:inherit;
    }
  
    input[type=text],[type=email], select, textarea {
      width: 100%;
      padding: 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
      margin-top: 6px;
      margin-bottom: 16px;
      resize: vertical;
    }
    
    input[type=button] {
      background-color: #546cbd;
      color: white;
      padding: 12px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-top:40px;
      margin-left:280px;
    }
    
    input[type=button]:hover {
      background-color: #e6bc4a;
    }
    h2#cere_ofertaM{
      font-size:large;
      font-family: 'IBM Plex Sans';
      margin-top:100px;
    text-indent: 60px;
    height:5px;
    }
    .container_of {
      border-radius: 5px;
      background-color: #f2f2f2;
      /* padding: 20px; */
      padding: 50px 60px 60px 50px;
      margin-right: 280px;
      /* margin-left:280px; */
      margin-bottom: 150px;
    }
    
    textarea#subject{
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }
    .error {
      color: red;
      font-size: 90%;
    }
    
    
    .modal1#popup {
      display: none; /* Hidden by default */
      position: fixed; /* Stay in place */
      z-index: 1; /* Sit on top */
      left: 0;
      top: 0;
      width: 100%; /* Full width */
      height: 100%; /* Full height */
      overflow: auto; /* Enable scroll if needed */
      background-color: rgb(0,0,0); /* Fallback color */
      background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
      padding-top: 60px;
    }
    .modal-content {
      background-color: #fefefe;
      margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
      border: 1px solid #888;
      /* width: 257px;  */
      height: 70%;/* Could be more or less, depending on screen size */
      margin-top: 100px;
    width: 302px;
    }
    #close-button {
      float: right;
      width: 1.5rem;
      line-height: 1.5rem;
      text-align: center;
      cursor: pointer;
      border-radius: 0.25rem;
      background-color: lightgray;
    }
    #close-button:hover {
      background-color: darkgray;
    }
    .show-modal {
      opacity: 1;
      visibility: visible;
      transform: scale(1.0);
      transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
    }
    .animate {
      -webkit-animation: animatezoom 0.6s;
      animation: animatezoom 0.6s
    }
    
    p#populate{
      width:50px;
      height:60%;
      margin-left:30px;
      line-height: 2;
      line-break: strict;
    
    
    }
    
    input[type=button]#submit-email{
      background-color: #546cbd;
      color: white;
      padding: 5px 16px;
      border: none;
      border-radius: 6px;
      /* margin-left: 30px; */
      cursor: pointer;
      margin-top: 0px;
      margin-left: 5px;
    }
    
    input[type=button]#submit-email:hover {
      background-color: #e6bc4a;
      margin-left: 25px;
    }
    
    a#prev {
      text-decoration: none;
      display: inline-block;
      padding: 8px 16px;
    }
    
    a#prev:hover {
      background-color: #ddd;
      color: black;
    }
    .section-notifications, .section-thin {
      padding: 0;
    }
    .section {
      padding: 70px 0;
      position: relative;
      background: #fff;
    }
    .alert.alert-success {
      background-color: rgba(24,206,15,.8);
    }
    .alert {
      border: 0;
      border-radius: 0;
      color: #fff;
      padding-top: .9rem;
      padding-bottom: .9rem;
    }
    .fade {
      transition: opacity .15s linear;
    }
    .alert.alert-info {
      background-color: rgba(44,168,255,.8);
    }
    .alert.alert-warning {
      background-color: rgba(255,178,54,.8);
    }
    .alert.alert-danger {
      background-color: rgba(255,54,54,.8);
    }
    
    .previous {
      background-color: #f1f1f1;
      color: black;
    }
    /* #myclock {
      position: relative;
      top: 20%;
      left: 85%;
      transform: translateX(-50%) translateY(-50%);
      color: #3e2d55;
      font-size: 15px;
      font-family: Orbitron;
      letter-spacing: 2px;
     
    
    
    } */
      /* Popup container */
      .modal{
        position: relative;
        display: none;
        cursor: pointer;
      }
      
      /* The actual popup (appears on top) */
      .modal .popup_content {
        visibility: hidden;
        width: 160px;
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 8px 0;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -80px;
      }
      
      /* Popup arrow */
       .modal .popup_content::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
      }
      
      
      /* Toggle this class when clicking on the popup container (hide and show the popup) */
      .modal .show {
        visibility: visible;
        -webkit-animation: fadeIn 1s;
        animation: fadeIn 1s;
        display: inline-block;
      }
      
      /* Add animation (fade in the popup) */
      @-webkit-keyframes fadeIn {
        from {opacity: 0;}
        to {opacity: 1;}
      }
      
      @keyframes fadeIn {
        from {opacity: 0;}
        to {opacity:1 ;}
      }
  
  
    } 

    @media screen and (max-width: 520px){
   
    
    input[type=text],[type=email], select, textarea {
       width: 100%; 
      padding: 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
      margin-top: 6px;
      margin-bottom: 16px;
      resize: vertical;
      /* width: 251px; */
      height: 70%;
     
   
  
    }
    
    input[type=button] {
      background-color: #546cbd;
      color: white;
      padding: 12px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-top:40px;
      /* margin-left:40px; */
      margin-left: 0px;
      padding-bottom: 25px;
    }
    
    input[type=button]:hover {
      background-color: #e6bc4a;
      margin-left:40px;
    }
    
    .container {
      border-radius: 5px;
      background-color: #f2f2f2;
      /* padding: 20px; */
      padding: 50px 60px 60px 50px;
      margin-right: 10px;
      margin-left:10px;
      margin-bottom: 150px;
    }
    
    textarea#subject{
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }
    .error {
      color: red;
      font-size: 90%;
    }
      }
    
    
    



  




