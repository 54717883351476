.dropdown-menu {
    background: linear-gradient(90deg, rgb(71, 69, 69) 0%, rgb(26, 23, 23) 100%);
    width: 190px;
    position: absolute;
    top: 100%;
    list-style: none;
    text-align: start;
    border-radius: 3px 4px;
    z-index: 2;
  }
  
  .dropdown-menu li {
    background: #1a130e;
    cursor: pointer;
  }
  
  .dropdown-menu li:hover {
    background: #eea654;
  }
  
  .dropdown-menu.clicked {
    display: none;
  }
  
  .dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #fff;
    padding: 13px;
  }
  
  @media screen and (max-width: 960px) {
    .fa-caret-down {
      display: none;
    }
  }