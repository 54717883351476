.App {
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}




 
  #domeniu{
    /* padding-left: 3%; */
    padding-right: 2%;
    font-size: 3em;
    
  }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.home,
.services,
.products,
.contact-us,
.sign-up,
.marketing,
.consulting {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.home {
  /* background-image: url('./images/img-1.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.services {
  /* background-image: url('./images/img-2.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  /* background-image: url('./images/img-3.jpg'); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contact-us {
  /* background-image: url('./images/img-4.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  /* background-image: url('./images/img-7.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.marketing {
  /* background-image: url('./images/img-6.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.consulting {
  /* background-image: url('./images/img-5.jpg'); */
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.card_euro{
  max-height: 950px;
  height: 100%;
  background-color: #fff;
  width: 100%;
  /* // height: '450px', */
  position: relative;
  display: grid;
  /* //gridTemplateColumns: '1fr 1fr', */
  place-items: center;
  line-height: 1.5;
  box-shadow: '0 20px 30px rgba(0, 0, 0, 0.185)';
  /* margin-top: 5%; */
  margin-top: 100px;
  max-width: 700px;


}

.card-body{
  display: inline-flex;
}
.content-wrap-card{
  width: 100%;
  margin: 5% 5% 5% 5%;
  justify-content: center;
  position: relative;
  display: flex;
}

.newsletter-overlay{
  margin-bottom: 5%;
}
@media(max-width:770px){
   .card-body {
    display: inline-block;

  } 
  
}


