
body {font-family: Arial, Helvetica, sans-serif; width: 100%; height:100%;}


input[type=text], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

#titluri{
  width: 100%;
  font-size: 1rem;
  font-family: 'Arial';
  font-weight: bold;
  outline: #00329e;
}


/* Extra styles for the cancel button */
.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #f44336;
}

/* Center the image and position the close button */
.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
  position: relative;
}

img#avatar {
  width: 40%;
 position: relative;
}

.container {
  padding: 16px;
}

span.psw {
  float: right;
  padding-top: 16px;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  padding-top: 60px;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button (x) */
.close {
  position: absolute;
  right: 25px;
  top: 0;
  color: #000;
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: red;
  cursor: pointer;
}


.animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s
}

@-webkit-keyframes animatezoom {
  from {-webkit-transform: scale(0)} 
  to {-webkit-transform: scale(1)}
}
  
@keyframes animatezoom {
  from {transform: scale(0)} 
  to {transform: scale(1)}
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
     display: block;
     float: none;
  }
  .cancelbtn {
     width: 100%;
  }
}

@media  (max-width: 360px){
 /* .navbar5{
   display: flex;
flex-wrap: wrap;
text-indent: 10px;
gap: 0.5em;
border-bottom: 1px solid #bdb773;
border-top: 60px solid rgba(252, 227, 198, 0.9);
padding-inline-start: 13em;
background-color: rgba(240, 222, 203, 0.9);
margin-bottom: 0px;
border-top-width: 0px;
padding-left: 0px;
} */

}
 

 p#logs{
  background-color: rgba(252, 227, 198, 0.9);
  color: black;
  margin-bottom: -45px;
}
 p#calls {
  margin-left: 1050px;
   background-color: rgba(248, 227, 220, 0.9);
   background: border-box;
}

a#calling{
  color: black;
  font-size: 80%;


}
#mess p{
  font-family: 'Alata';font-size: 10px;
  color: black;
  

}

@media screen and (max-width: 520px){
img#profil {
    width: 58px;
    margin-right: 6px;
}
/* .navbar5{
  display: flex;
flex-wrap: wrap;
text-indent: 10px;
gap: 0.5em;
border-bottom: 1px solid #bdb773;
border-top: 60px solid rgba(252, 227, 198, 0.9);
padding-inline-start: 13em;
background-color: rgba(240, 222, 203, 0.9);
margin-bottom: 0px;
border-top-width: 0px;padding-left: 0px;
} */


}
img#profil a img {
  width: auto;
  max-width: 88px;
 
}

img.call{
  width: 20px;
  max-width: 88px;
  margin-left: 1090px;

  position: fixed;
  left: 0;
  top: 0;
  border-radius: 10px;
}

html{
  box-sizing: border-box;
  width: auto;
  height: auto;
  margin:0;
  padding: 0;
  
}
*,*:before, *:after{
  box-sizing:inherit;
}
/*Global styles
-------------------------------------------*/
body{
  color:#343434;
  margin:0;
  padding:0;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  line-height: 1.5;
  width: auto;
  height: auto;
  /* height: 1605px; */
}
img#profil{

  width: 180px;
  position: fixed;
   left:0;
   top:0;
   border-radius: 10px;
   
   /* background-color: rgba(247, 219, 184, 0.9); */
}


section#menu-section{

  background-image: linear-gradient(to bottom, rgba(247, 219, 184, 0.9), rgba(186, 186, 221, 0.9)),url('../images/3.jpg') ;  
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* rgba(207, 207, 137, 0.8), rgba(186, 186, 221, 0.9)) */
  animation: example 20s ease infinite;
  /* background-image:  */

}

@keyframes example {
  0%   {background-color: #ffa86e;}
  25%  {background-color: #ffa86e}
  80%  {background-color: rgb(231, 121, 47);}
  100% {background-color: rgb(248, 216, 37);
  }
}

 
.Politica {
  position: fixed;
  z-index: 200;
  border: 1px solid #eee;
  box-shadow: 0 2px 2px rgb(218, 218, 218);
  background-color: white;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  top: 30%;
   /* left: 15%; */
   left: 0px;
  /* width: 50%; */
  margin-top: 20px;
  height: 100%;
  max-height: 1072px;  
  transition: all 0.3s ease-out;
  bottom: 0px;
  top: 0px;
  
  right: 0px;
  width:100%;
  max-width: 882px;
  overflow-y: auto;
  max-height: calc(100vh - 40px);
  
}


.PoliticaOpen {
  animation: openModal 0.4s ease-out forwards;
}

.PoliticaClosed {
  animation: closeModal 1s ease-out forwards;
}



.fade-slide-enter-active {
  animation: openModal 0.4s ease-out forwards;
}



.fade-slide-exit-active {
  animation: closeModal 1s ease-out forwards;
}

@keyframes openModal {
  0% {
      opacity: 0;
      transform: translateY(-100%);
  }
  50% {
      opacity: 1;
      transform: translateY(90%);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes closeModal {
  0% {
      opacity: 1;
      transform: translateY(0);
  }
  50% {
      opacity: 0.8;
      transform: translateY(60%);
  }
  100% {
      opacity: 0;
      transform: translateY(-100%);
  }
}

/* The Modal (background)
.modalp {
  display: none; /* Hidden by default *
  position: fixed; /* Stay in place *
  z-index: 1; /* Sit on top *
  left: 0;
  top: 0;
  width: 100%; /* Full width *
  height: 100%; /* Full height *
  overflow: auto; /* Enable scroll if needed *
  background-color: rgb(0,0,0); /* Fallback color *
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity *
  padding-top: 60px;
} */

/* Modal Content/Box */
.modal__contentp {
  background-color: #fefefe;
  margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 650px; /* Could be more or less, depending on screen size */
  height:1024px;
}

/* The Close Button (x) */
.close {
  position: absolute;
  right: 25px;
  top: 0;
  color: #000;
  font-size: 35px;
  font-weight: bold;
}
#paragraf_pol{
  text-indent: 10px;;
  width:750px;
}
.modalp{
  height: 100%;
  max-height: 1024px;
  width:100%;
  max-width:824px;

}
#modalpolitica{
  width:100%;
  max-width: 824px;
  height: 100%;
  max-height: 524px;
  margin-top:0px;
  padding-left:20px;
  padding-right:15px;
  margin-left:5px;
  margin-right:20px;

}
.modal_polaction{
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  
}

#btn-ok{
  

  color: #00329e;
  background: transparent;
  border: none;
  box-shadow: none;

}

.btn-ok:hover,
.btn-ok:active {
  background: #aec6f8;
}

.close:hover,
.close:focus {
  color: red;
  cursor: pointer;
}


.animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s
}

@-webkit-keyframes animatezoom {
  from {-webkit-transform: scale(0)} 
  to {-webkit-transform: scale(1)}
}
  
@keyframes animatezoom {
  from {transform: scale(0)} 
  to {transform: scale(1)}
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
     display: block;
     float: none;
  }
  .cancelbtn {
     width: 100%;
  }
}

#btn-det1,#btn-det2,#btn-det3{
  background: #ffa86e;
  color: rgb(202, 179, 245);
  font-weight: 700;
  letter-spacing: .06em;
  text-transform: uppercase;
  font-size: 13px;
  border-radius: 2px;
  padding: 15px 30px;
  transition: background-color 1s ease 0s;
  cursor: pointer;
  border-radius: 20px;
  border: none;
}


a{
  color: #f3e6a5;
 
}
a#cere{
  color:#fcf4cf;
  text-decoration-line: none;
  width: 100%;
  font-size:15px;
}

a:hover{
  text-decoration: none;
}

h1{
  font-size: 100px;
  line-height: 1;
}


#despreContent{
  max-width: 100%;
    width: 100%;
    /* margin-left: 260px; */
    /* margin-right: 100px; */
  
    display: flex;
    padding-left:10%;
   padding-right:10%;
  background-color:  #f8f9fa!important;
}

.content-wrap{
 
  max-width: 1070px;
  width:85%;
margin-left: 260px;
margin-right: 100px;
   padding: 60px 0;
}
.content-wrap1.item-details{


  max-width: 800px;
  width:85%;
   margin: 0 auto;
   padding: 60px 0;
   
}

/* ///sters la 22:58 pt forma iphone responsive */
 .project-item h3{
  margin-bottom: 0; 
  text-align: start;
    font-size: medium;
    font-family: 'Andada';
    /* text-decoration-color: bisque; */
    text-shadow: 1px 1px 3px #caa6ec;
    text-overflow: ellipsis;
    outline-style: dotted;
    outline-color: whitesmoke;
    cursor: zoom-out;
  cursor: zoom-out;
  text-indent: 100px;
  width: 800px;
  margin-left: 60px;

} 

p#firma{
  text-indent: 50px;
  visibility: hidden;
}
.item-details h3 + p{
  font-style: italic;
}
/*advanced selector*/


.item-details h3 ~ p {
  margin: 0;
}

.divider > section{
  border-bottom: 1px none #949393;
  padding: 25px 0;
}

.divider > section:last-of-type {
   border-bottom: none;
}



/*Profile section
------------------------------------------*/
.header  {
   /* background-color:#dfe0ec; */
  /* color: #F7FFF7;  */
  width: 100%;
  background-color:  rgba(223, 223, 253, 0.7);
}

.fadein { position:relative; width:500px; height:332px; }
/* .fadein img#i1#i2#i3  :hover { position:center; left:0; top:0; } */




.item-1
{
	position: relative;
  display: block;
  top: 1em;
   
  
  width: 60%;
  
  font-size: 2em;

	animation-duration: 20s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}

.item-2{
  position: relative;
  display: block;
  top: -5em;
  /* float:center; */

  width: 60%;
  
  font-size: 2em;

	animation-duration: 20s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}

.item-3{
  position: relative;
  display: block;
	top: -13em;
  
  width: 60%;
  
  
  font-size: 2em;

	animation-duration: 20s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}
.item-1{
	animation-name: anim-1;
}

.item-2{
	animation-name: anim-2;
}

.item-3{
	animation-name: anim-3;
}

@keyframes anim-1 {
	0%, 8.3% { left: -100%; opacity: 0; }
  8.3%,25% { left: 20%; opacity: 1; }
  33.33%, 100% { left: 110%; opacity: 0; }
}

@keyframes anim-2 {
	0%, 33.33% { left: -100%; opacity: 0; }
  41.63%, 58.29% { left: 20%; opacity: 1; }
  66.66%, 100% { left: 110%; opacity: 0; }
}

@keyframes anim-3 {
	0%, 66.66% { left: -100%; opacity: 0; }
  74.96%, 91.62% { left: 20%; opacity: 1; }
  100% { left: 110%; opacity: 0; }
}

/*Projects
----------------------------*/
.projects{
  background:  #f8fcf8;
}

.projects a{
  color: #2F3061;

}

.projects .btn_projects{
  color:#F7FFF7;
  background:  #2F3061;
  text-decoration: none;
  padding: 8px;
  border-radius: 4px;
  display: inline-block;

}

.projects .btn_projects:hover{
  background: rgba(47, 48 , 97, 80%);
}

.project-item{
  overflow: hidden;
  animation: fade-slide-in 0.3s ease-out forwards;

}


.project-item h3{
  margin-top: 0;
}
/*Work experience
-----------------------------*/


.work{
  background: #edf6fa;
}




/* Education ------------------
*/
.despre{

  background-size: cover;
  background-position: top right;
  padding-bottom: 0px;
  background-color: white;


}

.despre p{
  width:60%;
}


/* Create two unequal columns that floats next to each other */
/* Left column */
/* .leftcolumn {   
  float: center;
  width: 75%;
} */


/* Fake image */
.despre_img {
  width: 100%;
  /* position: center; */
  height:100%; opacity: .5; 
  padding: 20px;
  background-image: url('../images/2i.png');
}

/* Add a card effect for articles */
.card {
  /* background-color: white;
  padding: 20px;
  margin-top: 20px; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 800px;
  margin: 40px 30px;
  text-align: inherit;
  height: 100%;
  width:150%;
  margin-right: 0px;
  margin-bottom: 20px;

}

p#profi{
  border-top: gray 5px;
  width: fit-content;
  text-indent: 50px;

  

}
#text_despre{
  margin-top:10px;
  /* margin-left: 10px; */
  /* margin-right:10px; */
  margin-bottom: 10px;;

  height: 650px;
  margin-left: 35px;
  margin-right: 20px;
}

ul{
  margin-top:0px;
  text-align: left;
  /* text-indent: 150px; */
  list-style-type: disc;
}
p#d_cor{
  width:fit-content;
  text-align: left;
  padding-bottom: 20px;
  text-indent: 50px;
  
  
}

img#s1{
  opacity: 0;
}
.row {

width:600px;
}
/*Contact Info
---------------------------------*/

/* footer{
  background: #2F3061;
  color: #F7FFF7;
  
} */

.contact-list{
  list-style-type: none;
  padding:0;

}

.contact-list a{
  padding: 15px;
  display: inline-block;
}

#articol_recent{
 text-indent:90px;
}
/* Responsive
-----------------------*/




@media screen and (min-width:750px){

  #menu-section {
    /* position:absolute; */
    top:0;
    left:0;
    width: 100%;
    height:100%;;
  }
  div.infoTransitions {
    display: flex;
    justify-content: space-between;
    column-gap: 0px;
    position: relative;
    margin-top: 0px;
    /* transition: opacity .5s ease-in; */
    height: 600px;
    margin-left: 23%;
  }
  
  
.item-1
{
	position: relative;
  display: grid;
  /* top: -7.5em; */
 
  /* height:100px; */
  width: 300px;
  /* margin-left: 0px; */
  font-size: 1em;

	animation-duration: 40s;
	animation-timing-function: ease-in-out;
  animation-iteration-count: 1;

  margin-top: 0px;
  height: 80px;
  top: 0px;
  left: 120px;
}

.item-2{
  position: relative;
  display: grid;
  top: -40em;
  /* float:center; */

  width: 440px;
  margin-left: 750px;
  font-size: 1em;
 
	animation-duration: 30s;
	animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

}

.item-3{
  position: relative;
  display: grid;
	top: 0px;
  /* margin-left: 200px;
  width: 540px; */
  width: 450px;
  margin-left: 720px;
  
  font-size: 1.5em;
  height:140px;

	animation-duration: 30s;
	animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  
}
.item-1{
	animation-name: anim-1;
}

.item-2{
  animation-name: anim-2;
  
}

.item-3{
	animation-name: anim-3;
}

@keyframes anim-1 {
	0%, 8.3% { left: -100%; opacity: 0; }
  8.3%,25% { left: 1%; opacity: 1; }
  33.33%, 100% { left: 110%; opacity: 0; }
}

@keyframes anim-2 {
	0%, 33.33% { left: -120%; opacity: 0; }
  41.63%, 58.29% { left: 20%; opacity: 1; }
  66.66%, 100% { left: 110%; opacity: 0; }
}

@keyframes anim-3 {
	0%, 66.66% { left: -120%; opacity: 0; }
  74.96%, 91.62% { left: 20%; opacity: 1; }
  100% { left: 110%; opacity: 0; }
}
h3#cand{
  font-family: '-webkit-pictograph;';
  font-size: 20px;
  width:724px;
 }
 img#profil{

  width: 200px;
  position: relative;
   left:40px;
   top:0px;
   border-radius: 10px;
  
   
   /* background-color: rgba(247, 219, 184, 0.9); */
}
.videoTag{
  /* visibility: hidden; */
  position:absolute;
  display: none;
  min-height: 360vh;
}



.content-wrap2{

    width: 900px;
    margin-left: 10px;
    margin-right: 200px;
    padding: 0px 0;
  
    height: 765px;
}
 div.anunt{
 
    position: absolute;
   
     margin-top: 0px;
    top: 642px;
    margin-left: 510px;
    width: 500px;
   }
   #tickr-scroll a {
    color: #ff0000!important;
    text-decoration: none;
    font-weight: bold;
}

div#anunturi{
  position: fixed;
  /* top:0%; */
 bottom: -10%;
z-index:1;
   margin-top: 460px;
  /* left: 130px; */
  width: 2600px; 

  
  
}



#par{
  margin-top: 65px;
}

.news {
  width: 160px
}

.news-scroll a {
  text-decoration: none
}

.dot {
  height: 6px;
  width: 6px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 2px !important;
  background-color: rgb(207, 23, 23);
  border-radius: 50%;
  display: inline-block
}

#tickr-box {
  background: #C0C0C0;
  max-width: 950px;
  padding: 3px;
  margin: 0 auto 0 auto;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
}

h2#titlu_mob {
  line-height: 1;
  /* margin-top: -60px; */
  padding-top: 20px;
  height: 66px;
  font-size: 20px;
  padding: 1px 2px;
  text-align: center;
  padding-right: 150px;
  width: 510px;
  padding-left: 0px;

  background-color: #edeff2; ;
  border-right-width: 20px;
  margin-right: 20px;
  font-family: 'system-ui';
  
  text-shadow: 2px 2px 5px #caa6ec;
  text-indent: 15px;
  visibility: visible;
}



h3#asistenta {
  margin-bottom: 0;
  outline-style: dotted;
  outline-color: whitesmoke;
  cursor: zoom-out;
  text-indent: 100px;
  width: 800px;
  margin-left: 60px;
  visibility: hidden;
}
img#human{
  width:330px;
  
  
  /* margin-bottom: 490px; */
  /* position:absolute; */
  margin-left:35px;
  height:440px;
  transition: opacity .5s ease-in;
  margin-top:140px;

  
}




img#titlu_poza{
  width:470px;
  opacity: 0.8;
  /* background-color: #151823; */
  /* margin-top:39px; */
  height:750px;
}


img#anunt{
  width:330px;
  /* margin-left:880px; */
  height:440px;
  margin-top:240px;

}

 #link1{
  margin-left:27px;
   margin-right:20px;
   
 }

 /* #link2{
  width: 300px;
   max-width: 100%;
 } */

 #link3{
margin-left:15px;
 margin-right:30px; 

 }

  header, p#logs{
    /* , footer */
      text-align: center;
      /* background-color:  rgba(252, 227, 198, 0.9); */
     background-color:  rgba(250 225 196 / 90%);
}
h2#articol_recent {
  width:150%;
  text-indent: 570px;
  margin-top:0px;
  padding-top:25px;
  word-spacing: 4px;
  text-decoration-style:wavy;
   text-decoration-color: #a7510b;
  font-weight:400;
  font-size:x-large;
  background-color: #ffa86e;
  text-shadow: 2px 2px 5px #ffa86e ;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 
  border-radius: 25%;

  
}


h2#oferim{
  width:1651px;
  text-indent: 170px;
  margin-top:0px;
  padding-top:10px;
  word-spacing: 4px;
  text-decoration-style:wavy;
   text-decoration-color: #a7510b;
  font-weight:500;
  font-size:xx-large;
  /* background-color: #ffa86e; */
  text-shadow: 2px 2px 5px #ffa86e ;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 
}

h2#despre_noi {
  width:1651px;
 
  margin-top:0px;
  padding-top:10px;
  word-spacing: 4px;
  text-decoration-style:wavy;
   text-decoration-color: #a7510b;
  font-weight:500;
  font-size:xx-large;
  /* background-color: #ffa86e; */
  text-shadow: 2px 2px 5px #ffb888 ;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; 
  font-weight: 400;
  margin-top: -40px;
  text-indent: -20px;
}
#noutati{

  width: 100%;
   
    padding-left: 15%;
    padding-bottom: 5%;
}

.popover-header{
  
  font-weight: bolder;
}
.popover-body{
  background-color: antiquewhite;
  border-radius: 2rem;
}

#criterii{
  text-indent:5px;
}
#d_cor{
  padding-left:20px;
  padding-right: 5px;
 text-indent: 10px;
}
#pol{
  border:none;
  background-color: #666a6a;
  text-decoration: underline;
  border-radius: 10px;
}

  
  .project-item img {
      float: left;
      margin-right: 20px;
  }
  .job-item{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 1px;
      width: 100%;
      padding-top:1rem;
      
  }

  #link4{
   
    margin-right: 2.5%;
    padding-bottom: 40px;
    margin-left: 2%;
  }
  
#myclock {
  position: relative;
  top: 20%;
  left: 85%;
  transform: translateX(-50%) translateY(-50%);
  color: #3e2d55;
  font-size: 15px;
  font-family: Orbitron;
  letter-spacing: 2px;
 


}
  .projects{
    background-color: #ecebf6;
  }
  #post1{

    /* width:500px; height:265px;  */
    border:none;overflow:hidden;margin-right: 20px;margin-left: 20px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 4px;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 70%;
  }
  
  #post2{
    /* width:500px; height:260px; */
    border:none;overflow:hidden;margin-left:20px;
    display: inline;
    margin-right: 20px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 4px;


    position: absolute;
    top: 0;
    left: 0;
    /* bottom: 0; */
    /* right: 20px; */
    width: 100%;
    height: 70%;
  }
  #post3{
    /* width:320px; height:285px; */
    border:none;overflow:hidden;margin-right: 0px;
    display: inline;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 4px;


    position: absolute;
    top: 0;
    left: 0;
    /* bottom: 0; */
    right: 0;
    width: 100%;
    /* height: 100%; */
  }
  .MuiCardActions-root {
    display: flex;
    padding: 8px;
    align-items: center;
    
}



 .copy-link {
  padding-left: 30px;
  padding-right: 30px;
}



.targets {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  /* margin-bottom: 20px; */
  padding-left: 1rem;
  padding-top: 1rem;
}


.linkA {
  /* display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border-radius: 2px; */
  /* background-color: #eee; */
  background-color: #2e2e46;
  padding-top: 10px;
    vertical-align: bottom;
    font-size: 23px;
    color: yellow;
    margin: 0;
  width:100%;
    animation: marquee 10s linear infinite;
  display: inline-block;
  /* padding-right: 10px; */
  text-decoration: none;
 
}


#anunturi:after {
	content:'';
  top:0;
	transform:translateX(100%);
	width:100%;
	height:115px;
	position: absolute;
	z-index:1;
	animation: slide 5s infinite;
	 
  /* 
  CSS Gradient - complete browser support from http://www.colorzilla.com/gradient-editor/ 
  */
  background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(128,186,232,0) 99%, rgba(125,185,232,0) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0.8)), color-stop(99%,rgba(128,186,232,0)), color-stop(100%,rgba(125,185,232,0))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* IE10+ */
	background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
}

/* animation */

@keyframes slide {
	0% {transform:translateX(-100%);}
	100% {transform:translateX(100%);}
}
img#anuntnews{
  max-width: 129px;
  height: auto;
  display: inline;
  /* object-position: left bottom; */
  /* padding-bottom: 15px; */
  /* background-color:#2e2e46;
  border-radius: 25%; */

  position: relative;
}


.position-fixed-top {
  position:fixed !important;
  top: 0;
}

.containerNews{
  border-radius: 3px;
    /* background-color: #f2f2f2; */
    /* padding: 20px; */
    padding-top: 0px;
    margin-bottom: 105px;
    margin-right: 180px;
    margin-left: 40px;
    /* margin-bottom:0px; */
    width:100%;
 
    display: inline-flex;
   

}
/* .row2{
  width:100%;
  margin-right: auto;
  /* display:inline-flex; */
  /* margin-left: auto; */
/* }  */
.pen-url {
  margin-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.hidden {
  display: none;
}



 html, body{
  /* max-width: 100%; */
  overflow-x: hidden;
  }
  .fb{
  width: 100%;
    height: auto;
  }
  .work{
    background-color: white;
    height:auto;
    width: 100%;
    padding-bottom: 15%;
    padding-top:5%;
    /* padding-right:60px; */
   
  }
 
 
  .contact-list{
      display: flex;
      justify-content: center;
  }
  p#logs{
    background-color: rgba(252, 227, 198, 0.9);
    color: black;
    margin-bottom: -45px;
    margin-top: 0px;
  }
  p#calls{
    margin-left: 1050px;
    background-color:  rgba(252, 227, 198, 0.9);
  }
 
  a#calling{
    color: black;
    font-size: 80%;
  }
  #despre_img {
    width: 20%;
    background-size:cover;
    /* height:200px; */
     opacity: .2; 
    /* border-radius: 0px; */
    /* padding: 4px; */
    margin-left: 50px;
    margin-top: 30px;
    background-image: url('../images/2i.png');
    background-repeat: repeat-x;
    height: auto;

  }
  /* h2#despre_noi {
    font-family: 'Caveat', cursive;
    font-weight: 400;
    margin-top:-40px;
    text-indent: -20px;
} */
  .content-wrap1.item-details{
    max-width: 800px;
    width: 105%;
    margin: 0 auto;
    padding: 60px 0;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 60px;
    padding-left: 0px;
}
  

#desen1{
  float:left; 
  background:Red;
  /* width:15%; */
  height:280px;
}

.s{
  display: flex;
}

#desen2{
  float:right;
  background-image:url('../images/despre_f.png');
  /* width:35%; */
  height:280px;
  /* margin-top:56px; */
}

#videoD{
  /* margin-left: 146px; */
  width: 140%;
  height: auto;
  /* width: 50%; */
  float: center;

  
} 



#despreS{
  width: 100%;
  height: auto;
 
  margin-bottom:5%;
  max-width: 1950px;
 position: relative;


  
}


/* Clear floats after the columns */
#despreS::after {
  content: "";
  display: table;
  clear: both;
}


 



/* .row1{width:400%; display:inline-flex}
.row.full-width {  margin: 0 auto; max-width: 100%; width: 100%; }
.row.custom-width-90 {  margin: 0 auto; max-width: 90%; width: 90%; }
.row.full-width-padding {  margin: 0 auto; max-width: 98%; width: 98%; }
.row.no-gutter { margin:0 auto;} */
.row.no-gutter [class*='col-']:not(:first-child), .row.no-gutter [class*='col-']:not(:last-child) { padding-left: 0; padding-right: 0; }
/* .row.no-gutter-all { margin:0 auto;} */
.row.no-gutter-all [class*='col-'] { padding-left: 0; }
#mc_embed_signup { margin-top:15px;     height: 40px;
  width: 320px; border-radius:5px;}
#mc_embed_signup input[type="email"] { border-radius:5px; width:100%; padding:10px 15px; border:0;    width: 320px;}
#mc_embed_signup input[type="submit"] { margin-top:2px; transition: all 250ms ease-out 0s; border-radius:5px;     width: 320px; padding:10px 15px; border:0;  color:#fff; background:#cb6428;}
#mc_embed_signup input[type="submit"]:hover { background:#808080;}

/* Social Buttons ---------------------- */
.social-linkss {margin:20px 0 0; padding:0;}
.social-linkss li { list-style: none;  display: inline-table;}
.social-linkss li a { color:#fff; font-size:13px; display: inline-block;  padding-top:7px;  text-align: center;  height: 34px;  width: 34px;  position: relative; z-index: 1; -webkit-transition: color 0.15s; transition: color 0.15s; -moz-osx-font-smoothing: grayscale; border-radius:50%;}
.social-linkss li a::before { content: '';  position: absolute;  top: 0;  left: 0;  width: 100%;  height: 100%;  border-radius: inherit;  z-index: -1;  box-shadow: inset 0 0 0 35px #808080;  -webkit-transform: scale3d(0.7, 0.7, 1);  transform: scale3d(0.7, 0.7, 1);  -webkit-transition: box-shadow 0.15s, -webkit-transform 0.15;  transition: box-shadow 0.15s, transform 0.15s;}
.social-linkss li a:hover::before {  -webkit-transform: scale3d(1.0, 1.0, 1);  transform: scale3d(1.0, 1.0, 1); box-shadow: inset 0 0 0 35px  #009DE0;}

.copyright {padding:15px 0; background:#525151;}
.copyright p {font-size:14px; color:#ccc; margin-bottom:0;}

/* Back to Top Button  ----------------------- */
#back-to-top { color:#fff; position:fixed; bottom:20px; right:20px; z-index:99; display:none; text-align: center; border-radius: 50%; -moz-border-radius: 50%;  -webkit-border-radius:  50%; -o-border-radius:  50%;    z-index: 10000;    height: 59px;    width: 59px;    background-color:#3190E7;    background-repeat: no-repeat;    background-position: center;  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24); -webkit-transform: scale3d(0.7, 0.7, 1);  transform: scale3d(0.7, 0.7, 1);  -webkit-transition: box-shadow 0.15s, -webkit-transform 0.15;  transition: box-shadow 0.15s, transform 0.15s;}
#back-to-top i { padding-top:20px; font-size:17px; }
#back-to-top:hover { background:#e77a21;  -webkit-transform: scale3d(1.0, 1.0, 1);  transform: scale3d(1.0, 1.0, 1);}

 .col-sm-3  {
  display:-webkit-box;
  box-sizing: border-box;
  padding-left: 30px;  
  min-height: 1px;
  padding-right: 15px;
  
}
.card-container{
  /* float:center; */
  display: block;
  /* inline-size: auto; */


}
.despre {
  background-size: cover;
  background-position: top right;
  padding-bottom: 0px;
  background-color: white;
  margin-top:250px;
  height: 100%;
  width: 100%;

  

}
#pozeC{
  margin-top:260px;
  
}

.despre::after{
	transform-origin: left bottom;
	transform: skewY(3deg);
}

.row:before{
  display: table;
}

img#footerImg {
  box-shadow: -1px 0px 17px gainsboro;
  border-radius: 20px 20px ;
}
#articol_recent {
  text-indent: 190px;
}

#postare{
  display: inline-flex; margin-left: 20px;margin-right: 20px;
 }


@media screen and (max-width:749px){
  h1{
      font-size:75px;
      line-height: 0.9;
      margin-bottom: 20px;
  }
  h2{
       line-height: 1; 
      margin-top: -35px;
      font-size: 30px;
      padding: 1px 2px;
      text-align: center;
      width: 100%;

  }

 .project-item h3{
    text-indent: 15px;
    text-align: start;
    font-size: medium;
    font-family: cursive; 
    /* text-decoration-color: bisque; */
    /* text-decoration-line: underline; */
    /* text-decoration-skip-ink: inherit; */
    /* text-overflow: ellipsis;*/
  } 
  .contact-list a {
      padding: 5px;
  }

  .imgcontainer img#avatar {
    width: 40%;
    border-radius: 50%;

  }
  p#logs{
    
    text-align: center;
    background-color: rgba(252, 227, 198, 0.9);
    color: black;
    padding-top: 30px;
    /* margin-bottom: -45px; */
  
  }
  p#calls{

    margin-left: 140px;
    /* background-color: blanchedalmond; */

    /* background-color: blanchedalmond; */
    margin-top: -50px;
  }

  p#firma{
    /* text-indent: 10px; */
    visibility: hidden;
  }
  
  a#calling{
    color: black;
    font-size: 80%;
  }
 
  img#profil{

    width: 130px;
    position: fixed;
     left:0;
     top:0;
     border-radius: 10px;
     
     /* background-color: rgba(247, 219, 184, 0.9); */
  }


  .headings{
    padding-bottom: 0px;
        height: 660px;
        width: 389px;
  }
  
  .item-2 {
    position: relative;
    display: block;
    top: -7em;
    width: 80%;
    font-weight: 600;
    font-size: 1.5em;
  
    animation-duration: 20s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  
}

.item-1{
  position: relative;
  display: block;
  /* top: em; */
  width: 60%;
  font-size: 1.5em;
  animation-duration: 20s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  font-weight: 600;
  width:300px;
}

.item-3{

  position: relative;
  display: block;
  top: -18em;
  width: 80%;
  font-size: 1.5em;
  animation-duration: 20s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  font-weight: 600;

}
#postare{
  margin-left: 30px;
  margin-right: 30px;
  height: 780px;
}

#articol_recent{

  height: 67px; width: 354px;

}

.card-container{
  /* float:center; */
  display: block;
  /* inline-size: auto; */
  width: 300px;

}


.card {
  width: 360px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  
    margin: 40px 30px;
    text-align: inherit;
    height: 100%;
    width: 150%;
    margin-right: 0px;
    margin-bottom: 20px;
}
.despre{
  width: 500px;
}

.content-wrap1.item-details{


  margin-left: 0px;
  width: 240px;
  margin-right: 600px;
  padding: 60px 0;
}
.pozaTranzitie{
  margin-top: 0px;
    height: 299px;
    margin-left: 60px;
    width: 450px;
}
.content-wrap{
    width: 300px;
    margin-left: 60px;
    margin-right: 600px;
    padding: 60px 0;
  
      
  
}
.row {

  width: 200px;
  display: -webkit-inline-box;
}

#text_despre{
  width: 330px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}
#criterii{
  margin-top: 0px;
    text-align: left;
    text-indent: 10px;
}
#post2{
  /* width:552; height: 349; */
  border:none;overflow:hidden;width: 348px;height: 300px;margin-right: 80px;
}

#post1{
  /* width:552; height:340; */
   border:none;overflow:hidden;height: 323px;width: 350px;margin-right: 0px;
}

#post3{
  border:none;
  width: 348px;
  overflow: hidden;
}





.col-sm-12{
  margin-left: 0px;
  width: 90px;
}

.col-sm-3{
  margin-left: 0px;
  width: 110px;
 display: -webkit-box;
 box-sizing: border-box;
 min-height: 1px;
 height: 59px;
}




  .text-center{
    width: 360px;
    height: 40px;
    margin-top: 20px;
    border-bottom-width: 0px;
    margin-left: 30px;
  }
 

.row { max-width: 1170%; width:100%; display: -webkit-inline-box; }
/* .row1{width:400%; display:inline-flex}*/
.row.full-width {  margin: 0 auto; max-width: 100%; width: 100%; }
.row.custom-width-90 {  margin: 0 auto; max-width: 90%; width: 90%; }
.row.full-width-padding {  margin: 0 auto; max-width: 98%; width: 98%; }
.row.no-gutter { margin:0 auto;} 
.row.no-gutter [class*='col-']:not(:first-child), .row.no-gutter [class*='col-']:not(:last-child) { padding-left: 0; padding-right: 0; }
/* .row.no-gutter-all { margin:0 auto;} */
.row.no-gutter-all [class*='col-'] { padding-left: 0; padding-right: 0; }
#mc_embed_signup { margin-top:15px;}
#mc_embed_signup input[type="email"] {  border:0; padding-top: 3px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 3px;
  width: 89px;}
#mc_embed_signup input[type="submit"] { margin-top:5px; transition: all 250ms ease-out 0s; border-radius:5px; border:0; font-size: smaller; color:#fff; background:#cb6428;
  padding-top: 2px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 3px;
  width: 89px;
  height: 35px;}
#mc_embed_signup input[type="submit"]:hover { background:#808080;}

/* Social Buttons ---------------------- */
.social-linkss {margin:20px 0 0; padding:0;}
.social-linkss li { list-style: none;  display: inline-table;}
.social-linkss li a { color:#fff; font-size:13px; display: inline-block;  padding-top:7px;  text-align: center;  height: 34px;  width: 34px;  position: relative; z-index: 1; -webkit-transition: color 0.15s; transition: color 0.15s; -moz-osx-font-smoothing: grayscale; border-radius:50%;}
.social-linkss li a::before { content: '';  position: absolute;  top: 0;  left: 0;  width: 100%;  height: 100%;  border-radius: inherit;  z-index: -1;  box-shadow: inset 0 0 0 35px #808080;  -webkit-transform: scale3d(0.7, 0.7, 1);  transform: scale3d(0.7, 0.7, 1);  -webkit-transition: box-shadow 0.15s, -webkit-transform 0.15;  transition: box-shadow 0.15s, transform 0.15s;}
.social-linkss li a:hover::before {  -webkit-transform: scale3d(1.0, 1.0, 1);  transform: scale3d(1.0, 1.0, 1); box-shadow: inset 0 0 0 35px  #009DE0;}

.copyright {padding-top: 0px; background:#525151;    height: 67px;
  }
.copyright p {font-size:14px; color:#ccc; margin-bottom:0;    height: 50px;
  margin-top: 0px;}

/* Back to Top Button  ----------------------- */
#back-to-top { color:#fff; position:fixed; bottom:20px; right:20px; z-index:99; display:none; text-align: center; border-radius: 50%; -moz-border-radius: 50%;  -webkit-border-radius:  50%; -o-border-radius:  50%;    z-index: 10000;    height: 59px;    width: 59px;    background-color:#3190E7;    background-repeat: no-repeat;    background-position: center;  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24); -webkit-transform: scale3d(0.7, 0.7, 1);  transform: scale3d(0.7, 0.7, 1);  -webkit-transition: box-shadow 0.15s, -webkit-transform 0.15;  transition: box-shadow 0.15s, transform 0.15s;}
#back-to-top i { padding-top:20px; font-size:17px; }
#back-to-top:hover { background:#e77a21;  -webkit-transform: scale3d(1.0, 1.0, 1);  transform: scale3d(1.0, 1.0, 1);}


#politica{    padding-top: 40px;}
.row:before{
  display: table;
}

img#footerImg {
  box-shadow: -1px 0px 17px gainsboro;
  border-radius: 20px 20px ;
  width: 90px;height: 40px;
  
}

html, body {
  width: 100%;
  overflow-x: hidden;
  background-color: rgba(252, 227, 198, 0.9);
}

button#pol{
  text-decoration-line: underline;
  
    background-color: rgb(105, 104, 104,0.7);
    cursor: pointer;
    border: hidden;
  
}


}

.despre_img {
  width: 100%;
  padding: 10px;
  background-image: url(../images/2i.png);
  height:auto;
  background-size: contain;
  background-repeat: no-repeat;
}


.card-container{
  /* float:center; */
  display: block;
  /* inline-size: auto; */


}

.titlee {
  color: grey;
  font-size: 18px;
  margin-left: 50px;
}

.jtitle{
  margin-left: 50px;
}

p#but {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

a#sign {
  text-decoration: none;
  font-size: 22px;
  color: black;
}

p#but:hover, a#sign:hover {
  opacity: 0.7;
}

/* img#cris{
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

img#cora{
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
} */

#pozaTranzitie{
  /* background-image: url('../images/3.jpg'); */
  width: 100%;
  height: 20%;
  
}




label {
  font-weight: bold;
  margin: 0.5rem 0;
  color: #464646;
}

input {
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.4rem 0.2rem;
  color: #383838;
}

input:focus {
  outline: none;
  background: #fff1c4;
}

.btn {
  font: inherit;
  /* padding: 0.5rem 1.5rem; */
  border: 1px solid #00329e;
  background: #f67722;
  /* color: white; */
  border-radius: 6px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.26);
  cursor: pointer;
  margin: 0 0.5rem;
  padding-left: 10px;
  margin-right: 0px;
}

.btn:hover,
.btn:active {
  background: #f67722;
  border-color: #f67722;
}

.btn--passive {
  color: #00329e;
  background: transparent;
  border: none;
  box-shadow: none;
}

.btn--passive:hover,
.btn--passive:active {
  background: #aec6f8;
}

.btn--danger {
  background: #d30808;
  color: white;
  border-color: #d30808;
}

.btn--danger:hover,
.btn--danger:active {
  background: #ff3217;
  border-color: #ff3217;
}

.modal {
  
  position: fixed;
  z-index: 100;
  background: white;
  border-radius: 10px;
  width: 80%;
  top: 40px;
  left: 10%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  display: none;
}

.modal.visible {
  display: block;
  animation: fade-slide-in 0.3s ease-out forwards;
  margin-top: 10px;
    padding-top: 20px;
    padding-bottom: 50px;
}

.modal .modal__title {
  margin: 0;
  padding: 1rem;
  border-bottom: 1px solid #00329e;
  background: #00329e;
  color: white;
  border-radius: 10px 10px 0 0;
}

.modal .modal__content {
  padding: 1rem;
}

.modal .modal__actions {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

.movie-element {
  margin: 1rem 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
  display: flex;
}

.movie-element h2,
.movie-element p {
  font-size: 1.25rem;
  margin: 0;
}

.movie-element h2 {
  color: #383838;
  margin-bottom: 1rem;
}

.movie-element p {
  color: white;
  display: inline;
  background: #f67722;
  padding: 0.25rem 1rem;
  border-radius: 15px;
}

.movie-element__image {
  flex: 1;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
}


.movie-element__image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.movie-element__info {
  flex: 2;
  padding: 1rem;
}

#entry-text {
  margin: 2rem;
  font-size: 1.5rem;
  text-align: center;
}

#movie-list {
  list-style: none;
  width: 40rem;
  max-width: 90%;
  margin: 1rem auto;
  padding: 0;
}

#backdrop {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
  pointer-events: none;
  display: none;
}

#backdrop.visible {
  display: block;
  pointer-events: all;
}

#add-modal .modal__content {
  display: flex;
  flex-direction: column;
 
}

@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
  
}

@keyframes fade-slide-in {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0);
  }
}


.copyright {
  padding: 15px 0;
  background: #525151;
  
}
.copyright p {
  font-size: 14px;
  color: #ccc;
  margin-bottom: 0;
}
.text-center {
  text-align: center;
  text-indent: 500px;
}
#back-to-top {
  color: #fff;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  display: inline;
  text-align: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  z-index: 10000;
  height: 59px;
  width: 59px;
  background-color: rgba(186, 186, 221, 0.9);
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  -webkit-transform: scale3d(0.7, 0.7, 1);
  transform: scale3d(0.7, 0.7, 1);
  -webkit-transition: box-shadow 0.15s, -webkit-transform 0.15;
  transition: box-shadow 0.15s, transform 0.15s;
 
}

#back-to-top i {
  padding-top: 20px;
  font-size: 17px;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width:960px){

  
  .infoTransitions{
    display: none;
  }
  .pozaTranzitie{
    display: none;
  }
  img #human{
    display: none;
  }

  /* #anunturi{
    position: fixed;
    z-index: 1;
    top:76%;
  } */

  #politica{
    z-index: 2;
  }
 .news-scroll{
   width:auto;
    pointer-events: none;
 }
 
 #anunturi{




  z-index:1;
  overflow: hidden;
 pointer-events: none;

 position: fixed;
 bottom: 0 ;
 margin:0;
 width: 100%;
 display: none !important;

 }


 .news{
  height:auto;
  /* background-color:Blue; */
  padding: 10px 30px 10px 30px;
  font-size: x-large;
  color: white;
  z-index:1;
   display: block;
}

 /* #anuntnews{
 width:max-content;
 pointer-events: none;
 
 } */
 .link{
   width: fit-content;
   font-size: large;
   scroll-behavior: auto;


   display: inline;
   text-decoration: none;
  
 }
  .anunt{
    display: none;
  }

  .containerNews{
    /* width: 100%;
    display: fixed;
    margin-left: 0px;
  */

padding-top: 10px;
    vertical-align: middle;
    font-size: 25px;
    color: yellow;
    margin: 0;
  width:100%;
    animation: marquee 10s linear infinite;
  display: inline-block;
  /* padding-right: 10px; */
}
  
  

  .card{
    max-height: 950px;
    height: 100%;
    background-color: rgb(255, 255, 255);
    width: 100%;
    position: relative;
    display: grid;
    place-items: center;
    line-height: 1.5;
    box-shadow: rgb(0 0 0 / 18%) 0px 20px 30px;
    margin-top: 15%;
    max-width: 700px;
    width: 100%;;
    top: 50px;
  }

  img#profil{

    width: 200px;
    position: relative;
     left:20px;
     top:-55px;
     border-radius: 10px;
  }
.textBox{
  padding-right: 5%;
    max-width: 300px;
    width: 100%;
    margin-right: 0px;
  
}

.md-form{
  color: rgb(78, 94, 114);
  margin: 0px;
  padding: 0px;
  text-align: center;
  font-family: monospace;
  overflow: hidden;
  max-width: 300px;
  width: 100%;

}


  .menu-icon {
    display: block;
    position: absolute;
    right: 0;
    font-size: 1.8rem;
    cursor: pointer;
    margin-top: 0px;
    width: 66px;
    height: 56px;
}
.navbar {
  background: linear-gradient(
90deg
, rgb(99, 97, 97) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  width:100%;
  z-index: 100;
}

.videoTag{
  width:100%;

}

.card-body px-lg-5 pt-0{
  width:100%;
}


#post2 {
  border: none;
  overflow: hidden;
  height: 310px;
  width: 330px;
  margin-right: 0px;
  margin-left: 14px;
  padding-top: 15px;
}
#post1 {
  border: none;
  overflow: hidden;
  height: 310px;
  width: 330px;
  margin-right: 0px;
  margin-left: 14px;
  padding-top: 10px;
}
#post3 {
  border: none;
  width: 330px;
  margin-top: 25px;
  margin-left: 14px;
  overflow: hidden;
}
.job-item {
  display: table;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 1px;
  column-gap: 1px;
}
}

#nt-example1 {
  max-width: 620px;
  margin: auto;
}

#nt-example1-container {
  text-align: center;
}

#nt-example1-container i {
  font-size: 36px;
  margin: 8px;
  cursor: pointer;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

#nt-example1-container i:hover {
  color: #333;
}

#nt-example1 li {
  color: #4e4e4e;
  background: #f2f2f2;
  overflow: hidden;
  height: 80px;
  padding: 10px;
  line-height: 30px;
  list-style: none;
  font-size: 24px;
  text-align: left;
  border-bottom: 1px dotted #2c8162;
}

#nt-example1 li:hover {
  background: #fff;
}



@media screen and ( max-width:812px){
  
  h1{
      font-size:75px;
      line-height: 0.9;
      margin-bottom: 20px;
  }
  /* h2{
       line-height: 1; 
      margin-top: -40px;
      font-size: 27px;
      padding: 1px 2px;
      text-align: center;
      padding-right: 200px;
      width: 510px;
      padding-left: 0px;
      height: 90px;
      border-right-width: 20px;
      margin-right: 20px;
      font-family: 'Andada';
      font-style: italic;
      text-shadow: 2px 2px 5px #caa6ec ;
      margin-bottom: 0px;

  } */
  

  .project-item h3{
    text-indent: 19px;
    text-align: start;
    font-size: medium;
    font-family: 'Andada';
    /* text-decoration-color: bisque; */
    text-shadow: 1px 1px 3px #caa6ec ;
    text-overflow: ellipsis;
    outline-style: dotted;
    outline-color: whitesmoke;
    cursor: zoom-out;
  } 
  .contact-list a {
      padding: 5px;
  }

  .imgcontainer img#avatar {
    width: 40%;
    border-radius: 50%;

  }
  p#logs{
    
    text-align: center;
    background-color: rgba(252, 227, 198, 0.9);
    color: black;
    padding-top: 30px;
    text-transform: capitalize;
    margin-top: 40px;
    padding-bottom: 0px;
    height: 32px;
    /* margin-bottom: -45px; */
  
  }
  .projects{
    background-color: #ecebf6;
  }
  p#calls{


    margin-left: 150px;
    padding-bottom: 20px;
    /* background-color: blanchedalmond; */

    /* background-color: blanchedalmond; */
    margin-top: -50px;
  }

  p#firma{
   visibility: hidden;
  }
  
  a#calling{
    color: black;
    font-size: 65%;
  }
  .heading{
    width: 100%;
    padding-left: 0px;
  }
 
  img#profil{

    width: 130px;
    position: fixed;
     left:0;
     top:0;
     border-radius: 10px;
     
     /* background-color: rgba(247, 219, 184, 0.9); */
  }
  #myclock {
    position: relative;
    top: -60px;
    left: 70%;
    transform: translateX(-50%) translateY(-50%);
    color: #3e2d55;
    font-size: 15px;
    font-family: Orbitron;
    letter-spacing: 1px;
   
  
  
  }
  .headings{
    padding-bottom: 0px;
        height: 660px;
        width: 389px;
  }
  
  .item-2 {
    position: relative;
    display: block;
    top: -6em;
    width: 80%;
    font-weight: 600;
    font-size: 1.5em;
  
    animation-duration: 20s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  
}

.item-1{
  position: relative;
  display: block;
  /* top: em; */
  top:-4px;
  /* width: 60%; */
  font-size: 1.5em;
  animation-duration: 20s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  font-weight: 600;
  width:200px;
}

.item-3{

  position: relative;
  display: block;
  top: -15em;
  width: 80%;
  font-size: 1.5em;
  animation-duration: 20s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  font-weight: 600;

}
#postare{
  margin-left: 25px;
  margin-right: 20px;
  height: 780px;
}

.fb{
      height: 1275px;
}
#articol_recent{

  height: 67px;
  width: 530px;
  text-indent: 50px;

}
.despre_img {
  width: 100%;
  /* position: center; */
  padding: 10px;
  background-image: url(../images/2i.png);
  height: 100px;
  background-size: contain;
  background-repeat: repeat;
}
.card-container{
  /* float:center; */
  display: block;
  /* inline-size: auto; */
  width: 300px;

}


.card {

  margin-right: 10px;
  width: 330px;
  margin-top: 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  
    margin: 40px 30px;
    text-align: inherit;
    height: 100%;
    
  
    margin-bottom: 20px;
}

.job-item{
  display: table;
  grid-template-columns: 1fr 2fr;
  column-gap: 1px;
  position: relative;
}

.work{
  background: #fcf7ff;
}
.despre{
  width: 500px;
}

h2#despre_noi{
  margin-bottom: 0px;
  height:12px;
}
.content-wrap1.item-details{


  margin-left: 0px;
  width: 500px;
  margin-right: 600px;
  padding: 60px 0;
}
.pozaTranzitie{
  margin-top: 0px;
    height: 299px;
    width: 250px;

}
.content-wrap{
    width: 300px;
    margin-left: 46px;
    margin-right: 600px;
    padding: 60px 0;
  
      
  
}
.infoTransitions{
  display: none;
}


.row {

  width: 200px;
  display: -webkit-inline-box;
}

#text_despre{

    margin-top: 10px;
    margin-left: 10px;

    margin-bottom: 10px;
    margin-right: 20px;
    width: 310px;
}
#criterii{
  margin-top: 0px;
    text-align: left;
    text-indent: 10px;
}
#post2{
  /* width:552; height: 349; */
  border:none;overflow:hidden;    height: 310px;
  width: 330px;margin-right: 80px;
}

#post1{
  /* width:552; height:340; */
   border:none;overflow:hidden;height: 380px;
   width: 330px;margin-right: 0px;
}

#post3{
  border:none;
  width: 330px; margin-top: 25px;
  overflow: hidden;
}




.col-sm-12{
  margin-left: 0px;
  width: 84px;
}

.col-sm-3{
  margin-left: 0px;

 display: -webkit-box;
 box-sizing: border-box;
 min-height: 1px;

 width: 96px;
    height: 40px;
}



  .text-center{
    width: 340px;
    height: 40px;
    margin-top: 20px;
    border-bottom-width: 0px;
    margin-left: 30px;
  }
 
.row { max-width: 1170%; width:100%; display: -webkit-inline-box; }
/* .row1{width:400%; display:inline-flex}*/
.row.full-width {  margin: 0 auto; max-width: 100%; width: 100%; }
.row.custom-width-90 {  margin: 0 auto; max-width: 90%; width: 90%; }
.row.full-width-padding {  margin: 0 auto; max-width: 98%; width: 98%; }
.row.no-gutter { margin:0 auto;} 
.row.no-gutter [class*='col-']:not(:first-child), .row.no-gutter [class*='col-']:not(:last-child) { padding-left: 0; padding-right: 0; }
/* .row.no-gutter-all { margin:0 auto;} */
.row.no-gutter-all [class*='col-'] { padding-left: 0; padding-right: 0; }
#mc_embed_signup { margin-top:15px;}
#mc_embed_signup input[type="email"] {  border:0; padding-top: 3px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 3px;
  width: 84px;  border-radius:5px;}
#mc_embed_signup input[type="submit"] { margin-top:5px; transition: all 250ms ease-out 0s; border-radius:5px; border:0; font-size: smaller; color:#fff; background:#cb6428;
  padding-top: 2px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 3px;
  width: 84px;
  height: 35px;}
#mc_embed_signup input[type="submit"]:hover { background:#808080;}

/* Social Buttons ---------------------- */
.social-linkss {margin:20px 0 0; padding:0;}
.social-linkss li { list-style: none;  display: inline-table;}
.social-linkss li a { color:#fff; font-size:13px; display: inline-block;  padding-top:7px;  text-align: center;  height: 34px;  width: 34px;  position: relative; z-index: 1; -webkit-transition: color 0.15s; transition: color 0.15s; -moz-osx-font-smoothing: grayscale; border-radius:50%;}
.social-linkss li a::before { content: '';  position: absolute;  top: 0;  left: 0;  width: 100%;  height: 100%;  border-radius: inherit;  z-index: -1;  box-shadow: inset 0 0 0 35px #808080;  -webkit-transform: scale3d(0.7, 0.7, 1);  transform: scale3d(0.7, 0.7, 1);  -webkit-transition: box-shadow 0.15s, -webkit-transform 0.15;  transition: box-shadow 0.15s, transform 0.15s;}
.social-linkss li a:hover::before {  -webkit-transform: scale3d(1.0, 1.0, 1);  transform: scale3d(1.0, 1.0, 1); box-shadow: inset 0 0 0 35px  #009DE0;}

.copyright {padding-top: 0px; background:#525151;    height: 67px;
  }
.copyright p {font-size:14px; color:#ccc; margin-bottom:0;    height: 50px;
  margin-top: 0px;}

/* Back to Top Button  ----------------------- */
#back-to-top { color:#fff; position:fixed; bottom:20px; right:20px; z-index:99; display:none; text-align: center; border-radius: 50%; -moz-border-radius: 50%;  -webkit-border-radius:  50%; -o-border-radius:  50%;    z-index: 10000;    height: 59px;    width: 59px;    background-color:#7f65f0;    background-repeat: no-repeat;    background-position: center;  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24); -webkit-transform: scale3d(0.7, 0.7, 1);  transform: scale3d(0.7, 0.7, 1);  -webkit-transition: box-shadow 0.15s, -webkit-transform 0.15;  transition: box-shadow 0.15s, transform 0.15s;}
#back-to-top i { padding-top:20px; font-size:17px; }
#back-to-top:hover { background:#e77a21;  -webkit-transform: scale3d(1.0, 1.0, 1);  transform: scale3d(1.0, 1.0, 1);}


#politica{        padding-top: 0px;
  height: 20px;
  padding-left: 10px;
  width: 80px;
}
.row:before{
  display: table;
}

img#footerImg {
  box-shadow: -1px 0px 17px gainsboro;
  border-radius: 20px 20px ;
  width: 90px;height: 40px;
  
}

html, body {
  width: 100%;
  overflow-x: hidden;
  background-color: rgba(252, 227, 198, 0.9);
}

button#pol{
  text-decoration-line: underline;
  
    background-color: rgb(105, 104, 104,0.7);
    cursor: pointer;
    border: hidden;
    height: 52px;
  
}


}




